import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import * as React from "react";
import DrawerCustom from "../../../common/components/DrawerCustom";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl },
            valuesField,
            methods: { setValue },
          }) => {
            return {
              newPassword: {
                type: "text-field",
                inputType: valuesField?.showNewPassword ? "text" : "password",
                label: intl.formatMessage({ id: "user.newPassword" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  validate: {
                    required: (value) =>
                      !value ? intl.formatMessage({ id: "required" }) : true,
                    lessThan: (v) =>
                      v.length < 8 || v.length > 24
                        ? intl.formatMessage({ id: "user.minLength" })
                        : true,
                  },
                },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setValue(
                            "showNewPassword",
                            !valuesField?.showNewPassword
                          )
                        }
                      >
                        {valuesField?.showNewPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: 4, overflow: "hidden" },
                  disbleUnderline: true,
                },
              },
              rePassword: {
                type: "text-field",
                inputType: valuesField?.showRePassword ? "text" : "password",
                label: intl.formatMessage({ id: "rePassword" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  validate: {
                    required: (value) =>
                      !value ? intl.formatMessage({ id: "required" }) : true,
                    lessThan: (v) =>
                      v.length < 8 || v.length > 24
                        ? intl.formatMessage({ id: "user.minLength" })
                        : true,
                    positive: (value) =>
                      valuesField?.newPassword !== value &&
                      valuesField?.newPassword
                        ? intl.formatMessage({ id: "notMatchingPassword" })
                        : true,
                  },
                },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setValue(
                            "showRePassword",
                            !valuesField?.showRePassword
                          )
                        }
                      >
                        {valuesField?.showRePassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: 4, overflow: "hidden" },
                  disbleUnderline: true,
                },
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const ChangePassworDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={"user.changePassword"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default ChangePassworDialog;
