import { History } from "history";
import { combineReducers } from "redux";
import { createAction, getType } from "typesafe-actions";
import commonReducer, { CommonState } from "../common/reducer/commonReducer";
import intlReducer, { IntlState } from "../intl/redux/intlReducer";
import authenticationReducer, {
  AuthenticationState,
} from "../modules/auth/reducer/authenticationReducer";
import NreReducer, { NreTypeState } from "../modules/nreType/nreTypeReducer";

export const clearStoreAfterLogout = createAction("clearStoreAfterLogout")();

export interface AppState {
  // router: RouterState;
  intl: IntlState;
  common: CommonState;
  auth: AuthenticationState;
  nre: NreTypeState;
}

export default function createRootReducer(history: History) {
  const rawRootReducer = combineReducers({
    // router: connectRouter(history),
    intl: intlReducer,
    common: commonReducer,
    auth: authenticationReducer,
    nre: NreReducer,
  });

  return (state: AppState | undefined, action: any): AppState => {
    if (state && action.type === getType(clearStoreAfterLogout)) {
      return rawRootReducer(undefined, action);
    }
    return rawRootReducer(state, action);
  };
}
