import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";

interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
  setOpenCreateDialog: (value: boolean) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { hookPagination, data, loading, setOpenCreateDialog, onDelete } =
    props;

  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns = [
    {
      title: "construction.code",
      dataIndex: "code",
      minWidth: 100,
    },
    {
      title: "construction.name",
      dataIndex: "name",
      minWidth: 100,
    },
    {
      title: "constructionType",
      dataIndex: "constructionType.name",
      minWidth: 100,
    },
    {
      title: "constructionKind",
      dataIndex: "constructionKind.name",
      minWidth: 100,
    },
    {
      title: "nreType",
      dataIndex: "nreType.name",
      minWidth: 100,
    },
    {
      title: "address",
      dataIndex: "address",
      minWidth: 100,
    },
    {
      title: "longitude",
      dataIndex: "longitude",
      minWidth: 100,
    },
    {
      title: "latitude",
      dataIndex: "latitude",
      minWidth: 100,
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <LinkPM to={record.id}>
            <IconButton style={{ marginRight: 8 }} size="small">
              <Edit />
            </IconButton>
          </LinkPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Paper sx={{ p: 1 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">
            <FormattedMessage id="construction.list" />
          </Typography>
          <TagPM>
            <Button
              startIcon={<Add />}
              color="primary"
              onClick={() => setOpenCreateDialog(true)}
            >
              <FormattedMessage id="create" />
            </Button>
          </TagPM>
        </Box>
        <TableCustom
          dataSource={data?.items}
          loading={loading}
          paginationProps={{
            count: data?.total,
            page: pagination.page,
            rowsPerPage: pagination.pageSize,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
          }}
          columns={columns}
        />
      </Paper>
    </>
  );
};
export default Table;
