import { Box } from "@mui/material";
import * as React from "react";
import DrawerCustom from "../../../common/components/DrawerCustom";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({ formProps: { intl } }) => {
            return {
              name: {
                type: "text-field",
                label: intl.formatMessage({ id: "riverBasin.name" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
              },
              description: {
                type: "text-field",
                label: intl.formatMessage({ id: "description" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                multiline: true,
                rows: 5,
              },
              sortOrder: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "sortOrder" }),
                placeholder: intl.formatMessage({ id: "enter" }),
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default FormDialog;
