import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, ButtonBase, Divider, Tooltip } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useLocation } from "react-router";
import useGeneralHook from "../common/hook/useGeneralHook";
import { ASIDE_MIN_WIDTH, ASIDE_WIDTH, HEADER_HEIGHT } from "./constants";
import DefaultAsideItems from "./DefaultAsideItems";
import { IRouteObject } from "./utils";

interface Props {
  listRouter: IRouteObject[];
}

const DefaultAside: React.FunctionComponent<Props> = (props) => {
  const { listRouter } = props;
  const { isDarkTheme, theme } = useGeneralHook();
  const { pathname } = useLocation();
  const [hoverOpen, setHover] = React.useState(false);
  const [openAside, setOpenAside] = React.useState(true);
  const openMerge = openAside || hoverOpen;

  const mappedRoutes = (list: IRouteObject[], parent?: IRouteObject) => {
    return list
      .map((one) => {
        if (one.hidden) {
          return undefined;
        }
        if (one.staticRoute) {
          return {
            ...one,
            children: one.children && mappedRoutes(one.children, one),
          };
        }
        return {
          ...one,
          path:
            one.index && parent?.path
              ? `/${parent?.path || ""}`
              : `${parent?.path}/${one.path}`,
          children: one.children && mappedRoutes(one.children, one),
        };
      })
      .filter(Boolean);
  };

  const listRouterMapped = mappedRoutes(listRouter, {
    path: "",
  });

  return (
    <>
      <Box
        boxShadow={1}
        sx={{
          width: openMerge ? ASIDE_WIDTH : ASIDE_MIN_WIDTH,
          height: "100vh",
          transition: "width 0.3s",
          flexShrink: 0,
          zIndex: 20,
          borderRadius: 0,
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          top: 0,
          willChange: "width",
          bgcolor: isDarkTheme ? theme.palette.background.default : "#53A2E3",
        }}
      >
        <Box
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
          overflow="hidden"
          height="100%"
          borderRight={isDarkTheme ? 1 : 0}
          borderColor="divider"
        >
          <PerfectScrollbar
            style={{ height: "100%", paddingTop: HEADER_HEIGHT - 16 }}
          >
            <Box
              style={{
                paddingBottom: 16,
                marginTop: 24,
                overflowY: "auto",
                overflowX: "hidden",
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {listRouterMapped?.map((v: IRouteObject, index: number) => (
                <DefaultAsideItems
                  key={index}
                  data={v}
                  pathname={pathname}
                  open={openMerge}
                />
              ))}
            </Box>
          </PerfectScrollbar>
          <Divider sx={{ marginX: 2 }} />
        </Box>
        <Box
          onMouseEnter={() => {
            openAside && setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
        >
          <Tooltip title={<FormattedMessage id="collapse" />} arrow>
            <ButtonBase
              sx={{
                borderRadius: "50%",
                position: "absolute",
                top: 64,
                right: -14,
                minWidth: 28,
                width: 28,
                height: 28,
                bgcolor: "common.white",
                boxShadow: 4,
                border: 1,
                borderColor: "grey.300",
                zIndex: 20,
                transition: "all 0.3s",
                opacity: hoverOpen || !openAside ? 1 : 0,
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "common.white",
                },
              }}
              onClick={() => {
                setOpenAside((old) => !old);
                setHover(false);
              }}
            >
              <ArrowForwardIosIcon
                color="inherit"
                style={{
                  fontSize: 14,
                  transform: openAside ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </ButtonBase>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default DefaultAside;
