import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { DATE_FORMAT, some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";

interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
  onCreateUpdateForm: (value: some) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { hookPagination, data, loading, onCreateUpdateForm, onDelete } = props;

  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns = [
    {
      title: "monitoringLocation.name",
      dataIndex: "monitoringLocation.name",
    },
    {
      title: "waterQuality.time",
      dataIndex: "time",
      align: "center",
      render: (record) => {
        return record.time && moment(record.time).format(DATE_FORMAT);
      },
    },
    {
      title: "waterQuality.code",
      dataIndex: "code",
      align: "center",
    },

    {
      title: "waterQuality.observer",
      dataIndex: "observer",
      align: "center",
    },
    {
      title: "waterQuality.analyst",
      dataIndex: "analyst",
      align: "center",
    },
    {
      title: "waterQuality.weather",
      dataIndex: "weather",
      align: "center",
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <TagPM>
            <LinkPM to={record.id}>
              <IconButton
                style={{ marginRight: 8 }}
                size="small"
                onClick={() => {
                  onCreateUpdateForm(record);
                }}
              >
                <Edit />
              </IconButton>
            </LinkPM>
          </TagPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <TableCustom
        dataSource={data?.items}
        loading={loading}
        paginationProps={{
          count: data?.total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        columns={columns}
      />
    </>
  );
};
export default Table;
