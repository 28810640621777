// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
import { InputAdornment } from "@mui/material";
import { useParams } from "react-router";
import { HookFilterProps } from "../../../common/hook/useFilterHook";
// Hooks + Common
import SchemaForm from "../../../common/SchemaForm";
import { Search } from "../../../svg";

interface Props extends HookFilterProps {}
const Filter = (props: Props) => {
  const { filter, setParams, clearParams } = props;
  const { nreTypeId } = useParams<{ nreTypeId: string }>();
  return (
    <SchemaForm
      formProps={{ style: { marginBottom: 16 } }}
      formData={filter}
      onSubmit={setParams}
      hideSubmitButton
      schema={{
        fields: ({ formProps: { intl, dispatch, fetchThunk, API_PATHS } }) => ({
          constructionId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "construction.name" }),
            placeholder: intl.formatMessage({
              id: "search",
            }),

            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.construction.index({
                    page: 0,
                    pageSize: 100,
                    name,
                    nreTypeId,
                  })
                )
              );
              return json.data?.items?.map((v) => ({ id: v.id, name: v.name }));
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search className="svgStroke" />
              </InputAdornment>
            ),
            loadKey: nreTypeId,
            propsWrapper: { xs: 3 },
            noHelperText: true,
          },
          constructionTypeId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "constructionType" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadKey: nreTypeId,
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.constructionType.index({
                    name,
                    nreTypeId,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },

          search: {
            type: "submitButton",
            fullWidth: true,
            label: intl.formatMessage({ id: "search" }),
            propsWrapper: { xs: "auto", marginTop: 2.5 },
          },

          reset: {
            type: "button",
            variant: "text",
            fullWidth: true,
            label: <RefreshIcon />,
            style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
            propsWrapper: { xs: "auto", marginTop: 3 },
            onClick: () => {
              clearParams();
            },
          },
        }),
      }}
    />
  );
};
export default Filter;
