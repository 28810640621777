import { Typography } from "@mui/material";
import { Columns } from "../../common/components/TableCustom/utils";

const getSum = (data = [], key) => {
  return data?.reduce((value, current) => {
    if (typeof key === "string") {
      return value + current[key];
    }
    return (
      value +
      key.reduce((v, c) => {
        return v + current[c];
      }, 0)
    );
  }, 0);
};
const getSumRow = (data = {}, key) => {
  return key.reduce((v, c) => {
    return v + data[c];
  }, 0);
};

const month = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII",
];

const report_1 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Lưu vực sông/Vùng</Typography>,
      rowSpan: 3,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "riverBasin",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng số trạm quan trắc (trạm)
        </Typography>
      ),
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">Kỳ trước</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, [
                    "ktKyTruoc",
                    "nddKyTruoc",
                    "tnnKyTruoc",
                    "tongKyTruoc",
                  ])}
                </Typography>
              ),
              align: "center",
              render: (record) => {
                return getSumRow(record, [
                  "ktKyTruoc",
                  "nddKyTruoc",
                  "tnnKyTruoc",
                  "tongKyTruoc",
                ]);
              },
            },
          ],
        },
        {
          title: <Typography variant="body2">Kỳ báo cáo</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, [
                    "ktKyNay",
                    "nddKyNay",
                    "tnnKyNay",
                    "tongKyNay",
                  ])}
                </Typography>
              ),
              align: "center",
              render: (record) => {
                return getSumRow(record, [
                  "ktKyNay",
                  "nddKyNay",
                  "tnnKyNay",
                  "tongKyNay",
                ]);
              },
            },
          ],
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, [
                    "ktThayDoi",
                    "nddThayDoi",
                    "tnnThayDoi",
                    "tongThayDoi",
                  ])}
                </Typography>
              ),
              align: "center",
              render: (record) => {
                return getSumRow(record, [
                  "ktThayDoi",
                  "nddThayDoi",
                  "tnnThayDoi",
                  "tongThayDoi",
                ]);
              },
            },
          ],
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Loại trạm</Typography>,
      children: [
        {
          title: <Typography variant="body2">Khí tượng</Typography>,
          children: [
            {
              title: <Typography variant="body2">Kỳ trước</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["ktKyTruoc"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "ktKyTruoc",
                },
              ],
            },
            {
              title: <Typography variant="body2">Kỳ báo cáo</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["ktKyNay"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "ktKyNay",
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["ktThayDoi"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "ktThayDoi",
                },
              ],
            },
          ],
        },
        {
          title: (
            <Typography variant="body2">
              Thủy văn, thủy văn kết hợp tài nguyên nước
            </Typography>
          ),
          children: [
            {
              title: <Typography variant="body2">Kỳ trước</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["tvKyTruoc"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tvKyTruoc",
                },
              ],
            },
            {
              title: <Typography variant="body2">Kỳ báo cáo</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["tvKyNay"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tvKyNay",
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["tvThayDoi"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tvThayDoi",
                },
              ],
            },
          ],
        },
        {
          title: (
            <Typography variant="body2">Tài nguyên nước độc lập</Typography>
          ),
          children: [
            {
              title: <Typography variant="body2">Kỳ trước</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["tnnKyTruoc"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tnnKyTruoc",
                },
              ],
            },
            {
              title: <Typography variant="body2">Kỳ báo cáo</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["tnnKyNay"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tnnKyNay",
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["tnnThayDoi"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tnnThayDoi",
                },
              ],
            },
          ],
        },
        {
          title: (
            <Typography variant="body2">Quan trắc nước dưới đất</Typography>
          ),

          children: [
            {
              title: <Typography variant="body2">Kỳ trước</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["nddKyTruoc"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "nddKyTruoc",
                },
              ],
            },
            {
              title: <Typography variant="body2">Kỳ báo cáo</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["nddKyNay"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "nddKyNay",
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["nddThayDoi"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "nddThayDoi",
                },
              ],
            },
          ],
        },
      ],
    },
  ] as Columns[];

const report_2 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Tên trạm</Typography>,
      rowSpan: 3,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "constructionName",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Thời kỳ quan trắc</Typography>,
      rowSpan: 3,
      children: [
        {
          rowSpan: 1,
          colSpan: 1,
          align: "center",
          dataIndex: "nddKhacCount",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Tổng lượng mưa (mm)</Typography>,
      children: [
        {
          title: <Typography variant="body2">Năm</Typography>,
          children: [
            {
              title: <Typography variant="body2">Kỳ trước</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["kyTruocNam"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "kyTruocNam",
                },
              ],
            },
            {
              title: <Typography variant="body2">Kỳ báo cáo</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["kyNayNam"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "kyNayNam",
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["thayDoiNam"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "thayDoiNam",
                },
              ],
            },
          ],
        },
        {
          title: <Typography variant="body2">Mùa mưa</Typography>,
          children: [
            {
              title: <Typography variant="body2">Kỳ trước</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["muaKyTruoc"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "muaKyTruoc",
                },
              ],
            },
            {
              title: <Typography variant="body2">Kỳ báo cáo</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["muaKyNay"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "muaKyNay",
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["thayDoiMua"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "thayDoiMua",
                },
              ],
            },
          ],
        },
        {
          title: <Typography variant="body2">Mùa khô</Typography>,
          children: [
            {
              title: <Typography variant="body2">Kỳ trước</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["khoKyTruoc"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "khoKyTruoc",
                },
              ],
            },
            {
              title: <Typography variant="body2">Kỳ báo cáo</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["khoKyNay"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "khoKyNay",
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["thayDoiKho"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "thayDoiKho",
                },
              ],
            },
          ],
        },
      ],
    },
  ] as Columns[];

const report_3 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Tên trạm</Typography>,
      dataIndex: "constructionName",
    },
    {
      title: <Typography variant="subtitle2">Thời kỳ quan trắc</Typography>,
    },
    {
      title: <Typography variant="subtitle2">Lượng mưa tháng (mm)</Typography>,
      children: Array(12)
        .fill(0)
        .map((item, index) => ({
          title: month[index],
          dataIndex: `thang${index + 1}`,
        })),
    },
    {
      title: <Typography variant="subtitle2">Lượng mưa năm (mm)</Typography>,
      children: [
        {
          title: <Typography variant="body2">Xn</Typography>,
          render: (record) => {
            return getSumRow(
              record,
              Array(12)
                .fill(0)
                .map((item, index) => `thang${index + 1}`)
            );
          },
        },
      ],
    },
  ] as Columns[];

const report_4 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Lưu vực sông</Typography>,
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "riverBasinName",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng lượng dòng chảy năm (triệu m³)
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Trung bình nhiều năm</Typography>,
          children: [
            {
              title: getSum(data, "trungBinhNam"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "trungBinhNam",
            },
          ],
        },
        {
          title: <Typography variant="body2">Kỳ trước</Typography>,
          children: [
            {
              title: getSum(data, "kyTruocNam"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "kyTruocNam",
            },
          ],
        },
        {
          title: <Typography variant="body2">Kỳ báo cáo</Typography>,
          children: [
            {
              title: getSum(data, "kyNayNam"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "kyNayNam",
            },
          ],
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          children: [
            {
              title: getSum(data, "thayDoiNam"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "thayDoiNam",
            },
          ],
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng lượng dòng mùa lũ (triệu m³)
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Trung bình nhiều năm</Typography>,
          children: [
            {
              title: getSum(data, "trungBinhMua"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "trungBinhMua",
            },
          ],
        },
        {
          title: <Typography variant="body2">Kỳ trước</Typography>,
          children: [
            {
              title: getSum(data, "muaKyTruoc"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "muaKyTruoc",
            },
          ],
        },
        {
          title: <Typography variant="body2">Kỳ báo cáo</Typography>,
          children: [
            {
              title: getSum(data, "muaKyNay"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "muaKyNay",
            },
          ],
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          children: [
            {
              title: getSum(data, "thayDoiMua"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "thayDoiMua",
            },
          ],
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng lượng dòng chảy mùa cạn (triệu m³)
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Trung bình nhiều năm</Typography>,
          children: [
            {
              title: getSum(data, "trungBinhKho"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "trungBinhKho",
            },
          ],
        },
        {
          title: <Typography variant="body2">Kỳ trước</Typography>,
          children: [
            {
              title: getSum(data, "khoKyTruoc"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "khoKyTruoc",
            },
          ],
        },
        {
          title: <Typography variant="body2">Kỳ báo cáo</Typography>,
          children: [
            {
              title: getSum(data, "khoKyNay"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "khoKyNay",
            },
          ],
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          children: [
            {
              title: getSum(data, "thayDoiKho"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "thayDoiKho",
            },
          ],
        },
      ],
    },
  ] as Columns[];

const report_5 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Tên trạm</Typography>,
      dataIndex: "constructionName",
    },
    {
      title: <Typography variant="subtitle2">Thời kỳ quan trắc</Typography>,
    },
    {
      title: <Typography variant="subtitle2">Lưu lượng (m³/s)</Typography>,
      children: Array(12)
        .fill(0)
        .map((item, index) => ({
          title: month[index],
          dataIndex: `thang${index + 1}`,
        })),
    },
    {
      title: (
        <Typography variant="subtitle2">
          Lưu lượng bình quân năm (m³/s)
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Qn</Typography>,
          render: (record) => {
            return (
              getSumRow(
                record,
                Array(12)
                  .fill(0)
                  .map((item, index) => `thang${index + 1}`)
              ) / 12
            );
          },
        },
      ],
    },
  ] as Columns[];

const report_6 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Lưu vực sông</Typography>,
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "riverBasinName",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Số lượng hồ chứa tổng hợp (hồ)
        </Typography>
      ),
      rowSpan: 2,
      children: [
        {
          title: (
            <Typography variant="body2">
              {getSum(data, [
                "dryVolume",
                "preventionVolume",
                "totalLake",
                "totalVolume",
                "usefulVolume",
              ])}
            </Typography>
          ),
          rowSpan: 1,
          colSpan: 1,
          align: "center",
          render: (record) => {
            return getSumRow(record, [
              "dryVolume",
              "preventionVolume",
              "totalLake",
              "totalVolume",
              "usefulVolume",
            ]);
          },
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng lượng dòng chảy năm (triệu m³)
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Toàn bộ (triệu m³)</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, ["totalVolume"])}
                </Typography>
              ),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "totalVolume",
            },
          ],
        },
        {
          title: <Typography variant="body2">Hữu ích (triệu m³)</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, ["usefulVolume"])}
                </Typography>
              ),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "usefulVolume",
            },
          ],
        },
        {
          title: <Typography variant="body2">Phòng lũ (triệu m³)</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, ["preventionVolume"])}
                </Typography>
              ),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "preventionVolume",
            },
          ],
        },
        {
          title: (
            <Typography variant="body2">
              Tích được vào cuối mùa lũ, đầu mùa cạn (triệu m³)
            </Typography>
          ),
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, ["dryVolume"])}
                </Typography>
              ),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "dryVolume",
            },
          ],
        },
      ],
    },
  ] as Columns[];
const report_7 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Vùng/Khu vực</Typography>,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Vùng điều tra</Typography>,
    children: [
      {
        title: <Typography variant="subtitle2">1</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Diện tích được điều tra (km2)</Typography>
    ),
    children: [
      {
        title: <Typography variant="subtitle2">1</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Tầng chứa nước được điều tra</Typography>
    ),
    children: [
      {
        title: <Typography variant="subtitle2">1</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Tỷ lệ điều tra, đánh giá</Typography>
    ),
    children: [
      {
        title: <Typography variant="subtitle2">1</Typography>,
      },
    ],
  },
] as Columns[];
const report_8 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Vùng/Tầng chứa nước</Typography>,
      dataIndex: "waterFloor",
    },
    {
      title: (
        <Typography variant="subtitle2">Số lượng giếng quan trắc</Typography>
      ),
      align: "center",
      dataIndex: "wellNumber",
    },
    {
      title: <Typography variant="subtitle2">Mực nước lớn nhất (m)</Typography>,
      children: [
        {
          title: <Typography variant="body2">Kỳ trước</Typography>,
          align: "center",
          dataIndex: "kyTruocMax",
        },
        {
          title: <Typography variant="body2">Kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "kyNayMax",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          dataIndex: "thayDoiMax",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">Mực nước trung bình (m)</Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Kỳ trước</Typography>,
          align: "center",
          dataIndex: "kyTruocAvg",
        },
        {
          title: <Typography variant="body2">Kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "kyNayAvg",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          dataIndex: "thayDoiAvg",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Mực nước nhỏ nhất (m)</Typography>,
      children: [
        {
          title: <Typography variant="body2">Kỳ trước</Typography>,
          align: "center",
          dataIndex: "kyTruocMin",
        },
        {
          title: <Typography variant="body2">Kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "kyNayMin",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          dataIndex: "thayDoiMin",
        },
      ],
    },
  ] as Columns[];

const report_9 = (data) =>
  [
    {
      title: (
        <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
      ),
      align: "center",
      dataIndex: "riverBasinName",
    },
    {
      title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
      children: [
        {
          title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          align: "center",
          render: (record) => {
            return getSumRow(record, ["luyKeKyTruocNDD", "luyKeKyTruocNM"]);
          },
        },
        {
          title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
          dataIndex: "riverBasinName",
          align: "center",
          render: (record) => {
            return getSumRow(record, ["luyKeKyNayNDD", "luyKeKyNayNM"]);
          },
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          render: (record) => {
            return (
              getSumRow(record, ["luyKeKyNayNDD", "luyKeKyNayNM"]) -
              getSumRow(record, ["luyKeKyTruocNDD", "luyKeKyTruocNM"])
            );
          },
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Số lượng công trình khai thác nước mặt
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          align: "center",
          dataIndex: "luyKeKyTruocNM",
        },
        {
          title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "luyKeKyNayNM",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          render: (record) => {
            return record?.luyKeKyNayNM - record?.luyKeKyTruocNM;
          },
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Số lượng công trình khai thác nước dưới đất
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          align: "center",
          dataIndex: "luyKeKyTruocNDD",
        },
        {
          title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "luyKeKyNayNDD",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          render: (record) => {
            return record?.luyKeKyNayNDD - record?.luyKeKyTruocNDD;
          },
        },
      ],
    },
  ] as Columns[];
const report_10 = (data) => {
  return [
    {
      title: (
        <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
      ),

      rowSpan: 3,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "riverBasinName",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
      rowSpan: 3,
      children: [
        {
          title: (
            <Typography variant="body2">
              {getSum(data, [
                "khacNDD",
                "khacNM",
                "thuyDien",
                "tuoiTieuNDD",
                "tuoiTieuNM",
              ])}
            </Typography>
          ),
          rowSpan: 1,
          colSpan: 1,
          align: "center",
          render: (record) => {
            return getSumRow(record, [
              "khacNDD",
              "khacNM",
              "thuyDien",
              "tuoiTieuNDD",
              "tuoiTieuNM",
            ]);
          },
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
      children: [
        {
          title: <Typography variant="body2">Tưới</Typography>,
          children: [
            {
              title: <Typography variant="body2">Nguồn nước mặt</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["tuoiTieuNM"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tuoiTieuNM",
                },
              ],
            },
            {
              title: (
                <Typography variant="body2">Nguồn nước dưới đất</Typography>
              ),
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["tuoiTieuNDD"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tuoiTieuNDD",
                },
              ],
            },
          ],
        },
        {
          title: <Typography variant="body2">Thủy điện</Typography>,
          rowSpan: 2,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, ["thuyDien"])}
                </Typography>
              ),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "thuyDien",
            },
          ],
        },
        {
          title: <Typography variant="body2">Mục đích khác</Typography>,
          children: [
            {
              title: <Typography variant="body2">Nguồn nước mặt</Typography>,
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["khacNM"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "khacNM",
                },
              ],
            },
            {
              title: (
                <Typography variant="body2">Nguồn nước dưới đất</Typography>
              ),
              children: [
                {
                  title: (
                    <Typography variant="body2">
                      {getSum(data, ["khacNDD"])}
                    </Typography>
                  ),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "khacNDD",
                },
              ],
            },
          ],
        },
      ],
    },
  ] as Columns[];
};
const report_11 = (data) => {
  return [
    {
      title: (
        <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
      ),
      rowSpan: 3,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "riverBasinName",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
      rowSpan: 3,
      children: [
        {
          title: (
            <Typography variant="body2">
              {getSum(data, [
                "hochuaCount",
                "dapdangCount",
                "congCount",
                "trambomCount",
                "nmKhacCount",
                "giengkhoanCount",
                "nddKhacCount",
              ])}
            </Typography>
          ),
          align: "center",
          render: (record) => {
            return getSumRow(record, [
              "hochuaCount",
              "dapdangCount",
              "congCount",
              "trambomCount",
              "nmKhacCount",
              "giengkhoanCount",
              "nddKhacCount",
            ]);
          },
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Số lượng công trình phân theo loại hình
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Khai thác nước mặt</Typography>,
          children: [
            {
              title: <Typography variant="body2">Hồ chứa</Typography>,
              children: [
                {
                  title: getSum(data, "hochuaCount"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "hochuaCount",
                },
              ],
            },
            {
              title: <Typography variant="body2">Đập dâng</Typography>,
              children: [
                {
                  title: getSum(data, "dapdangCount"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "dapdangCount",
                },
              ],
            },
            {
              title: <Typography variant="body2">Cống</Typography>,
              children: [
                {
                  title: getSum(data, "congCount"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "congCount",
                },
              ],
            },
            {
              title: <Typography variant="body2">Trạm bơm</Typography>,
              children: [
                {
                  title: getSum(data, "trambomCount"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "trambomCount",
                },
              ],
            },
            {
              title: <Typography variant="body2">Khác</Typography>,
              children: [
                {
                  title: getSum(data, "nmKhacCount"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "nmKhacCount",
                },
              ],
            },
          ],
        },
        {
          title: (
            <Typography variant="body2">Khai thác nước dưới đất</Typography>
          ),
          children: [
            {
              title: <Typography variant="body2">Giếng khoan</Typography>,
              children: [
                {
                  title: getSum(data, "giengkhoanCount"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "giengkhoanCount",
                },
              ],
            },
            {
              title: <Typography variant="body2">Khác</Typography>,
              children: [
                {
                  title: getSum(data, "nddKhacCount"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "nddKhacCount",
                },
              ],
            },
          ],
        },
      ],
    },
  ] as Columns[];
};
const report_12 = (data) =>
  [
    {
      title: (
        <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
      ),
      rowSpan: 3,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "riverBasinName",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
      rowSpan: 3,
      children: [
        {
          title: (
            <Typography variant="body2">
              {getSum(data, ["tongCongTrinh"])}
            </Typography>
          ),
          align: "center",
          dataIndex: "tongCongTrinh",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Lượng nước khai thác, sử dụng đã được cấp phép phân theo mục đích khai
          thác và theo nguồn nước
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Tưới (m³/s)</Typography>,
          children: [
            {
              title: <Typography variant="body2">Nguồn nước mặt</Typography>,
              children: [
                {
                  title: getSum(data, "tuoiTieuNM"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tuoiTieuNM",
                },
              ],
            },
            {
              title: (
                <Typography variant="body2">Nguồn nước dưới đất</Typography>
              ),
              children: [
                {
                  title: getSum(data, "tuoiTieuNDD"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "tuoiTieuNDD",
                },
              ],
            },
          ],
        },
        {
          title: <Typography variant="body2">Thủy điện (MW)</Typography>,
          rowSpan: 2,
          children: [
            {
              title: getSum(data, "thuyDien"),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "thuyDien",
            },
          ],
        },
        {
          title: (
            <Typography variant="body2">Mục đích khác (m³/ngày đêm)</Typography>
          ),
          children: [
            {
              title: <Typography variant="body2">Nguồn nước mặt</Typography>,
              children: [
                {
                  title: getSum(data, "khacNM"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "khacNM",
                },
              ],
            },
            {
              title: (
                <Typography variant="body2">Nguồn nước dưới đất</Typography>
              ),
              children: [
                {
                  title: getSum(data, "khacNDD"),
                  rowSpan: 1,
                  colSpan: 1,
                  align: "center",
                  dataIndex: "khacNDD",
                },
              ],
            },
          ],
        },
      ],
    },
  ] as Columns[];
const report_13 = (data) =>
  [
    {
      title: (
        <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
      ),
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "riverBasinName",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Sông, suối/hồ chứa</Typography>,
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">&nbsp;</Typography>,
          dataIndex: "waterSource",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Vị trí quan trắc</Typography>,
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">&nbsp;</Typography>,
          dataIndex: "monitoringLocation",
        },
      ],
    },
    {
      title: (
        <Typography variant="body2">
          Kết quả phân tích Chỉ tiêu .........
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lớn nhất</Typography>,
          children: [
            {
              title: getSum(data, "max"),
              rowSpan: 1,
              colSpan: 1,
              dataIndex: "max",
            },
          ],
        },
        {
          title: <Typography variant="body2">Nhỏ nhất</Typography>,
          children: [
            {
              title: getSum(data, "min"),
              rowSpan: 1,
              colSpan: 1,
              dataIndex: "min",
            },
          ],
        },
        {
          title: <Typography variant="body2">Trung bình</Typography>,
          children: [
            {
              title: getSum(data, "avg"),
              rowSpan: 1,
              colSpan: 1,
              dataIndex: "avg",
            },
          ],
        },
      ],
    },
    {
      title: <Typography variant="body2">Ghi chú</Typography>,
      rowSpan: 3,
      dataIndex: "description",
    },
  ] as Columns[];

const report_14 = (data) =>
  [
    {
      title: (
        <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
      ),
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "riverBasinName",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Tầng chứa nước</Typography>,
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">&nbsp;</Typography>,
          dataIndex: "waterFloor",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Vị trí quan trắc</Typography>,
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">&nbsp;</Typography>,
          dataIndex: "monitoringLocation",
        },
      ],
    },
    {
      title: (
        <Typography variant="body2">
          Kết quả phân tích Chỉ tiêu .........
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lớn nhất</Typography>,
          children: [
            {
              title: getSum(data, "max"),
              rowSpan: 1,
              colSpan: 1,
              dataIndex: "max",
            },
          ],
        },
        {
          title: <Typography variant="body2">Nhỏ nhất</Typography>,
          children: [
            {
              title: getSum(data, "min"),
              rowSpan: 1,
              colSpan: 1,
              dataIndex: "min",
            },
          ],
        },
        {
          title: <Typography variant="body2">Trung bình</Typography>,
          children: [
            {
              title: getSum(data, "avg"),
              rowSpan: 1,
              colSpan: 1,
              dataIndex: "avg",
            },
          ],
        },
      ],
    },
    {
      title: <Typography variant="body2">Ghi chú</Typography>,
      rowSpan: 3,
      dataIndex: "description",
    },
  ] as Columns[];

const report_15 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Cơ quan phát hành</Typography>,
      dataIndex: "authorityName",
    },

    {
      title: <Typography variant="subtitle2">Tổng số văn bản</Typography>,
      children: [
        {
          title: <Typography variant="body2">Kỳ trước</Typography>,
          align: "center",
          dataIndex: "luyKeKyTruoc",
        },
        {
          title: <Typography variant="body2">Kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "luyKeKyNay",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          dataIndex: "thayDoi",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Ghi chú</Typography>,
      dataIndex: "description",
    },
  ] as Columns[];
const report_16 = (data) => {
  return [
    {
      title: <Typography variant="subtitle2">Loại giấy phép</Typography>,
      dataIndex: "licenseTypeName",
    },
    {
      title: (
        <Typography variant="subtitle2">Tổng số giấy phép đã cấp</Typography>
      ),
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          align: "center",
          dataIndex: "luyKeKyTruocTinh",
        },
        {
          title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "luyKeKyNayTinh",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          render: (record) => {
            return record.luyKeKyNayTinh - record.luyKeKyTruocTinh;
          },
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng số giấy phép cấp phân theo thẩm quyền
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Bộ TNMT cấp</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ trước</Typography>
              ),
              align: "center",
              dataIndex: "luyKeKyTruocBtnmt",
            },
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
              ),
              align: "center",
              dataIndex: "luyKeKyNayBtnmt",
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              align: "center",
              render: (record) => {
                return record.luyKeKyNayBtnmt - record.luyKeKyTruocBtnmt;
              },
            },
          ],
        },
        {
          title: <Typography variant="body2">Địa phương cấp</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ trước</Typography>
              ),
              align: "center",
              dataIndex: "luyKeKyTruocDp",
            },
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
              ),
              align: "center",
              dataIndex: "luyKeKyNayDp",
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              align: "center",
              render: (record) => {
                return record.luyKeKyNayDp - record.luyKeKyTruocDp;
              },
            },
          ],
        },
      ],
    },
  ] as Columns[];
};
const report_17 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Tỉnh</Typography>,
      dataIndex: "provinceName",
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng số công trình đã phê duyệt tiền cấp quyền
        </Typography>
      ),
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          align: "center",
          dataIndex: "luyKeKyTruocTinh",
        },
        {
          title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "luyKeKyNayTinh",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          dataIndex: "thayDoiTinh",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng số công trình đã phê duyệt tiền cấp quyền phân theo thẩm quyền
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Bộ TNMT phê duyệt</Typography>,
          align: "center",
          dataIndex: "thayDoi",
          children: [
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ trước</Typography>
              ),
              align: "center",
              dataIndex: "luyKeKyTruocBtnmt",
            },
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
              ),
              align: "center",
              dataIndex: "luyKeKyNayBtnmt",
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              align: "center",
              dataIndex: "thayDoiBTNMT",
            },
          ],
        },
        {
          title: <Typography variant="body2">Địa phương phê duyệt</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ trước</Typography>
              ),
              align: "center",
              dataIndex: "luyKeKyTruocDp",
            },
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
              ),
              align: "center",
              dataIndex: "luyKeKyNayDp",
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              align: "center",
              dataIndex: "thayDoiDp",
            },
          ],
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng số tiền cấp quyền đã phê duyệt (tỷ đồng)
        </Typography>
      ),
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          align: "center",
          dataIndex: "kyTruocTong",
        },
        {
          title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
          align: "center",
          dataIndex: "kyNayTong",
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          align: "center",
          dataIndex: "thayDoiTong",
        },
      ],
    },
  ] as Columns[];
const report_18 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Tỉnh</Typography>,
      rowSpan: 3,
      children: [
        {
          title: <Typography variant="subtitle2">Tổng</Typography>,
          dataIndex: "provinceName",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng số sông, suối đã phê duyệt, công bố dòng chảy tối thiểu phân theo
          thẩm quyền
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Bộ TNMT</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ trước</Typography>
              ),
              children: [
                {
                  title: "",
                  rowSpan: 1,
                  colSpan: 1,
                },
              ],
            },
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
              ),
              children: [
                {
                  title: "",
                  rowSpan: 1,
                  colSpan: 1,
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: "",
                  rowSpan: 1,
                  colSpan: 1,
                },
              ],
            },
          ],
        },
        {
          title: <Typography variant="body2">Địa phương</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ trước</Typography>
              ),
              children: [
                {
                  title: "",
                  rowSpan: 1,
                  colSpan: 1,
                },
              ],
            },
            {
              title: (
                <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
              ),
              children: [
                {
                  title: "",
                  rowSpan: 1,
                  colSpan: 1,
                },
              ],
            },
            {
              title: <Typography variant="body2">Thay đổi</Typography>,
              children: [
                {
                  title: "",
                  rowSpan: 1,
                  colSpan: 1,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng số hồ chứa đã được phê duyệt, công bố dòng chảy tối thiểu ở hạ
          lưu
        </Typography>
      ),
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, ["kyTruocHo"])}
                </Typography>
              ),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "kyTruocHo",
            },
          ],
        },
        {
          title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, ["kyNayHo"])}
                </Typography>
              ),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "kyNayHo",
            },
          ],
        },
        {
          title: <Typography variant="body2">Thay đổi</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  {getSum(data, ["thayDoiHo"])}
                </Typography>
              ),
              rowSpan: 1,
              colSpan: 1,
              align: "center",
              dataIndex: "thayDoiHo",
            },
          ],
        },
      ],
    },
  ] as Columns[];
const report_19 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Tỉnh</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },

  {
    title: (
      <Typography variant="subtitle2">
        Số lượng các cuộc thanh tra, kiểm tra
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Số lượng đối tượng thanh tra, kiểm tra
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Số lượng tổ chức, cá nhân bị xử phạt
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng số tiền xử phạt vi phạm hành chính
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
] as Columns[];

const report_20 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Tên công trình</Typography>,
      dataIndex: "constructionName",
    },
    {
      title: (
        <Typography variant="subtitle2">
          Loại hình công trình (hồ, đập, cống, trạm bơm, giếng khoan, khác
        </Typography>
      ),
      dataIndex: "constructionKind",
    },
    {
      title: (
        <Typography variant="subtitle2">
          Nguồn nước khai thác (sông, suối, hồ, đập, nước dưới đất)
        </Typography>
      ),
      dataIndex: "waterSource",
    },
    {
      title: <Typography variant="subtitle2">Vị trí</Typography>,
      rowSpan: 2,
      children: [
        {
          title: <Typography variant="body2">Xã</Typography>,
          dataIndex: "commune",
        },
        {
          title: <Typography variant="body2">Huyện</Typography>,
          dataIndex: "district",
        },
        {
          title: <Typography variant="body2">Tỉnh</Typography>,
          dataIndex: "province",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Thông số cơ bản</Typography>,
      children: [
        {
          title: <Typography variant="body2">Hồ chứa, đập</Typography>,
          children: [
            {
              title: (
                <Typography variant="body2">
                  Dung tích toàn bộ (triệu m³)
                </Typography>
              ),
              align: "center",
              dataIndex: "totalVolume",
            },
            {
              title: (
                <Typography variant="body2">
                  Dung tích hữu ích (triệu m³)
                </Typography>
              ),
              align: "center",
              dataIndex: "totalUsefulVolume",
            },
            {
              title: <Typography variant="body2">Công suất (MW)</Typography>,
              align: "center",
              dataIndex: "totalWatt",
            },
          ],
        },
        {
          title: (
            <Typography variant="body2">
              Giếng khoan và loại hình khác
            </Typography>
          ),
          children: [
            {
              title: (
                <Typography variant="body2">
                  Lưu lượng thiết kế (m³/ngày đêm)
                </Typography>
              ),
              align: "center",
              dataIndex: "totalDesignFlow",
            },
            {
              title: (
                <Typography variant="body2">
                  Lưu lượng thực tế (m³/ngày đêm)
                </Typography>
              ),
              align: "center",
              dataIndex: "totalRealityFlow",
            },
          ],
        },
      ],
    },
  ] as Columns[];
const report_21 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Thời gian</Typography>,
      dataIndex: "month",
    },

    {
      title: (
        <Typography variant="subtitle2">Lưu lượng đến hồ (m³/s)</Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lớn nhất</Typography>,
          align: "center",
          dataIndex: "max_lldh",
        },
        {
          title: <Typography variant="body2">Nhỏ nhất</Typography>,
          align: "center",
          dataIndex: "min_lldh",
        },
        {
          title: <Typography variant="body2">Trung bình</Typography>,
          align: "center",
          dataIndex: "avg_lldh",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">Tổng lưu lượng xả (m³/s)</Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lớn nhất</Typography>,
          align: "center",
          dataIndex: "max_llx",
        },
        {
          title: <Typography variant="body2">Nhỏ nhất</Typography>,
          align: "center",
          dataIndex: "min_llx",
        },
        {
          title: <Typography variant="body2">Trung bình</Typography>,
          align: "center",
          dataIndex: "avg_llx",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">Dòng chảy tối thiểu (m³/s)</Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Thực tế</Typography>,
          align: "center",
          dataIndex: "sum_dctt",
        },
        {
          title: <Typography variant="body2">Yêu cầu</Typography>,
          align: "center",
          dataIndex: "ll.min",
        },
      ],
    },
  ] as Columns[];

const report_22 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Thời gian</Typography>,
      dataIndex: "month",
    },

    {
      title: (
        <Typography variant="subtitle2">
          Lưu lượng khai thác (m³/ngày đêm hoặc m³/s tùy loại hình)
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lớn nhất</Typography>,
          align: "center",
          dataIndex: "maxLuuLuong",
        },
        {
          title: <Typography variant="body2">Nhỏ nhất</Typography>,
          align: "center",
          dataIndex: "minLuuLuong",
        },
        {
          title: <Typography variant="body2">Trung bình</Typography>,
          align: "center",
          dataIndex: "avgLuuLuong",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Lưu lượng khai thác được cấp phép (m³/ngày đêm hoặc m³/s tùy loại
          hình)
        </Typography>
      ),
      align: "center",
      dataIndex: "tongCapPhep",
    },
    {
      title: <Typography variant="subtitle2">Số ngày khai thác</Typography>,
      align: "center",
      dataIndex: "soNgay",
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng lượng khai thác (Triệu m³)
        </Typography>
      ),
      align: "center",
      dataIndex: "tongLuuLuong",
    },
  ] as Columns[];

const report_23 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Thời gian</Typography>,
      dataIndex: "month",
    },
    {
      title: (
        <Typography variant="subtitle2">
          Lưu lượng khai thác của giếng số…… (m³/ngày đêm)
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lớn nhất</Typography>,
          align: "center",
          dataIndex: "maxLuuLuong",
        },
        {
          title: <Typography variant="body2">Nhỏ nhất</Typography>,
          align: "center",
          dataIndex: "minLuuLuong",
        },
        {
          title: <Typography variant="body2">Trung bình</Typography>,
          align: "center",
          dataIndex: "avgLuuLuong",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Lưu lượng khai thác được cấp phép (m³/ngày đêm)
        </Typography>
      ),
      dataIndex: "luuLuongCapPhep",
    },
    {
      title: (
        <Typography variant="subtitle2">Số ngày khai thác (ngày)</Typography>
      ),
      dataIndex: "soNgay",
    },

    {
      title: (
        <Typography variant="subtitle2">
          Mực nước giếng khai thác số … (m)
        </Typography>
      ),
      children: [
        {
          title: <Typography variant="body2">Lớn nhất</Typography>,
          align: "center",
          dataIndex: "maxMucNuoc",
        },
        {
          title: <Typography variant="body2">Nhỏ nhất</Typography>,
          align: "center",
          dataIndex: "minMucNuoc",
        },
        {
          title: <Typography variant="body2">Trung bình</Typography>,
          align: "center",
          dataIndex: "avgMucNuoc",
        },
        {
          title: (
            <Typography variant="body2">
              Chiều sâu mực nước động lớn nhất cho phép
            </Typography>
          ),
          align: "center",
          dataIndex: "tongLuuLuong ",
        },
      ],
    },
    {
      title: (
        <Typography variant="subtitle2">
          Tổng lượng khai thác (Nghìn m³)
        </Typography>
      ),
      align: "center",
      dataIndex: "soNgay",
    },
  ] as Columns[];

const report_24 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Thời gian</Typography>,
    dataIndex: "time",
  },

  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng xả nước thải (m³/ngày đêm)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        dataIndex: "ll.max",
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        dataIndex: "ll.min",
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        dataIndex: "ll.average",
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng xả thải được cấp phép (m³/ngày đêm)
      </Typography>
    ),
    width: "25%",
    dataIndex: "ll2",
  },
  {
    title: <Typography variant="subtitle2">Số ngày xả nước thải</Typography>,
    dataIndex: "date",
  },
  {
    title: (
      <Typography variant="subtitle2">Tổng lượng xả thải (Nghìn m³)</Typography>
    ),
    dataIndex: "sum",
  },
] as Columns[];
const report_25 = (data) =>
  [
    {
      title: <Typography variant="subtitle2">Thông số quan trắc</Typography>,
      dataIndex: "indicator",
    },
    {
      title: <Typography variant="subtitle2">Đơn vị</Typography>,
      dataIndex: "unit",
    },

    {
      title: <Typography variant="subtitle2">Nồng độ</Typography>,
      children: [
        {
          title: <Typography variant="body2">Lớn nhất</Typography>,
          dataIndex: "max",
        },
        {
          title: <Typography variant="body2">Nhỏ nhất</Typography>,
          dataIndex: "min",
        },
        {
          title: <Typography variant="body2">Trung bình</Typography>,
          dataIndex: "avg",
        },
        {
          title: (
            <Typography variant="body2">
              Giới hạn quy định trong giấy phép
            </Typography>
          ),
          dataIndex: "limitLicense",
        },
      ],
    },
    {
      title: <Typography variant="subtitle2">Ghi chú</Typography>,
      dataIndex: "description",
    },
  ] as Columns[];

export const LIST_REPORT_TABLE = {
  1: {
    name: "Số lượng trạm quan trắc khí tượng, thủy văn, tài nguyên nước, nước dưới đất (1)",
    hidden: false,
    value: 1,
  },
  2: {
    name: "Tổng lượng mưa, phân phối lượng mưa trong năm (2)",
    hidden: false,
    value: 2,
  },
  3: {
    name: "Lượng mưa tháng, năm trong kỳ báo cáo (3)",
    hidden: false,
    value: 3,
  },
  4: {
    name: "Tổng lượng nước mặt trên các lưu vực sông (4)",
    hidden: false,
    value: 4,
  },
  5: {
    name: "Dòng chảy trung bình tháng, năm trong kỳ báo cáo (5)",
    hidden: false,
    value: 5,
  },
  6: {
    name: "Tổng hợp dung tích các hồ chứa lớn, quan trọng trên các lưu vực sông (6)",
    hidden: false,
    value: 6,
  },
  7: {
    name: "Diện tích đã được điều tra, đánh giá tài nguyên nước dưới đất (7)",
    hidden: false,
    value: 7,
  },
  8: {
    name: "Tổng hợp các đặc trưng mực nước dưới đất (8)",
    hidden: false,
    value: 8,
  },
  9: {
    name: "Số lượng công trình khai thác phân theo nguồn nước (9)",
    hidden: false,
    value: 9,
  },
  10: {
    name: "Số lượng công trình khai thác nước mặt, nước dưới đất phân theo mục đích sử dụng (10)",
    hidden: false,
    value: 10,
  },
  11: {
    name: "Số lượng công trình khai thác phân theo loại hình công trình khai thác (11)",
    hidden: false,
    value: 11,
  },
  12: {
    name: "Lượng nước khai thác, sử dụng (quy mô) đã được cấp giấy phép khai thác tài nguyên nước (12)",
    hidden: false,
    value: 12,
  },
  13: {
    name: "Tổng hợp các đặc trưng của các chỉ tiêu cơ bản về chất lượng nước mặt (13)",
    hidden: false,
    value: 13,
  },
  14: {
    name: "Tổng hợp các đặc trưng của các chỉ tiêu cơ bản về chất lượng nước dưới đất (14)",
    hidden: false,
    value: 14,
  },
  15: {
    name: "Tổng hợp số lượng văn bản quy phạm pháp luật về tài nguyên nước đã ban hành (15)",
    hidden: false,
    value: 15,
  },
  16: {
    name: "Tổng hợp số lượng giấy phép tài nguyên nước đã được cấp (16)",
    hidden: false,
    value: 16,
  },
  17: {
    name: "Tổng hợp kết quả phê duyệt tiền cấp quyền khai thác tài nguyên nước (17)",
    hidden: false,
    value: 17,
  },
  18: {
    name: "Tổng hợp kết quả đánh giá, phê duyệt và công bố dòng chảy tối thiểu (18)",
    hidden: false,
    value: 18,
  },
  19: {
    name: "Tổng hợp kết quả thanh tra trong lĩnh vực tài nguyên nước (19)",
    hidden: false,
    value: 19,
  },
  20: {
    name: "Danh mục các công trình khai thác, sử dụng tài nguyên nước (20)",
    hidden: false,
    value: 20,
  },
  21: {
    name: "Tổng hợp tình hình khai thác, sử dụng nước mặt đối với loại hình hồ chứa (21)",
    hidden: false,
    value: 21,
  },
  22: {
    name: "Tổng hợp tình hình khai thác, sử dụng nước mặt đối với loại hình khai thác, sử dụng nước khác hồ chứa (22)",
    hidden: false,
    value: 2,
  },
  23: {
    name: "Tổng hợp tình hình khai thác, sử dụng nước nước dưới đất (23)",
    hidden: false,
    value: 23,
  },
  24: {
    name: "Tổng hợp tình hình xả nước thải (24)",
    hidden: false,
    value: 24,
  },
  25: {
    name: "Tổng hợp các đặc trưng về nồng độ các chất ô nhiễm theo từng thông số quy định trong giấy phép (25)",
    hidden: false,
    value: 25,
  },
};

export const LIST_REPORT = (data = {}) => {
  return {
    1: {
      name: "Số lượng trạm quan trắc khí tượng, thủy văn, tài nguyên nước, nước dưới đất (1)",
      columns: report_1(data),
      value: 1,
    },
    2: {
      name: "Tổng lượng mưa, phân phối lượng mưa trong năm (2)",
      columns: report_2(data),
      value: 2,
    },
    3: {
      name: "Lượng mưa tháng, năm trong kỳ báo cáo (3)",
      columns: report_3(data),
      value: 3,
    },
    4: {
      name: "Tổng lượng nước mặt trên các lưu vực sông (4)",
      columns: report_4(data),
      value: 4,
    },
    5: {
      name: "Dòng chảy trung bình tháng, năm trong kỳ báo cáo (5)",
      columns: report_5(data),
      value: 5,
    },
    6: {
      name: "Tổng hợp dung tích các hồ chứa lớn, quan trọng trên các lưu vực sông (6)",
      columns: report_6(data),
      value: 6,
    },
    7: {
      name: "Diện tích đã được điều tra, đánh giá tài nguyên nước dưới đất (7)",
      columns: report_7,
      value: 7,
    },
    8: {
      name: "Tổng hợp các đặc trưng mực nước dưới đất (8)",
      columns: report_8(data),
      value: 8,
    },
    9: {
      name: "Số lượng công trình khai thác phân theo nguồn nước (9)",
      columns: report_9(data),
      value: 9,
    },
    10: {
      name: "Số lượng công trình khai thác nước mặt, nước dưới đất phân theo mục đích sử dụng (10)",
      columns: report_10(data),
      value: 0,
    },
    11: {
      name: "Số lượng công trình khai thác phân theo loại hình công trình khai thác (11)",
      columns: report_11(data),
      value: 11,
    },
    12: {
      name: "Lượng nước khai thác, sử dụng (quy mô) đã được cấp giấy phép khai thác tài nguyên nước (12)",
      columns: report_12(data),
      value: 12,
    },
    13: {
      name: "Tổng hợp các đặc trưng của các chỉ tiêu cơ bản về chất lượng nước mặt (13)",
      columns: report_13(data),
      value: 13,
    },
    14: {
      name: "Tổng hợp các đặc trưng của các chỉ tiêu cơ bản về chất lượng nước dưới đất (14)",
      columns: report_14(data),
      value: 14,
    },
    15: {
      name: "Tổng hợp số lượng văn bản quy phạm pháp luật về tài nguyên nước đã ban hành (15)",
      columns: report_15(data),
      value: 15,
    },
    16: {
      name: "Tổng hợp số lượng giấy phép tài nguyên nước đã được cấp (16)",
      columns: report_16(data),
      value: 16,
    },
    17: {
      name: "Tổng hợp kết quả phê duyệt tiền cấp quyền khai thác tài nguyên nước (17)",
      columns: report_17(data),
      value: 17,
    },
    18: {
      name: "Tổng hợp kết quả đánh giá, phê duyệt và công bố dòng chảy tối thiểu (18)",
      columns: report_18(data),
      value: 18,
    },
    19: {
      name: "Tổng hợp kết quả thanh tra trong lĩnh vực tài nguyên nước (19)",
      columns: report_19,
      value: 19,
    },
    20: {
      name: "Danh mục các công trình khai thác, sử dụng tài nguyên nước (20)",
      columns: report_20(data),
      value: 20,
    },
    21: {
      name: "Tổng hợp tình hình khai thác, sử dụng nước mặt đối với loại hình hồ chứa (21)",
      columns: report_21(data),
      value: 21,
    },
    22: {
      name: "Tổng hợp tình hình khai thác, sử dụng nước mặt đối với loại hình khai thác, sử dụng nước khác hồ chứa (22)",
      columns: report_22(data),
      value: 2,
    },
    23: {
      name: "Tổng hợp tình hình khai thác, sử dụng nước nước dưới đất (23)",
      columns: report_23(data),
      value: 23,
    },
    24: {
      name: "Tổng hợp tình hình xả nước thải (24)",
      columns: report_24,
      value: 24,
    },
    25: {
      name: "Tổng hợp các đặc trưng về nồng độ các chất ô nhiễm theo từng thông số quy định trong giấy phép (25)",
      columns: report_25(data),
      value: 25,
    },
  };
};
