// Material UI
import { Box } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
// Redux
import { fetchThunk } from "../../../common/reducer/thunk";
// Component
import FormDialog from "../component/FormDialog";
import Table from "../component/Table";

const MenuPage = (props: any) => {
  const { dispatch, enqueueSnackbar, confirmDialog, intl } = useGeneralHook();
  const [formData, setFormData] = useState<some | undefined>();

  const {
    data = [],
    isValidating,
    revalidate,
  } = useSWR(
    API_PATHS.menu.getAll({ appId: process.env.REACT_APP_APP_ID }),
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = await dispatch(
        fetchThunk(
          value?.id ? API_PATHS.menu.update : API_PATHS.menu.create,
          "post",
          value
        )
      );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, revalidate]
  );
  const getAllChild = useCallback((value): any => {
    if (value.children?.length > 0) {
      return value.children.reduce(
        (v, c) => {
          return [...v, ...getAllChild(c)];
        },
        [value]
      );
    }
    return [value];
  }, []);

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.menu.delete, "post", {
            menuList: getAllChild(value).map((v) => v.id),
          })
        );
        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, getAllChild, enqueueSnackbar, revalidate]
  );

  const mappedData = useMemo(() => {
    const getChild = (parentId) => {
      return (data || [])
        ?.filter((v) => v.parentId === parentId)
        .map((v) => {
          return { ...v, children: getChild(v.id) };
        })
        ?.reverse()
        ?.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));
    };
    return getChild(null);
  }, [data]);

  return (
    <Box display="flex" flexDirection="column">
      <Table
        data={mappedData}
        loading={isValidating}
        onCreateUpdateForm={setFormData}
        onDelete={onDelete}
      />
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
    </Box>
  );
};

export default MenuPage;
