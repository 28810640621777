// Material UI
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
// API
import useSWR from "swr";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";

// Component
import FormDialog from "./FormDialog";
import Table from "./Table";

interface Props {
  constructionData: some;
}
const ThresholdTab = (props: Props) => {
  const {
    dispatch,
    enqueueSnackbar,
    confirmDialog,
    fetchThunk,
    API_PATHS,
    intl,
  } = useGeneralHook();
  const { id } = useParams<{ id: string }>();

  const [formData, setFormData] = useState<some | undefined>();

  const { data, isValidating, revalidate } = useSWR(
    id ? API_PATHS.indicatorsThreshold.index(id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading?: (loading: boolean) => void) => {
      if (!id) {
        return;
      }
      setLoading && setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.indicatorsThreshold.update(id, value.id),
              "put",
              {
                ...value,
                constructionId: id,
              }
            )
          )
        : await dispatch(
            fetchThunk(API_PATHS.indicatorsThreshold.index(id), "post", {
              ...value,
              constructionId: id,
            })
          );
      setLoading && setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      id,
      dispatch,
      fetchThunk,
      API_PATHS.indicatorsThreshold,
      enqueueSnackbar,
      revalidate,
    ]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.indicator.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.indicatorsThreshold.getById(value.id), "delete")
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [
      confirmDialog,
      intl,
      dispatch,
      fetchThunk,
      API_PATHS.indicatorsThreshold,
      enqueueSnackbar,
      revalidate,
    ]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Table
        data={data}
        loading={isValidating}
        onCreateUpdateForm={setFormData}
        onUpdate={onCreateUpdateForm}
        onDelete={onDelete}
      />
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
    </Box>
  );
};

export default ThresholdTab;
