// Material UI
import { Box } from "@mui/material";
// API
// Redux
// Component
import TableReport from "../component/TableReport";

const ReportDetailPage = (props: any) => {
  return (
    <Box display="flex" flexDirection="column">
      <TableReport />
    </Box>
  );
};

export default ReportDetailPage;
