// Material UI
import { Box } from "@mui/material";
import React, { useState } from "react";
import { Navigate, useParams } from "react-router";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../../common/constants";
// Hooks
import useGeneralHook from "../../../../common/hook/useGeneralHook";
// Redux
import { fetchThunk } from "../../../../common/reducer/thunk";
import CameraStationDialog from "./CameraStationDialog";
import Table from "./Table";

const CameraTab = (props: any) => {
  const { dispatch, enqueueSnackbar, confirmDialog, intl } = useGeneralHook();
  const { id, stationId } = useParams<{ id: string; stationId: string }>();
  const [formData, setFormData] = useState<some | undefined>();
  const [message, setMessage] = useState("");
  const { data, isValidating, revalidate } = useSWR(
    id && stationId ? API_PATHS.cameraStation.index(stationId) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data.map((v) => ({ ...v, stationId }));
    }
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      if (!stationId) {
        return;
      }
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.camera.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(
            API_PATHS.cameraStation.update(stationId, value.id),
            "delete"
          )
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, enqueueSnackbar, revalidate, stationId]
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some) => {
      if (!stationId) {
        return;
      }
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.cameraStation.update(stationId, value.id),
              "put",
              value
            )
          )
        : await dispatch(
            fetchThunk(API_PATHS.cameraStation.index(stationId), "post", value)
          );
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
      if (json.code === 103) {
        setMessage(json.msg);
      }
    },
    [dispatch, enqueueSnackbar, revalidate, stationId]
  );

  if (!id || !stationId) {
    return <Navigate to="/" />;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Table
        data={data}
        loading={isValidating}
        onCreateUpdateForm={setFormData}
        onDelete={onDelete}
        onUpdate={onCreateUpdateForm}
      />
      <CameraStationDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
        message={message}
      />
    </Box>
  );
};

export default CameraTab;
