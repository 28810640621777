// Material UI
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
// Redux
// Component
import ThresholdFormDialog from "./ThresholdFormDialog";
import ThresholdTable from "./ThresholdTable";

const ThresholdTab = (props: any) => {
  const { dispatch, enqueueSnackbar, fetchThunk, confirmDialog, intl } =
    useGeneralHook();
  const [formData, setFormData] = useState<some | undefined>();

  const { id } = useParams<{ id: string }>();

  const { data, isValidating, revalidate } = useSWR(
    id ? API_PATHS.threshold.index({ qcvnId: id }) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(API_PATHS.threshold.update(value.id), "put", {
              ...value,
              qcvnId: id,
            })
          )
        : await dispatch(
            fetchThunk(API_PATHS.threshold.create, "post", {
              ...value,
              qcvnId: id,
            })
          );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, fetchThunk, id, revalidate]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.threshold.update(value.id), "delete")
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, fetchThunk, enqueueSnackbar, revalidate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <ThresholdTable
        data={data}
        loading={isValidating}
        onCreateUpdateForm={setFormData}
        onDelete={onDelete}
      />
      <ThresholdFormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
    </Box>
  );
};

export default ThresholdTab;
