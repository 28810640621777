import * as React from "react";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <>
      <SchemaForm
        schema={{
          fields: ({ formProps: { intl }, valuesField }) => {
            return {
              name: {
                type: "text-field",
                label: intl.formatMessage({ id: "sensor.name" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              description: {
                type: "text-field",
                label: intl.formatMessage({ id: "description" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              producingCountry: {
                type: "text-field",
                label: intl.formatMessage({ id: "sensor.producingCountry" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              manufacturer: {
                type: "text-field",
                label: intl.formatMessage({ id: "sensor.manufacturer" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              model: {
                type: "text-field",
                label: intl.formatMessage({ id: "sensor.model" }),
                placeholder: intl.formatMessage({ id: "select" }),
                propsWrapper: { xs: 6 },
              },
              sortOrder: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "sortOrder" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              lowerThreshold: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "sensor.lowerThreshold" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  max: {
                    value: valuesField.lowerThreshold,
                    message: intl.formatMessage({
                      id: "sensor.upperThresholdValid",
                    }),
                  },
                },
                required: false,
              },
              upperThreshold: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "sensor.upperThreshold" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  min: {
                    value: valuesField.lowerThreshold,
                    message: intl.formatMessage({
                      id: "sensor.upperThresholdValid",
                    }),
                  },
                },
                required: false,
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
