import * as React from "react";
import DialogCustomFullScreen from "../../../../common/components/DialogCustomFullScreen";
import { some } from "../../../../common/constants";
import SchemaForm from "../../../../common/SchemaForm";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

interface Props {
  onSubmit: (formVal: some) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <>
      <SchemaForm
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "off",
          autoCorrect: "off",
        }}
        schema={{
          fields: ({
            formProps: {
              intl,
              dispatch,
              state,
              setState,
              fetchThunk,
              API_PATHS,
            },
            valuesField,
          }) => {
            return {
              account: {
                type: "text-field",
                label: intl.formatMessage({ id: "dataLoggerStation.account" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 4 },
                register: {
                  required: true,
                },
                inputProps: {
                  autoComplete: "account",
                  name: "account",
                  "aria-label": "account",
                },
              },
              password: {
                type: "text-field",
                label: intl.formatMessage({ id: "password" }),
                placeholder: intl.formatMessage({ id: "passwordEnter" }),
                inputType: state.showPassword ? "text" : "password",
                propsWrapper: { xs: 4 },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setState({ showPassword: !state.showPassword })
                        }
                      >
                        {state.showPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                register: {
                  required: true,
                },
                inputProps: {
                  autoComplete: "accountPassword",
                  name: "accountPassword",
                  "aria-label": "accountPassword",
                },
              },
              datalogger: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "dataLogger" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.dataLogger.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                    manufacturer: v.manufacturer,
                    model: v.model,
                    producingCountry: v.producingCountry,
                  }));
                },
                propsWrapper: { xs: 4 },
                register: {
                  required: true,
                },
                noHelperText: true,
              },
              ip: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "dataLoggerStation.ip",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 4 },
              },
              commandLine: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "dataLoggerStation.commandLine",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 4 },
              },
              connectionStandard: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "dataLoggerStation.connectionStandard",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 4 },
              },

              installationDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "dataLoggerStation.installationDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 4 },
              },
              operationDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "dataLoggerStation.operationDate",
                }),
                minDate: valuesField.installationDate,
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 4 },
              },
              dataTransmissionStartTime: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "dataLoggerStation.dataTransmissionStartTime",
                }),
                minDate: valuesField.operationDate,
                propsWrapper: { xs: 4 },
              },
              encryptionSupport: {
                type: "switch",
                label: intl.formatMessage({
                  id: "dataLoggerStation.encryptionSupport",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 4 },
              },
              active: {
                type: "switch",
                label: intl.formatMessage({ id: "dataLoggerStation.active" }),
                propsWrapper: { xs: 4 },
              },
            };
          },
        }}
      />
    </>
  );
};

const DataLoggerStationDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default DataLoggerStationDialog;
