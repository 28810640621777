import * as React from "react";
import { FormattedMessage } from "react-intl";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  formData?: some;
  onSubmit: (value: some, setLoading: (loading: boolean) => void) => void;
  submitLabel?: string;
}

const FormInfoBox: React.FunctionComponent<Props> = (props) => {
  const { formData, onSubmit, submitLabel } = props;

  return (
    <SchemaForm
      initialData={formData}
      onSubmit={onSubmit}
      formProps={{
        autoComplete: "nope",
        autoCorrect: "off",
      }}
      submitLabel={submitLabel && <FormattedMessage id={submitLabel} />}
      schema={{
        fields: ({ formProps: { intl, dispatch, fetchThunk, API_PATHS } }) => {
          return {
            name: {
              type: "text-field",
              label: intl.formatMessage({ id: "constructionType.name" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              register: {
                required: true,
              },
            },
            nreType: {
              type: "auto-complete",
              label: intl.formatMessage({ id: "constructionType.nreType" }),
              placeholder: intl.formatMessage({
                id: "select",
              }),
              loadOptions: async (name: string) => {
                const json = await dispatch(
                  fetchThunk(
                    API_PATHS.nreType.index({
                      name,
                      pageSize: 100,
                    })
                  )
                );
                return json.data?.items?.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              },
              register: {
                required: true,
              },
            },
            sortOrder: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({ id: "sortOrder" }),
              placeholder: intl.formatMessage({ id: "enter" }),
            },
            description: {
              type: "text-field",
              label: intl.formatMessage({
                id: "description",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              multiline: true,
              rows: 5,
            },
          };
        },
      }}
    />
  );
};

export default FormInfoBox;
