import moment from "moment";
import { useMemo } from "react";
import Chart from "react-apexcharts";
import useSWR from "swr";
import LoadingIcon from "../../../common/components/LoadingIcon";
import { DATE_FORMAT, some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";

interface Props {
  params: some;
}
const ChartBox = (props: Props) => {
  const { params } = props;
  const { constructionId } = params;
  const { dispatch, fetchThunk, API_PATHS, appState, intl } = useGeneralHook();
  const { data, isValidating } = useSWR(
    constructionId?.id
      ? API_PATHS.trafficData.dayChart({
          constructionId: constructionId?.id,
        })
      : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );

  const series = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: "totalTraffic" }),
        data: data?.map((v) => [
          moment(v.time).toDate().getTime(),
          v.construction_tong_luuluong,
        ]),
      },
    ];
  }, [data, intl]);

  if (isValidating) {
    return <LoadingIcon />;
  }
  return (
    <Chart
      options={{
        theme: {
          mode: appState.common.mode === "light" ? "light" : "dark",
        },
        chart: {
          type: "area",
          stacked: false,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          align: "left",
        },
        stroke: {
          width: 1,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function (val) {
              return moment(val).format(DATE_FORMAT);
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },
      }}
      type="area"
      series={series}
      height={450}
    />
  );
};

export default ChartBox;
