import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import * as React from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { HEADER_HEIGHT } from "../../layout/constants";

interface Props extends Omit<DrawerProps, "onClose" | "title"> {
  onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  title?: React.ReactNode;
}

const DrawerCustom: React.FunctionComponent<Props> = (props) => {
  const { children, onClose, title, ...rest } = props;
  const [open, setOpen] = React.useState(rest.open);

  useEffect(() => {
    setOpen(rest.open);
  }, [rest.open]);

  return (
    <Drawer
      keepMounted={false}
      anchor="right"
      {...rest}
      PaperProps={{
        ...rest.PaperProps,
        sx: {
          minWidth: 340,
          position: "fixed",
          right: 0,
          top: HEADER_HEIGHT,
          bottom: 0,
          margin: 0,
          borderRadius: "0px !important",
          boxShadow: (theme: Theme) => theme.shadows[1],
          maxHeight: "unset",
          height: "inherit",
          ...rest.PaperProps?.sx,
        },
      }}
      onClose={() => {
        onClose && onClose();
        setOpen(false);
      }}
      open={open}
    >
      {title ? (
        <Box
          sx={{
            position: "sticky",
            top: 0,
            padding: 2,
            zIndex: 2,
            borderBottom: 1,
            borderColor: "grey.300",
            bgcolor: "background.default",
          }}
        >
          {typeof title === "string" ? (
            <Typography variant="h6">
              <FormattedMessage id={title} />
            </Typography>
          ) : (
            title
          )}
          <Box
            position="absolute"
            top={6}
            right={0}
            marginX={2}
            marginY={1}
            zIndex={3}
          >
            <IconButton
              onClick={() => {
                onClose && onClose();
                setOpen(false);
              }}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          position="absolute"
          top={6}
          right={0}
          marginX={2}
          marginY={1}
          zIndex={3}
        >
          <IconButton
            onClick={() => {
              onClose && onClose();
              setOpen(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      )}
      <Box
        bgcolor={(theme: Theme) => theme.palette.background.paper}
        overflow="auto"
      >
        {children}
      </Box>
    </Drawer>
  );
};
export default DrawerCustom;
