import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { FormattedMessage, FormattedNumber } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import { DATE_TIME_FORMAT, some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import { capitalizeFirstLetter } from "../../../common/utils";
import { LIST_STATUS_STATION } from "../../dashboard/constants";

interface Props {
  data: some;
  columnsFilter: some[];
  hookPagination: HookPaginationProps;
  loading: boolean;
}
const Table = (props: Props) => {
  const { data, columnsFilter = [], hookPagination, loading } = props;
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  const columns = [
    {
      title: "time",
      minWidth: 120,
      fixed: "left",
      variant: "caption",
      align: "center",
      render: (record: some) => {
        return (
          <Typography variant="caption">
            {record.time && moment(record.time).format(DATE_TIME_FORMAT)}
          </Typography>
        );
      },
    },
    ...(columnsFilter || []).map((column) => {
      if (column.indicators) {
        return {
          title: (
            <Box>
              <Typography
                variant="caption"
                fontWeight="bold"
                style={{ wordBreak: "break-word" }}
                component="div"
              >
                {capitalizeFirstLetter(column.name)}
              </Typography>
            </Box>
          ),
          headerProps: { style: { paddingRight: 0, paddingLeft: 0 } },
          align: "center",

          children: column.indicators?.map((indicator) => {
            return {
              title: (
                <Box>
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    component="span"
                  >
                    {capitalizeFirstLetter(indicator.name)}
                  </Typography>
                  {indicator.unit && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                    >
                      &nbsp;({indicator.unit})
                    </Typography>
                  )}
                </Box>
              ),
              minWidth: 70,
              align: "center",
              render: (record: some) => {
                const value = record?.[`${indicator.dataColumnCode}_v`];
                const status = record?.[`${indicator.dataColumnCode}_s`];
                const label = LIST_STATUS_STATION.find((v) =>
                  v.value.includes(status)
                )?.label;
                const color = LIST_STATUS_STATION.find((v) =>
                  v.value.includes(status)
                )?.color;
                return (
                  <Box>
                    {typeof value === "number" ? (
                      <Typography
                        variant="caption"
                        color={
                          record?.status === 99
                            ? "textSecondary"
                            : "success.main"
                        }
                        style={{
                          wordBreak: "break-word",
                        }}
                      >
                        {typeof value === "number" && (
                          <FormattedNumber
                            value={value}
                            maximumFractionDigits={2}
                          />
                        )}
                      </Typography>
                    ) : (
                      "-"
                    )}
                    {color && (
                      <Tooltip
                        title={label ? <FormattedMessage id={label} /> : ""}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 4,
                            left: "calc(50% - 4px)",
                            width: 8,
                            height: 8,
                            borderRadius: "50%",
                            bgcolor: color,
                            flexShrink: 0,
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                );
              },
            };
          }),
        };
      }
      return {
        title: (
          <Box>
            <Typography variant="caption" fontWeight="bold" component="span">
              {capitalizeFirstLetter(column.name)}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              component="span"
            >
              &nbsp;({column.unit})
            </Typography>
          </Box>
        ),
        minWidth: 70,
        align: "center",
        variant: "caption",
        render: (record) => {
          const value = record?.[`${column.dataColumnCode}_v`];
          const status = record?.[`${column.dataColumnCode}_s`];
          const label = LIST_STATUS_STATION.find((v) =>
            v.value.includes(status)
          )?.label;
          const color = LIST_STATUS_STATION.find((v) =>
            v.value.includes(status)
          )?.color;
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Tooltip title={label ? <FormattedMessage id={label} /> : ""}>
                <Typography variant="caption" color={color}>
                  {typeof value === "number" && (
                    <FormattedNumber value={value} maximumFractionDigits={2} />
                  )}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      };
    }),
  ] as Columns[];

  return (
    <>
      <TableCustom
        dataSource={data?.items}
        columns={columns}
        loading={loading}
        containerProps={{ sx: { "& td": { position: "relative" } } }}
        paginationProps={{
          count: data?.total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
    </>
  );
};
export default Table;
