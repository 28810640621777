import { PaletteMode } from "@mui/material";
import Cookies from "js-cookie";
import { ActionType, createCustomAction, getType } from "typesafe-actions";
import { MODE_THEME } from "../constants";

export interface CommonState {
  networkErrorMsg?: string;
  openErrorDialog: boolean;
  loading: boolean;
  mode: PaletteMode;
  disableBreadcrumbs?: boolean;
}

export const setModeTheme = createCustomAction(
  "common/setModeTheme",
  (val: PaletteMode) => ({ val })
);

export const setOpenErrorDialog = createCustomAction(
  "common/setOpenErrorDialog",
  (val: boolean) => ({ val })
);

export const setNetworkError = createCustomAction(
  "common/setNetworkError",
  (errorMsg: string, openErrorDialog: boolean) => ({
    errorMsg,
    openErrorDialog,
  })
);

export const setLoading = createCustomAction(
  "common/setLoading",
  (val: boolean) => ({ val })
);
export const setDisableBreadcrumbs = createCustomAction(
  "common/setDisableBreadcrumbs",
  (val: boolean) => ({ val })
);

const actions = {
  setNetworkError,
  setOpenErrorDialog,
  setLoading,
  setModeTheme,
  setDisableBreadcrumbs,
};

type Action = ActionType<typeof actions>;

export default function reducer(
  state: CommonState = {
    loading: false,
    openErrorDialog: false,
    mode: (Cookies.get(MODE_THEME) as PaletteMode) || "light",
    disableBreadcrumbs: false,
  },
  action: Action
): CommonState {
  switch (action.type) {
    case getType(setModeTheme):
      return { ...state, mode: action.val };

    case getType(setOpenErrorDialog):
      return { ...state, openErrorDialog: action.val };
    case getType(setNetworkError):
      return {
        ...state,
        networkErrorMsg: action.errorMsg,
        openErrorDialog: action.openErrorDialog,
      };
    case getType(setLoading):
      return { ...state, loading: action.val };
    case getType(setDisableBreadcrumbs):
      return { ...state, disableBreadcrumbs: action.val };
    default:
      return state;
  }
}
