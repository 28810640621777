// Material UI
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../../common/constants";
// Hooks
import useGeneralHook from "../../../../common/hook/useGeneralHook";
// Redux
import { fetchThunk } from "../../../../common/reducer/thunk";
// Component
import FormDialog from "./FormDialog";
import Table from "./Table";

const AutoCheckTab = (props: any) => {
  const { dispatch, enqueueSnackbar, confirmDialog, intl } = useGeneralHook();
  const { id, stationId } = useParams<{ id: string; stationId: string }>();

  const [formData, setFormData] = useState<some | undefined>();

  const { data, isValidating, revalidate } = useSWR(
    id && stationId ? API_PATHS.stationIndicators.index(stationId) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some) => {
      if (!stationId) {
        return;
      }
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.stationIndicators.update(stationId, value.id),
              "put",
              value
            )
          )
        : await dispatch(
            fetchThunk(
              API_PATHS.stationIndicators.index(stationId),
              "post",
              value
            )
          );
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [stationId, dispatch, enqueueSnackbar, revalidate]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.indicator.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.stationIndicators.delete(value.id), "delete")
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, enqueueSnackbar, revalidate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Table
        data={data}
        loading={isValidating}
        onCreateUpdateForm={setFormData}
        onUpdate={onCreateUpdateForm}
        onDelete={onDelete}
      />
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
    </Box>
  );
};

export default AutoCheckTab;
