/* eslint-disable react-hooks/exhaustive-deps */
// Material UI
import { Box, Paper, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import TableCustom from "../../../../common/components/TableCustom";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
import { ErrorCircle } from "../../../../svg";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    "@global": {
      td: {
        backgroundColor:
          theme.palette.mode === "light" ? theme.palette.grey[100] : "inherit",
      },
      th: {
        backgroundColor:
          theme.palette.mode === "light" ? theme.palette.grey[100] : "inherit",
      },
    },
  },
}));

interface Props {}
const CompareBox = (props: Props) => {
  const styles = useStyles();
  const { dispatch, API_PATHS } = useGeneralHook();

  const { data: monthData } = useSWR(
    API_PATHS.dashboard.compareData({ compareType: "month", by: "file" }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );
  const { data: monthDataExceed } = useSWR(
    API_PATHS.dashboard.compareData({ compareType: "month", by: "exceed" }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );
  const { data: yearData } = useSWR(
    API_PATHS.dashboard.compareData({ compareType: "year", by: "file" }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );
  const { data: yearDataExceed } = useSWR(
    API_PATHS.dashboard.compareData({ compareType: "year", by: "exceed" }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );
  const columns = [
    {
      title: "time",
      width: "15%",
      dataIndex: "name",
    },
    {
      title: "ratio",
      width: "35%",
      render: (record) => (
        <Box paddingX={3}>
          <Typography variant="h6">{record.ratio}%</Typography>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id="data"
              values={{
                num: `${record.total}/${record.expect}`,
              }}
            />
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box sx={{ svg: { height: 24, width: 24 }, marginRight: 1 }}>
            <ErrorCircle />
          </Box>
          <FormattedMessage id={"status.exceed"} />
        </Box>
      ),
      render: (record) => (
        <Box textAlign="center">
          <Typography variant="h6">{record.exceedRatio}%</Typography>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id="data"
              values={{ num: `${record.exceedTotal}/${record.exceedExpect}` }}
            />
          </Typography>
        </Box>
      ),
    },
  ];
  return (
    <Paper
      sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
        <FormattedMessage id="dashboard.dataDevice" />
      </Typography>
      <Box display="flex" gap={2} flex={1}>
        <TableCustom
          boxProps={{ style: { flex: 1 } }}
          tableProps={{
            style: { backgroundColor: grey[600], height: "100%" },
            className: styles.table,
          }}
          columns={columns}
          dataSource={monthData?.map((v, index) => {
            return {
              ...v,
              exceedRatio: monthDataExceed?.[index]?.ratio,
              exceedTotal: monthDataExceed?.[index]?.total,
              exceedExpect: monthDataExceed?.[index]?.expect,
            };
          })}
          hideColumnIndex
          caption={
            <Typography textAlign={"center"}>
              <FormattedMessage id="noData" />
            </Typography>
          }
        />
        <TableCustom
          boxProps={{ style: { flex: 1 } }}
          tableProps={{
            style: { backgroundColor: grey[600], height: "100%" },
            className: styles.table,
          }}
          columns={columns}
          dataSource={yearData?.map((v, index) => {
            return {
              ...v,
              exceedRatio: yearDataExceed?.[index]?.ratio,
              exceedTotal: yearDataExceed?.[index]?.total,
              exceedExpect: yearDataExceed?.[index]?.expect,
            };
          })}
          hideColumnIndex
          caption={
            <Typography textAlign={"center"}>
              <FormattedMessage id="noData" />
            </Typography>
          }
        />
      </Box>
    </Paper>
  );
};

export default CompareBox;
