import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, ButtonBase, Paper, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import useSWR from "swr";
import LinkPM from "../../../../common/components/LinkPM";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
import { stringifyUrl } from "../../../../common/utils";
import { ROUTES } from "../../../../layout/constants";

interface Props {}
const LicenseBox = (props: Props) => {
  const { dispatch, API_PATHS } = useGeneralHook();
  const [tab, setTab] = useState("1");

  const { data } = useSWR(API_PATHS.dashboard.license, async (url: string) => {
    const json = await dispatch(fetchThunk(url));
    return json?.data;
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Paper
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" sx={{ p: 2, paddingBottom: 0 }}>
        <FormattedMessage id="dashboard.licenseSummary" />
      </Typography>
      <TabContext value={`${tab}`}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {data?.map((item, index) => {
              return (
                <Tab
                  key={item.id}
                  label={item.name}
                  value={`${index + 1}`}
                  title={item.name}
                />
              );
            })}
          </TabList>
        </Box>
        {data?.map((item, index) => {
          return (
            <TabPanel
              key={item.id}
              value={`${index + 1}`}
              style={{ padding: 0, height: "100%" }}
            >
              <Box display={"flex"} height="100%">
                <LinkPM
                  style={{ flex: 1 }}
                  to={{
                    pathname: `/${ROUTES.licenseManagement.index}/${ROUTES.licenseManagement.list}`,
                    search: `?${stringifyUrl({
                      nreTypeId: { id: item.id, name: item.name },
                      status: "unexpired",
                    })}`,
                  }}
                >
                  <ButtonBase
                    sx={{
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "36px 16px",
                      height: "100%",
                      bgcolor: "#C8FACD",
                      color: "black",
                    }}
                  >
                    <Typography variant="h4" color="inherit">
                      <FormattedNumber value={item.total} />
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginTop: 8 }}
                      color="inherit"
                    >
                      <FormattedMessage id="dashboard.licenseProvided" />
                    </Typography>
                  </ButtonBase>
                </LinkPM>
                <LinkPM
                  style={{ flex: 1 }}
                  to={{
                    pathname: `/${ROUTES.licenseManagement.index}/${ROUTES.licenseManagement.list}`,
                    search: `?${stringifyUrl({
                      nreTypeId: { id: item.id, name: item.name },
                      status: "aboutToExpire",
                    })}`,
                  }}
                >
                  <ButtonBase
                    sx={{
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "36px 16px",
                      height: "100%",
                      bgcolor: "#FEF5D4",
                      color: "black",
                    }}
                  >
                    <Typography variant="h4" color="inherit">
                      <FormattedNumber value={item.aboutToExpire} />
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginTop: 8 }}
                      color="inherit"
                    >
                      <FormattedMessage id="dashboard.licenseAboutToExpire" />
                    </Typography>
                  </ButtonBase>
                </LinkPM>
                <LinkPM
                  style={{ flex: 1 }}
                  to={{
                    pathname: `/${ROUTES.licenseManagement.index}/${ROUTES.licenseManagement.list}`,
                    search: `?${stringifyUrl({
                      nreTypeId: { id: item.id, name: item.name },
                      status: "expired",
                    })}`,
                  }}
                >
                  <ButtonBase
                    sx={{
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "36px 16px",
                      height: "100%",
                      bgcolor: "#FDE4E2",
                      color: "black",
                    }}
                  >
                    <Typography variant="h4" color="inherit">
                      <FormattedNumber value={item.expired} />
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginTop: 8 }}
                      color="inherit"
                    >
                      <FormattedMessage id="dashboard.licenseExpired" />
                    </Typography>
                  </ButtonBase>
                </LinkPM>
              </Box>
            </TabPanel>
          );
        })}
      </TabContext>
    </Paper>
  );
};

export default LicenseBox;
