import { Box, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import DrawerCustom from "../../../../common/components/DrawerCustom";
import { some } from "../../../../common/constants";
import SchemaForm from "../../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  const { stationId } = useParams<{ id: string; stationId: string }>();
  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({ formProps: { intl } }) => {
            return {
              indicator: {
                type: () => {
                  return (
                    <Box display="flex">
                      <Typography variant="subtitle1">
                        <FormattedMessage id="indicator" />
                        <Typography variant="body1" component={"span"}>
                          :&nbsp;{formData?.indicator?.name}
                        </Typography>
                      </Typography>
                    </Box>
                  );
                },
                unregister: true,
              },
              // removeDuplicate: {
              //   type: "text-field",
              //   inputType: "number",
              //   fromControlProps: { style: { margin: 0 } },
              //   label: intl.formatMessage({
              //     id: "indicator.removeDuplicate",
              //   }),
              //   placeholder: intl.formatMessage({
              //     id: "enter",
              //   }),
              // },
              removeNegative: {
                type: "checkbox",
                fromControlProps: { style: { margin: 0 } },
                label: intl.formatMessage({
                  id: "indicator.removeNegative",
                }),
              },
              removeZero: {
                type: "checkbox",
                fromControlProps: { style: { margin: 0 } },
                label: intl.formatMessage({
                  id: "indicator.removeZero",
                }),
              },

              removeDeviceError: {
                type: "checkbox",
                fromControlProps: { style: { margin: 0 } },
                label: intl.formatMessage({
                  id: "indicator.removeDeviceError",
                }),
              },
              removeCalibrate: {
                type: "checkbox",
                fromControlProps: { style: { margin: 0 } },
                label: intl.formatMessage({
                  id: "indicator.removeCalibrate",
                }),
              },
            };
          },
          changeDataBeforeSubmit: (value: some) => {
            return {
              ...value,
              stationId: stationId,
              stationSensorId: value?.stationSensorId?.id,
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default FormDialog;
