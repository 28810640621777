import { OpenInNewRounded, Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Paper, Typography } from "@mui/material";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import TableCustom from "../../../common/components/TableCustom";
import { BE_DATE_FORMAT, GIENG_OPTIONS } from "../../../common/constants";
import useFilterHook from "../../../common/hook/useFilterHook";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import SchemaForm from "../../../common/SchemaForm";
import { NoData } from "../../../svg";
import { LIST_REPORT } from "../constants";

export const REPORT_FILTER_1 = [1, 8, 9, 10, 15, 16, 17, 18];
export const REPORT_FILTER_2 = [2, 4];
export const REPORT_FILTER_3 = [3, 5, 21, 22];
export const REPORT_FILTER_4 = [23];
export const REPORT_FILTER_5 = [13, 14];
interface Props {}
const TableReport = (props: Props) => {
  const { dispatch, fetchThunk, enqueueSnackbar, API_PATHS } = useGeneralHook();
  const { id = "1" } = useParams<{ id: any }>();
  const idReport = Number(id);
  const { filter, setParams, clearParams } = useFilterHook();
  const [loading, setLoading] = useState(false);
  console.log("filter", filter);

  const validate = useMemo(() => {
    if (REPORT_FILTER_1.includes(idReport)) {
      return filter.startYear && filter.endDate;
    } else if (REPORT_FILTER_2.includes(idReport)) {
      return filter.startYear && filter.endYear;
    } else if (REPORT_FILTER_3.includes(idReport)) {
      return filter.startYear;
    } else if (REPORT_FILTER_4.includes(idReport)) {
      return filter.startYear && filter.dataColumn;
    } else if (REPORT_FILTER_5.includes(idReport)) {
      return filter.indicatorId;
    }
    return true;
  }, [
    filter.dataColumn,
    filter.endDate,
    filter.endYear,
    filter.indicatorId,
    filter.startYear,
    idReport,
  ]);

  const { data = [], isValidating } = useSWR(
    id && validate
      ? API_PATHS.report.get(id, {
          ...filter,
          startYear: filter.startYear && moment(filter.startYear).year(),
          endYear: filter.endYear && moment(filter.endYear).year(),
        })
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const ReportTemplate = LIST_REPORT(data)[id];

  const getLink = useCallback(async () => {
    setLoading(true);
    try {
      const json = await dispatch(
        fetchThunk(
          API_PATHS.report.export(id, {
            ...filter,
            startYear: filter.startYear && moment(filter.startYear).year(),
            endYear: filter.endYear && moment(filter.endYear).year(),
          }),
          "get",
          undefined,
          "file"
        )
      );
      setLoading(false);
      if (json.status !== 200) {
        return;
      }
      const newBlob = new Blob([json.blob as Blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${ReportTemplate?.name}}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(json.blob as any);
    } catch (e: any) {
      enqueueSnackbar({
        message: <FormattedMessage id="downloadFail" />,
        variant: "success",
      });
    } finally {
      setLoading(false);
    }
  }, [
    API_PATHS.report,
    ReportTemplate?.name,
    dispatch,
    enqueueSnackbar,
    fetchThunk,
    filter,
    id,
  ]);

  if (!ReportTemplate) {
    return (
      <Box display="flex" justifyContent="center">
        <NoData />
      </Box>
    );
  }

  return (
    <>
      {[
        ...REPORT_FILTER_1,
        ...REPORT_FILTER_2,
        ...REPORT_FILTER_3,
        ...REPORT_FILTER_4,
        ...REPORT_FILTER_5,
      ].includes(idReport) && (
        <SchemaForm
          formProps={{ style: { marginBottom: 16 } }}
          formData={filter}
          onSubmit={setParams}
          hideSubmitButton
          schema={{
            fields: ({ formProps: { intl, appState }, valuesField }) => {
              const nreTypeId = appState?.nre?.data?.find(
                (v) => v.code === "NM"
              )?.id;
              return {
                startYear: {
                  type: "datePicker",
                  label: intl.formatMessage({ id: "fromYear" }),
                  mask: "____",
                  views: ["year"],
                  inputFormat: "yyyy",
                  propsWrapper: { xs: 2 },
                  disableFuture: true,
                  register: {
                    required: true,
                  },
                },
                endYear: {
                  type: "datePicker",
                  label: intl.formatMessage({ id: "fromYear" }),
                  mask: "____",
                  views: ["year"],
                  inputFormat: "yyyy",
                  propsWrapper: { xs: 2 },
                  disableFuture: true,
                  minDate: valuesField?.startYear,
                  register: {
                    required: true,
                  },
                },
                endDate: {
                  type: "datePicker",
                  label: intl.formatMessage({ id: "endDate" }),
                  propsWrapper: { xs: 2 },
                  disableFuture: true,
                  minDate:
                    valuesField.startYear &&
                    moment(valuesField.startYear)
                      .startOf("year")
                      .format(BE_DATE_FORMAT),
                  register: {
                    required: true,
                  },
                },
                dataColumn: {
                  type: "select",
                  label: intl.formatMessage({ id: "station.dataColumn" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  options: GIENG_OPTIONS,
                  rawOptions: true,
                  register: {
                    required: true,
                  },
                  propsWrapper: { xs: 2 },
                },
                indicatorId: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "indicator.name" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadKey: nreTypeId,
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.indicator.index({
                          name,
                          pageSize: 100,
                          nreTypeId,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  register: {
                    required: true,
                  },
                  propsWrapper: { xs: 2 },
                },
                search: {
                  type: "submitButton",
                  fullWidth: true,
                  label: intl.formatMessage({ id: "search" }),
                  propsWrapper: { xs: "auto", marginTop: 2.5 },
                },
                reset: {
                  type: "button",
                  variant: "text",
                  fullWidth: true,
                  label: <Refresh />,
                  style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
                  propsWrapper: { xs: "auto", marginTop: 3 },
                  onClick: () => {
                    clearParams();
                  },
                },
              };
            },
            ui: [
              REPORT_FILTER_1.includes(idReport)
                ? {
                    id: "filter_1",
                    fields: ["startYear", "endDate", "search", "reset"],
                  }
                : REPORT_FILTER_2.includes(idReport)
                ? {
                    id: "filter_2",
                    fields: ["startYear", "endYear", "search", "reset"],
                  }
                : REPORT_FILTER_3.includes(idReport)
                ? {
                    id: "filter_3",
                    fields: ["startYear", "search", "reset"],
                  }
                : REPORT_FILTER_4.includes(idReport)
                ? {
                    id: "filter_4",
                    fields: ["startYear", "dataColumn", "search", "reset"],
                  }
                : REPORT_FILTER_5.includes(idReport)
                ? {
                    id: "filter_5",
                    fields: ["indicatorId", "search", "reset"],
                  }
                : null,
            ],
            changeDataBeforeSubmit: (values) => {
              return {
                ...values,
                startYear:
                  values.startYear &&
                  moment(values.startYear)
                    .startOf("year")
                    .format(BE_DATE_FORMAT),
                endYear:
                  values.endYear &&
                  moment(values.endYear).startOf("year").format(BE_DATE_FORMAT),
              };
            },
          }}
        />
      )}
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">{ReportTemplate?.name}</Typography>
          <LoadingButton
            style={{ marginLeft: 8 }}
            loading={loading}
            disabled={!validate}
            loadingPosition="start"
            startIcon={<OpenInNewRounded />}
            onClick={() => {
              getLink();
            }}
          >
            <FormattedMessage id="exportData" />
          </LoadingButton>
        </Box>
        <TableCustom
          dataSource={data}
          columns={ReportTemplate.columns}
          loading={isValidating}
        />
      </Paper>
    </>
  );
};
export default TableReport;
