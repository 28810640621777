import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";

interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
  onCreateUpdateForm: (value: some) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { hookPagination, data, loading, onCreateUpdateForm, onDelete } = props;

  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns = [
    {
      title: "enterprise.name",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "email",
      dataIndex: "email",
    },
    {
      title: "enterprise.phone",
      dataIndex: "phone",
    },
    {
      title: "enterprise.address",
      dataIndex: "address",
    },
    {
      title: "description",
      dataIndex: "description",
    },

    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <TagPM>
            <IconButton
              style={{ marginRight: 8 }}
              size="small"
              onClick={() => {
                onCreateUpdateForm(record);
              }}
            >
              <Edit />
            </IconButton>
          </TagPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">
            <FormattedMessage id="enterprise.list" />
          </Typography>
          <TagPM>
            <Button
              startIcon={<Add />}
              color="primary"
              onClick={() => onCreateUpdateForm({})}
            >
              <FormattedMessage id="create" />
            </Button>
          </TagPM>
        </Box>
        <TableCustom
          dataSource={data?.items}
          loading={loading}
          paginationProps={{
            count: data?.total,
            page: pagination.page,
            rowsPerPage: pagination.pageSize,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
          }}
          columns={columns}
        />
      </Paper>
    </>
  );
};
export default Table;
