import { Visibility, VisibilityOff } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LayersIcon from "@mui/icons-material/Layers";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import {
  alpha,
  Box,
  ButtonBase,
  Checkbox,
  Collapse,
  createFilterOptions,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, grey, orange, purple, red } from "@mui/material/colors";
import React, { useMemo, useRef, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import { OpenState } from "../../../common/components/elements";
import LoadingIcon from "../../../common/components/LoadingIcon";
import { some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../common/reducer/thunk";
import FormControlAutoComplete from "../../../common/SchemaForm/element/autocomplete/FormControlAutoComplete";
import { PinIcon, Search } from "../../../svg";
import { LIST_STATUS_STATION } from "../../dashboard/constants";
import BingMapsReact from "../component/BingMapsReact";
import LocationMarker from "../component/LocationMarker";

const STATUS_COLORS = {
  80: red[600],
  0: green[600],
  1: purple[600],
  2: orange[600],
  99: grey[600],
};

const CENTER_POINT = {
  latitude: 22.808211,
  longitude: 104.9708122,
};
const LAYERS = [
  {
    url: "https://map.stnmt-hagiang.com.vn/geoserver/hg_map/wms",
    params: {
      layers: "hg_map:huyen_dd",
      transparent: true,
      format: "image/png",
    },
    title: "Huyện thành phố thị xã",
    default: true,
  },
  {
    url: "https://map.stnmt-hagiang.com.vn/geoserver/hg_map/wms",
    params: {
      layers: "hg_map:xa_dd_line",
      transparent: true,
      format: "image/png",
    },
    title: "Xã phường thị trấn",
    default: true,
  },
  {
    url: "https://map.stnmt-hagiang.com.vn/geoserver/hg_map/wms",
    params: {
      layers: "hg_map:song_vung_catinh_dd",
      transparent: true,
      format: "image/png",
    },
    title: "Sông xuối ao hồ",
    default: true,
  },
  {
    url: "https://map.stnmt-hagiang.com.vn/geoserver/hg_map/wms",
    params: {
      layers: "hg_map:giaothong_dd",
      transparent: true,
      format: "image/png",
    },
    title: "Giao thông",
    default: true,
  },
];

interface Props {}
const MapConstructionPage = (props: Props) => {
  const { dispatch, API_PATHS, location, intl } = useGeneralHook();
  const [status, setStatus] = React.useState<number[]>([]);
  const [layerMap, setLayerMap] = React.useState<number[]>(
    LAYERS.map((_, index) => (_.default ? index : -1))
  );

  const [construction, setConstruction] = React.useState<some | undefined>(
    undefined
  );
  const [showLabel, setShowLabel] = React.useState(true);
  const [fullScreen, setFullScreen] = React.useState(false);
  const search = new URLSearchParams(location.search);
  const constructionId = search.get("constructionId");
  const handle = useFullScreenHandle();
  const map = useRef<any>(null);

  const { data, isValidating, revalidate } = useSWR(
    API_PATHS.map.index({
      status: status.join(","),
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      // setCurrentLocation(json?.data?.items?.[0]);
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const FilterStatusBox = useMemo(() => {
    return (
      <Box
        sx={{
          position: "absolute",
          left: 16,
          bottom: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          zIndex: 100,
        }}
      >
        {LIST_STATUS_STATION.map((item, index) => {
          return (
            <ButtonBase
              key={index}
              sx={{
                marginTop: "12px",
                paddingX: 1,
                borderRadius: 8,
                boxShadow: 2,
                bgcolor: (theme: Theme) =>
                  item.value.every((v) => status.includes(v))
                    ? alpha(theme.palette.grey[700], 0.4)
                    : theme.palette.background.paper,
                color: item.value.every((v) => status.includes(v))
                  ? "common.white"
                  : undefined,
              }}
              onClick={() => {
                if (item.value.every((v) => status.includes(v))) {
                  setStatus(status.filter((v: number) => !status.includes(v)));
                } else {
                  setStatus([...status, ...item.value]);
                }
              }}
            >
              <Box
                sx={{
                  height: 8,
                  minWidth: 8,
                  borderRadius: 4,
                  bgcolor: item.color,
                  marginRight: 1,
                }}
              />
              <Typography variant="body2" color="inherit">
                <FormattedMessage id={item.label} />
              </Typography>
            </ButtonBase>
          );
        })}
      </Box>
    );
  }, [status]);

  const FilterClassBox = useMemo(() => {
    return (
      <OpenState>
        {({ open, setOpen }) => {
          return (
            <Paper
              style={{
                position: "absolute",
                right: 80,
                top: 4,
                display: "flex",
                flexDirection: "column",
                zIndex: 100,
                padding: "8px 16px",
                width: 240,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                onClick={() => setOpen(!open)}
                style={{ cursor: "pointer" }}
              >
                <LayersIcon color="primary" />
                <Typography
                  variant="subtitle1"
                  style={{ flex: 1, marginLeft: 12 }}
                >
                  <FormattedMessage id="layerMap" />
                </Typography>
                <IconButton>
                  <KeyboardArrowDownIcon
                    style={{
                      transform: open ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "all 200ms",
                      fontSize: 16,
                    }}
                  />
                </IconButton>
              </Box>
              <Collapse in={open} style={{ display: "block" }}>
                {LAYERS.map((item, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      label={
                        <Typography variant="body2">{item.title}</Typography>
                      }
                      control={
                        <Checkbox
                          checked={layerMap.includes(index)}
                          onChange={(e) => {
                            const checked = e.currentTarget.checked;
                            if (!checked) {
                              setLayerMap(
                                layerMap.filter((v: number) => v !== index)
                              );
                            } else {
                              setLayerMap([...layerMap, index]);
                            }
                          }}
                        />
                      }
                    />
                  );
                })}
              </Collapse>
            </Paper>
          );
        }}
      </OpenState>
    );
  }, [layerMap]);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    // stringify: (option: any) => option.label,
  });

  const bingMapProps = useMemo(() => {
    return (data || [])?.map((val) => {
      return {
        center: {
          latitude: val.latitude,
          longitude: val.longitude,
        },
        data: val,
        options: {
          title: showLabel ? val.name : "",
          icon: ReactDOMServer.renderToString(
            <PinIcon
              id={val.station_id}
              style={{
                color:
                  val.id === construction?.id
                    ? "blue"
                    : STATUS_COLORS?.[val.status],
              }}
            ></PinIcon>
          ),
          anchor: { x: 12, y: -12, z: undefined },
        },
      };
    });
  }, [construction?.id, data, showLabel]);

  React.useEffect(() => {
    if (constructionId) {
      const tmp = data?.find((v) => v.id === constructionId);
      if (tmp) {
        setConstruction(tmp);
      }
    }
  }, [constructionId, data]);

  const mappedLayer = useMemo(() => {
    return LAYERS.filter((_, index) => layerMap.includes(index));
  }, [layerMap]);

  return (
    <Paper style={{ padding: 8, height: "calc(100% - 90px)" }}>
      <FullScreen
        handle={handle}
        className={"fullHeight"}
        onChange={(state) => {
          setFullScreen(state);
        }}
      >
        <Box height={"100%"} position="relative">
          {isValidating && !data ? (
            <LoadingIcon style={{ height: "100%", width: "100%", zIndex: 0 }} />
          ) : (
            <>
              <BingMapsReact
                bingMapsKey="6cUhn3nX8rMm3qqntugX~UQqXdA0ntvCzek53q6XNNQ~AkgvX0x73IbJSxL9PENXR5Ck5HK1uLPoyZP0ubHk7FxnKkkMg_4PV9aqaDW9trrW"
                viewOptions={{
                  center: CENTER_POINT,
                }}
                mapOptions={{
                  showCopyright: false,
                }}
                onMapReady={(mapLayer) => {
                  map.current = mapLayer.map;
                  revalidate();
                }}
                pushPinsWithInfoboxes={bingMapProps}
                layerMap={mappedLayer}
                onClick={setConstruction}
              />
              {construction && (
                <LocationMarker
                  data={construction}
                  onClose={() => setConstruction(undefined)}
                  isFullScreen={handle.active}
                />
              )}

              <FormControlAutoComplete
                startAdornment={
                  <InputAdornment position="start">
                    <Search className="svgStroke" />
                  </InputAdornment>
                }
                PopperComponent={(val) => <Popper {...val} disablePortal />}
                placeholder={intl.formatMessage({
                  id: "mapConstruction.search",
                })}
                filterOptions={filterOptions}
                value={construction}
                onChange={(_, value) => {
                  map.current?.setView({
                    center: {
                      latitude: value.latitude,
                      longitude: value.longitude,
                    },
                  });
                  setConstruction(value);
                }}
                options={data}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                style={{
                  position: "absolute",
                  top: 12,
                  left: 12,
                  width: 300,
                  zIndex: 100,
                }}
              />
              <Box
                display={"flex"}
                gap={1}
                style={{
                  position: "absolute",
                  top: 54,
                  left: 12,
                  zIndex: 100,
                }}
              >
                <Tooltip
                  title={<FormattedMessage id="mapConstruction.fullScreen" />}
                  placement="bottom"
                  arrow
                >
                  <ButtonBase
                    style={{
                      padding: 4,
                      minWidth: "unset",
                      backgroundColor: "white",
                      borderRadius: 4,
                    }}
                    sx={{
                      border: 1,
                      borderColor: "grey.500",
                    }}
                    onClick={() => {
                      fullScreen ? handle.exit() : handle.enter();
                    }}
                  >
                    <ZoomOutMapIcon color="primary" />
                  </ButtonBase>
                </Tooltip>

                <Tooltip
                  title={<FormattedMessage id="mapConstruction.showLabel" />}
                  placement="bottom"
                  arrow
                >
                  <ButtonBase
                    style={{
                      padding: 4,
                      borderRadius: "50%",
                      minWidth: "unset",
                      backgroundColor: "white",
                    }}
                    sx={{
                      border: 1,
                      borderColor: "grey.500",
                      color: "primary.main",
                    }}
                    onClick={() => setShowLabel((old) => !old)}
                  >
                    {showLabel ? <Visibility /> : <VisibilityOff />}
                  </ButtonBase>
                </Tooltip>
              </Box>
              {FilterStatusBox}
              {FilterClassBox}
            </>
          )}
        </Box>
      </FullScreen>
    </Paper>
  );
};

export default MapConstructionPage;
