import { Add, Delete, Edit, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Collapse, IconButton, TableRow, Tooltip } from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import TableCustom from "../../../common/components/TableCustom";
import { TableCustomCell } from "../../../common/components/TableCustom/element";
import { Columns } from "../../../common/components/TableCustom/utils";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import ThresholdIndicatorFormDialog from "./ThresholdIndicatorFormDialog";

interface Props {
  row: some;
  setFormData: (item: some) => void;
  onDelete: (item: some) => void;
  index: number;
}
function ThresholdIndicationRow(props: Props) {
  const { dispatch, fetchThunk, enqueueSnackbar, confirmDialog, intl } =
    useGeneralHook();
  const { index, row, setFormData, onDelete } = props;
  const [formDataIndicator, setFormDataIndicator] = useState<some | undefined>(
    undefined
  );
  const [open, setOpen] = useState(true);

  const { data, isValidating, revalidate } = useSWR(
    row.id ? API_PATHS.thresholdIndicator.index(row.id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  const onCreateUpdateForm = useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.thresholdIndicator.update(row.id, value.id),
              "put",
              value
            )
          )
        : await dispatch(
            fetchThunk(
              API_PATHS.thresholdIndicator.index(row.id),
              "post",
              value
            )
          );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormDataIndicator(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, fetchThunk, revalidate, row.id]
  );

  const onDeleteIndicator = useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.indicator?.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.thresholdIndicator.delete(value.id), "delete")
        );
        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormDataIndicator(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, fetchThunk, enqueueSnackbar, revalidate]
  );

  const columns = [
    {
      title: "indicator.code",
      dataIndex: "indicator.code",
    },
    {
      title: "indicator.name",
      dataIndex: "indicator.name",
    },
    {
      title: "indicator.unit",
      dataIndex: "indicator.unit",
      align: "center",
    },
    {
      title: "threshold.lowerThreshold",
      dataIndex: "lowerThreshold",
      align: "center",
    },
    {
      title: "threshold.lowerThresholdOperator",
      dataIndex: "lowerThresholdOperator",
      align: "center",
    },

    {
      title: "threshold.upperThreshold",
      dataIndex: "upperThreshold",
      align: "center",
    },
    {
      title: "threshold.upperThresholdOperator",
      dataIndex: "upperThresholdOperator",
      align: "center",
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <IconButton
            style={{ marginRight: 8 }}
            size="small"
            onClick={() => {
              setFormDataIndicator(record);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              onDeleteIndicator(record);
            }}
            size="small"
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ] as Columns[];

  return (
    <Fragment>
      <TableRow>
        <TableCustomCell width={50} align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <KeyboardArrowDown
              style={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 200ms",
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell align="center" width={50}>
          {index + 1}
        </TableCustomCell>
        <TableCustomCell>{row.name}</TableCustomCell>
        <TableCustomCell align="center">{data?.length || 0}</TableCustomCell>
        <TableCustomCell align="center">
          <Tooltip title={<FormattedMessage id={"threshold.addIndicator"} />}>
            <IconButton
              style={{ marginRight: 8 }}
              size="small"
              onClick={() => {
                setFormDataIndicator({});
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
          <IconButton
            size="small"
            onClick={() => {
              setFormData(row);
            }}
            style={{ margin: "0px 8px" }}
          >
            <Edit />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              onDelete(row);
            }}
          >
            <Delete />
          </IconButton>
        </TableCustomCell>
      </TableRow>
      <TableRow>
        <TableCustomCell
          style={{ padding: 0, border: open ? "" : "none" }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box padding={1} bgcolor="white">
              <TableCustom
                dataSource={data}
                loading={isValidating}
                columns={columns}
                caption=""
              />
            </Box>
          </Collapse>
        </TableCustomCell>
      </TableRow>
      <ThresholdIndicatorFormDialog
        open={!!formDataIndicator}
        onClose={() => {
          setFormDataIndicator(undefined);
        }}
        formData={formDataIndicator}
        onSubmit={onCreateUpdateForm}
      />
    </Fragment>
  );
}

export default ThresholdIndicationRow;
