import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import { some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import CheckBoxElement from "../../../common/SchemaForm/element/checkbox/CheckBoxElement";
import MultipleRadioElement from "../../../common/SchemaForm/element/multiple-radio/MultipleRadioElement";
import { mergeFieldName } from "../../../common/SchemaForm/utils";
interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
  onSubmit: (values: some) => void;
}

const IndicatorArray = (props: { name: string; readOnly: boolean }) => {
  const { name, readOnly } = props;
  const methods = useFormContext();
  const { fields } = useFieldArray({
    control: methods.control,
    name: name,
  });

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {fields.map((item: some, index: number) => {
        return (
          <Controller
            key={item.id}
            name={mergeFieldName({
              name: "isPublic",
              index,
              parent: name,
            })}
            control={methods.control}
            render={({ field, fieldState: { isDirty } }) => (
              <CheckBoxElement
                label={<Typography variant="caption">{item.name}</Typography>}
                fromControlProps={{
                  style: { margin: 0, marginRight: 8, marginBottom: 8 },
                }}
                color={isDirty ? "primary" : "secondary"}
                disabled={readOnly}
                {...field}
              />
            )}
          />
        );
      })}
    </Box>
  );
};

const Table = (props: Props) => {
  const { hookPagination, data, loading, onSubmit } = props;
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const readOnly = false;
  //  !checkPermission([["STATION-PUBLIC_CONFIG"]]);
  const methods = useForm({
    defaultValues: data,
    reValidateMode: "onChange",
    mode: "onSubmit",
  });

  const { control, reset } = methods;

  const { fields } = useFieldArray({
    control,
    name: "items",
  });
  const valueForm = methods.watch("items");

  const columns = [
    {
      title: "publicConfig.stationName",
      dataIndex: "name",
      fixed: "left",
      minWidth: 300,
      width: 300,
    },
    {
      title: "publicConfig.isPublic",
      fixed: "left",
      align: "center",
      minWidth: 80,
      width: 80,
      render: (record: some, index) => {
        return (
          <Controller
            key={record.id}
            name={mergeFieldName({
              name: "isPublic",
              index,
              parent: "items",
            })}
            control={methods.control}
            render={({
              field: { onChange, value = null, ...field },
              fieldState: { isDirty },
            }) => (
              <CheckBoxElement
                fromControlProps={{
                  style: { margin: 0 },
                }}
                disabled={readOnly}
                onChange={(...params) => {
                  onChange(...params);
                }}
                color={isDirty ? "primary" : "secondary"}
                value={value}
                {...field}
              />
            )}
          />
        );
      },
    },
    {
      title: "publicConfig.type",
      fixed: "left",
      align: "center",
      width: 200,
      render: (record: some, index) => {
        return (
          <Controller
            key={record.id}
            name={mergeFieldName({
              name: "publicData",
              index,
              parent: "items",
            })}
            control={methods.control}
            render={({
              field: { onChange, value = null, ...field },
              fieldState: { isDirty, isTouched },
            }) => (
              <MultipleRadioElement
                variant="caption"
                onChange={(...params) => {
                  !readOnly && onChange(...params);
                }}
                radioProps={{
                  color: isDirty ? "primary" : "secondary",
                }}
                options={[
                  { id: "kdtd", name: "publicConfig.kdtd" },
                  { id: "kdtc", name: "publicConfig.kdtc" },
                ]}
                value={value}
                {...field}
              />
            )}
          />
        );
      },
    },
    {
      title: "publicConfig.indicator",
      render: (record, index) => {
        return (
          <IndicatorArray
            key={record.id}
            name={mergeFieldName({
              name: "indicators",
              index,
              parent: "items",
            })}
            readOnly={readOnly}
          />
        );
      },
    },
  ] as Columns[];

  React.useEffect(() => {
    reset(data, { keepDirty: false });
  }, [data, reset]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Paper sx={{ p: 1 }}>
          <Box
            display="flex"
            alignItems="center"
            marginBottom={2}
            marginTop={1}
          >
            <Typography variant="h6" style={{ flex: 1 }}>
              <FormattedMessage id="publicConfig.title" />
            </Typography>
            {!readOnly && (
              <>
                <IconButton size="small" onClick={() => methods.reset()}>
                  <RefreshIcon />
                </IconButton>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={"secondary"}
                      style={{ padding: 2 }}
                      checked={valueForm
                        ?.reduce((v: some[], c: some) => {
                          return [...v, ...c.indicators];
                        }, [])
                        .every((v) => v.isPublic)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          reset(
                            {
                              ...data,
                              data: [
                                ...valueForm.map((val: some) => ({
                                  ...val,
                                  indicators: val.indicators.map((v) => ({
                                    ...v,
                                    isPublic: true,
                                  })),
                                })),
                              ],
                            },
                            { keepDirty: true }
                          );
                        } else {
                          reset(
                            {
                              ...data,
                              data: [
                                ...valueForm.map((val: some, index) => ({
                                  ...val,
                                  indicators: val.indicators.map((v, idx) => ({
                                    ...v,
                                    isPublic: get(
                                      data,
                                      `items.${index}.indicators.${idx}.isPublic`
                                    ),
                                  })),
                                })),
                              ],
                            },
                            { keepDirty: true }
                          );
                        }
                      }}
                    />
                  }
                  label={
                    <InputLabel
                      style={{
                        marginBottom: 0,
                        height: "auto",
                        display: "unset",
                      }}
                    >
                      <FormattedMessage id="publicConfig.selectAll" />
                    </InputLabel>
                  }
                  labelPlacement={"end"}
                  sx={{
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 2,
                    "&label": {
                      fontSize: "inherit",
                      overflow: "unset",
                    },
                    minWidth: 80,
                  }}
                />
                <Button color="primary" type="submit">
                  <FormattedMessage id="save" />
                </Button>
              </>
            )}
          </Box>
          <TableCustom
            dataSource={fields}
            loading={loading}
            fixIndexColumn
            paginationProps={{
              count: methods.getValues("total"),
              page: pagination.page,
              rowsPerPage: pagination.pageSize,
              onPageChange: onPageChange,
              onRowsPerPageChange: onRowsPerPageChange,
            }}
            columns={columns}
          />
        </Paper>
      </form>
    </FormProvider>
  );
};
export default Table;
