// DEPENDENCIES
import { stringify } from "query-string";
import { useCallback, useEffect, useRef } from "react";
import { some } from "../../../common/constants";

interface Props {
  bingMapsKey: string;
  height?: string;
  width?: string;
  mapOptions?: any;
  onMapReady?: any;
  pushPins?: any;
  pushPinsWithInfoboxes?: any;
  viewOptions?: any;
  layerMap: some[];
  onClick: (value?: some) => void;
}
export default function BingMapsReact(props: Props) {
  const {
    bingMapsKey,
    height = "100%",
    mapOptions,
    onMapReady,
    pushPins,
    pushPinsWithInfoboxes,
    viewOptions,
    width = "100%",
    layerMap = [],
    onClick,
  } = props;
  // refs
  const mapContainer = useRef(null);
  const map = useRef<any>(null);

  // removes pushpins
  function removePushpins(map, Maps) {
    for (var i = map.entities.getLength() - 1; i >= 0; i--) {
      var pushpin = map.entities.get(i);
      if (pushpin instanceof Maps.Pushpin) {
        map.entities.removeAt(i);
      }
    }
  }
  const addLayer = useCallback(() => {
    if (
      (window as any).Microsoft &&
      (window as any).Microsoft?.Maps &&
      map.current
    ) {
      map.current.layers?.clear();
      const { Maps } = (window as any)?.Microsoft;
      layerMap.forEach((item) => {
        var NOAAWeatherRadar = new Maps.TileLayer({
          mercator: new Maps.TileSource({
            uriConstructor: `${item.url}?${stringify(
              item.params
            )}&bbox={bbox}&CRS=CRS:84&service=WMS&request=GetMap&version=1.1.1&width=256&height=256`,
          }),
        });
        map.current.layers.insert(NOAAWeatherRadar);
        return;
      });
    }
  }, [layerMap]);

  // add pushpins with infoboxes
  const addPushpinsWithInfoboxes = useCallback(
    (pushPinsToAdd, mapTmp, Maps) => {
      const infobox = new Maps.Infobox(mapTmp?.getCenter(), {
        visible: false,
      });
      infobox.setMap(mapTmp);
      removePushpins(mapTmp, Maps);
      pushPinsToAdd.forEach((pushPin) => {
        if (pushPin === null) {
          return;
        }
        const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
        newPin.metadata = {
          ...pushPin.options,
        };
        Maps.Events.addHandler(newPin, "click", async (e) => {
          onClick(pushPin.data);
        });
        mapTmp.entities.push(newPin);
      });
      // Maps.loadModule("Maps.Clustering", function () {
      //   //Create a ClusterLayer and add it to the map.
      //   const clusterLayer = new Maps.ClusterLayer(map.entities);
      //   map.current.layers.insert(clusterLayer);
      // });
    },
    [onClick]
  );

  // add pushpins
  const addPushpins = useCallback((pushPinsToAdd, map, Maps) => {
    removePushpins(map, Maps);
    pushPinsToAdd.forEach((pushPin) => {
      if (pushPin === null) {
        return;
      }
      const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
      map.entities.push(newPin);
    });
  }, []);

  // set view options
  function setMapViewOptions(map, viewOptions, Maps) {
    const options = { ...viewOptions };
    if (viewOptions.mapTypeId) {
      options.mapTypeId = Maps.MapTypeId[viewOptions.mapTypeId];
    }
    if (viewOptions.hideRoadLabels) {
      options.labelOverlay = Maps.LabelOverlay.hidden;
    }
    map.setView(options);
  }

  // set map options
  function setMapOptions(map, mapOptions, Maps) {
    const options = { ...mapOptions };

    // some map options require values from the Maps class
    // these conditional statements handle those cases
    if (mapOptions.navigationBarMode) {
      options.navigationBarMode =
        Maps.NavigationBarMode[mapOptions.navigationBarMode];
    }
    if (mapOptions.navigationBarOrientation) {
      options.navigationBarOrientation =
        Maps.NavigationBarOrientation[mapOptions.navigationBarOrientation];
    }
    if (mapOptions.supportedMapTypes) {
      options.supportedMapTypes = mapOptions.supportedMapTypes.map(
        (type) => Maps.MapTypeId[type]
      );
    }
    map.setOptions(options);
  }

  // make map, set options, add pins
  const makeMap = useCallback(() => {
    const { Maps } = (window as any).Microsoft;
    // only make a new map if one doesn't already exist
    if (!map.current) {
      map.current = new Maps.Map(mapContainer.current, {
        credentials: bingMapsKey,
        showMapTypeSelector: false,
        navigationBarMode: Maps.NavigationBarMode.square,
      });
    }

    // set viewOptions, if any
    if (viewOptions) {
      setMapViewOptions(map.current, viewOptions, Maps);
    }

    // set mapOptions, if any
    if (mapOptions) {
      setMapOptions(map.current, mapOptions, Maps);
    }

    // add push pins, if any
    if (pushPins) {
      addPushpins(pushPins, map.current, Maps);
    }
    if (addPushpinsWithInfoboxes) {
      addPushpinsWithInfoboxes(pushPinsWithInfoboxes, map.current, Maps);
    }
    addLayer();
    onMapReady && onMapReady({ map: map.current });
  }, [
    addLayer,
    addPushpins,
    addPushpinsWithInfoboxes,
    bingMapsKey,
    mapOptions,
    onMapReady,
    pushPins,
    pushPinsWithInfoboxes,
    viewOptions,
  ]);

  useEffect(() => {
    addLayer();
  }, [addLayer]);

  useEffect(() => {
    if (
      (window as any).Microsoft &&
      (window as any).Microsoft?.Maps &&
      map.current &&
      addPushpinsWithInfoboxes
    ) {
      const { Maps } = (window as any)?.Microsoft;
      addPushpinsWithInfoboxes(pushPinsWithInfoboxes, map.current, Maps);
    }
  }, [addPushpinsWithInfoboxes, pushPinsWithInfoboxes]);

  useEffect(() => {
    if ((window as any).Microsoft && (window as any).Maps) {
      makeMap();
    } else {
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("type", "text/javascript");
      scriptTag.setAttribute(
        "src",
        `https://www.bing.com/api/maps/mapcontrol?callback=makeMap`
      );
      scriptTag.async = true;
      scriptTag.defer = true;
      document.body.appendChild(scriptTag);
      (window as any).makeMap = makeMap;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div ref={mapContainer} style={{ height: height, width: width }}></div>
    </>
  );
}
