import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import TableCustom from "../../../../common/components/TableCustom";
import { Columns } from "../../../../common/components/TableCustom/utils";
import TagPM from "../../../../common/components/TagPM";
import { some, SUCCESS_CODE } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import FormDialog from "./FormDialog";

interface Props {
  info: some;
}
const IndicatorsTab = (props: Props) => {
  const { info } = props;
  const { id = "" } = useParams<{ id: string }>();
  const {
    API_PATHS,
    intl,
    dispatch,
    fetchThunk,
    enqueueSnackbar,
    confirmDialog,
  } = useGeneralHook();
  const nreTypeId = info?.monitoringLocation?.construction?.nreTypeId;

  const [formData, setFormData] = useState<some | undefined>();

  const { data, isValidating, revalidate } = useSWR(
    id ? API_PATHS.waterQualityIndicators.index(id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onCreateUpdateForm = useCallback(
    async (value: some) => {
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.waterQualityIndicators.update(id, value.id),
              "put",
              value
            )
          )
        : await dispatch(
            fetchThunk(
              API_PATHS.waterQualityIndicators.index(id),
              "post",
              value
            )
          );
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      API_PATHS.waterQualityIndicators,
      dispatch,
      enqueueSnackbar,
      fetchThunk,
      id,
      revalidate,
    ]
  );

  const onDelete = useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(
            API_PATHS.waterQualityIndicators.delete(value.id),
            "delete"
          )
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [
      confirmDialog,
      intl,
      dispatch,
      fetchThunk,
      API_PATHS.waterQualityIndicators,
      enqueueSnackbar,
      revalidate,
    ]
  );

  const columns = [
    {
      title: "indicator",
      dataIndex: "indicator.name",
    },
    {
      title: "indicator.unit",
      align: "center",
      dataIndex: "indicator.unit",
    },
    {
      title: "qcvn",
      align: "center",
      dataIndex: "qcvn.name",
    },
    {
      title: "qcvn.type",
      align: "center",
      dataIndex: "thresholdInformation.name",
    },
    {
      title: "analytical",
      align: "center",
      dataIndex: "analyticalMethod.name",
    },
    {
      title: "waterQuality.indicatorsValue",
      align: "center",
      dataIndex: "value",
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <TagPM>
            <IconButton
              style={{ marginRight: 8 }}
              size="small"
              onClick={() => {
                setFormData(record);
              }}
            >
              <Edit />
            </IconButton>
          </TagPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <Box>
      <Box
        marginBottom={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle1">
          <FormattedMessage id="waterQuality.indicatorsTable" />
        </Typography>
        <Button
          startIcon={<Add />}
          onClick={() => {
            setFormData({});
          }}
        >
          <FormattedMessage id="create" />
        </Button>
      </Box>
      <TableCustom
        dataSource={data}
        columns={columns}
        loading={isValidating}
        fixIndexColumn
        dataKey="key"
      />
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
        nreTypeId={nreTypeId}
      />
    </Box>
  );
};

export default IndicatorsTab;
