import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { some } from "../../../common/constants";

interface Props {
  data?: some[];
  loading: boolean;
  onCreateUpdateForm: (value: some) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { data, loading, onCreateUpdateForm, onDelete } = props;

  const columns = [
    {
      title: "menu.name",
      dataIndex: "name",
    },
    {
      title: "menu.url",
      dataIndex: "url",
    },
    {
      title: "description",
      dataIndex: "description",
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <TagPM>
            <IconButton
              style={{ marginRight: 8 }}
              size="small"
              onClick={() => {
                onCreateUpdateForm(record);
              }}
            >
              <Edit />
            </IconButton>
          </TagPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">
            <FormattedMessage id="menu.list" />
          </Typography>
          <TagPM>
            <Button
              startIcon={<Add />}
              color="primary"
              onClick={() => onCreateUpdateForm({})}
            >
              <FormattedMessage id="create" />
            </Button>
          </TagPM>
        </Box>
        <TableCustom dataSource={data} loading={loading} columns={columns} />
      </Paper>
    </>
  );
};
export default Table;
