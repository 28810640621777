// Material UI
import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, Paper, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import { some, SUCCESS_CODE } from "../../../common/constants";
// API
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
import SchemaForm from "../../../common/SchemaForm";
import RefreshIcon from "@mui/icons-material/Refresh";
// Redux

const UserConstructionPage = (props: any) => {
  const { dispatch, enqueueSnackbar, API_PATHS, fetchThunk } = useGeneralHook();
  const { id } = useParams<{ id: string }>();
  const {
    params,
    setParams,
    clearParams,
    pagination,
    onPageChange,
    onRowsPerPageChange,
  } = usePaginationHook();
  const [selection, setSelection] = useState<some[]>([]);
  const [loading, setLoading] = useState(false);

  const { data, isValidating, revalidate } = useSWR(
    id
      ? API_PATHS.construction.userConstruction({ userId: id, ...params })
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onSave = useCallback(async () => {
    setLoading(true);
    const json = await dispatch(
      fetchThunk(API_PATHS.construction.userConstructionUpdate, "post", {
        userId: id,
        constructions: selection,
      })
    );
    setLoading(false);
    if (json.code === SUCCESS_CODE) {
      revalidate();
    }
    enqueueSnackbar({
      message: json.msg,
      requestId: json.requestId,
      variant: json.code,
    });
  }, [
    API_PATHS.construction,
    dispatch,
    enqueueSnackbar,
    fetchThunk,
    id,
    revalidate,
    selection,
  ]);

  const columns = [
    {
      title: "construction.name",
      dataIndex: "constructionName",
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 70,
      // hidden: true,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <Checkbox
            checked={record.isChecked}
            onChange={(e) => {
              setSelection((old) => {
                return old.map((v) => {
                  if (v.constructionId === record.constructionId) {
                    return { ...v, isChecked: e.target.checked };
                  }
                  return v;
                });
              });
            }}
          />
        </>
      ),
    },
  ] as Columns[];

  useEffect(() => {
    if (data?.items) {
      setSelection(data?.items);
    }
  }, [data]);

  return (
    <>
      <SchemaForm
        formProps={{ style: { marginBottom: 16 } }}
        formData={params}
        onSubmit={setParams}
        hideSubmitButton
        schema={{
          fields: ({ formProps: { intl, dispatch } }) => ({
            constructionId: {
              type: "auto-complete",
              label: intl.formatMessage({ id: "construction.name" }),
              placeholder: intl.formatMessage({
                id: "select",
              }),
              loadOptions: async (name: string) => {
                const json = await dispatch(
                  fetchThunk(
                    API_PATHS.construction.index({
                      name,
                      pageSize: 100,
                    })
                  )
                );
                return json.data?.items?.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              },
              propsWrapper: { xs: 3 },
              noHelperText: true,
            },
            search: {
              type: "submitButton",
              fullWidth: true,
              label: intl.formatMessage({ id: "search" }),
              propsWrapper: { xs: "auto", marginTop: 2.5 },
            },
            reset: {
              type: "button",
              variant: "text",
              fullWidth: true,
              label: <RefreshIcon />,
              style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
              propsWrapper: { xs: "auto", marginTop: 3 },
              onClick: () => {
                clearParams();
              },
            },
          }),
        }}
      />
      <Paper sx={{ p: 2 }}>
        <Box display="flex" alignItems="center" marginBottom={2} gap={1}>
          <Typography variant="h6" flex={1}>
            <FormattedMessage id="userConstruction.list" />
          </Typography>
          <LoadingButton loading={loading} color="primary" onClick={onSave}>
            <FormattedMessage id="save" />
          </LoadingButton>
        </Box>
        <TableCustom
          dataSource={selection}
          loading={isValidating}
          paginationProps={{
            count: data?.total,
            page: pagination.page,
            rowsPerPage: pagination.pageSize,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
          }}
          columns={columns}
        />
      </Paper>
    </>
  );
};

export default UserConstructionPage;
