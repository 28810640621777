import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { ROUTES } from "../../layout/constants";
import { AppState } from "../../redux/reducer";

function ProtectedRoute({ children }: { children?: JSX.Element }) {
  const { authentication } = useSelector((state: AppState) => state.auth);
  let location = useLocation();

  if (!authentication) {
    const from = (location && `${location.pathname}${location.search}`) || "/";
    return (
      <Navigate
        to={{
          pathname: ROUTES.login,
          search: `?from=${encodeURIComponent(from)}`,
        }}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
}

export default ProtectedRoute;
