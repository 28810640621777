import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Collapse,
  ListItem,
  ListItemIcon,
  styled,
  Typography,
} from "@mui/material";
import { alpha, useTheme } from "@mui/system";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import LinkPM from "../common/components/LinkPM";
import { IRouteObject } from "./utils";

const ListItemStyle = styled((props: any) => (
  <ListItem button disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  // textTransform: "capitalize",
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  minWidth: "unset",
  width: 24,
  height: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
interface Props {
  data: IRouteObject;
  pathname: string;
  open?: boolean;
}

const DefaultAsideItems: React.FC<Props> = (props: Props) => {
  const { data, pathname, open } = props;
  const [openList, setOpen] = React.useState(false);
  const theme = useTheme();

  const checkIsActive =
    data.children && !data?.children.every((child) => !child.index)
      ? data.children.findIndex((v) => v.path && pathname.includes(v.path)) !==
        -1
      : data.children &&
        data?.children.every((child) => !child.index) &&
        data.path
      ? pathname.includes(data.path)
      : data.path
      ? pathname.includes(data.path)
      : false;

  const activeRootStyle = {
    color: theme.palette.common.white,
    fontWeight: "fontWeightMedium",
    bgcolor: theme.palette.primary.main,
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    // color: "text.primary",
    bgcolor: alpha(theme.palette.primary.main, 0.3),
    fontWeight: "fontWeightMedium",
  };

  useEffect(() => {
    setOpen(checkIsActive);
  }, [checkIsActive, pathname]);

  if (data.hidden || !data.path) {
    return null;
  }

  if (data.children && data.children.length > 0) {
    return (
      <Box>
        <ListItemStyle
          onClick={() => setOpen(!openList)}
          sx={{
            // color: "text.primary",
            color: "common.white",
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.8),
            },
            ...(checkIsActive && activeRootStyle),
          }}
        >
          <ListItemIconStyle>
            {data.icon ? (
              <Box
                // color={checkIsActive ? "common.white" : "text.primary"}
                color={"common.white"}
                height={24}
                width={24}
              >
                {data.icon}
              </Box>
            ) : (
              <Box
                component="span"
                sx={{
                  width: 4,
                  height: 4,
                  display: "flex",
                  borderRadius: "50%",
                  alignItems: "center",
                  justifyContent: "center",
                  // bgcolor: "text.disabled",
                  bgcolor: "common.white",
                  transition: (theme) => theme.transitions.create("transform"),
                  ...(checkIsActive && {
                    transform: "scale(2)",
                    // bgcolor: "primary.main",
                  }),
                }}
              />
            )}
          </ListItemIconStyle>
          <Box
            style={{
              marginLeft: 8,
              flex: 1,
              opacity: open ? 1 : 0,
              transition: "all 200ms",
            }}
          >
            {typeof data.title === "string" && data.title ? (
              <Typography noWrap variant="body1" color="inherit">
                <FormattedMessage id={data.title} />
              </Typography>
            ) : (
              data.title
            )}
          </Box>
          <KeyboardArrowDownIcon
            style={{
              transform: openList ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 200ms",
              fontSize: 16,
            }}
          />
        </ListItemStyle>
        <Collapse in={openList && open} unmountOnExit>
          {data.children?.map((item, index) => {
            if (!item.path || item.hidden) {
              return null;
            }
            const checkIsActiveSub = item.path && pathname.includes(item.path);

            return (
              <LinkPM
                key={item.path}
                to={{
                  pathname: item.path,
                }}
                style={{ display: "flex", flex: 1 }}
              >
                <ListItemStyle
                  sx={{
                    // color: "text.primary",
                    color: "common.white",
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.5),
                    },
                    ...(checkIsActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    {item.icon ? (
                      <Box
                        // color={checkIsActive ? "common.white" : "text.primary"}
                        color={"common.white"}
                        height={24}
                        width={24}
                      >
                        {item.icon}
                      </Box>
                    ) : (
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: "flex",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          // bgcolor: "text.disabled",
                          bgcolor: "common.white",
                          transition: (theme) =>
                            theme.transitions.create("transform"),
                          ...(checkIsActiveSub && {
                            transform: "scale(2)",
                            // bgcolor: "primary.main",
                          }),
                        }}
                      />
                    )}
                  </ListItemIconStyle>
                  <Box
                    style={{
                      marginLeft: 8,
                      flex: 1,
                      opacity: open ? 1 : 0,
                      transition: "all 200ms",
                    }}
                  >
                    {typeof item.title === "string" && item.title ? (
                      <Typography noWrap variant="body1" color="inherit">
                        <FormattedMessage id={item.title} />
                      </Typography>
                    ) : (
                      item.title
                    )}
                  </Box>
                </ListItemStyle>
              </LinkPM>
            );
          })}
        </Collapse>
      </Box>
    );
  }

  return (
    <>
      <LinkPM
        to={{
          pathname: data.path,
        }}
        style={{ display: "flex", flex: 1 }}
      >
        <ListItemStyle
          sx={{
            // color: "text.primary",
            color: "common.white",
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.5),
            },
            ...(checkIsActive && activeRootStyle),
          }}
        >
          <ListItemIconStyle>
            {data.icon ? (
              <Box
                // color={checkIsActive ? "common.white" : "text.primary"}
                color={"common.white"}
                height={24}
                width={24}
              >
                {data.icon}
              </Box>
            ) : (
              <Box
                component="span"
                sx={{
                  width: 4,
                  height: 4,
                  display: "flex",
                  borderRadius: "50%",
                  alignItems: "center",
                  justifyContent: "center",
                  // bgcolor: "text.disabled",
                  bgcolor: "common.white",
                  transition: (theme) => theme.transitions.create("transform"),
                  ...(checkIsActive && {
                    transform: "scale(2)",
                    // bgcolor: "primary.main",
                  }),
                }}
              />
            )}
          </ListItemIconStyle>
          <Box
            style={{
              marginLeft: 8,
              flex: 1,
              opacity: open ? 1 : 0,
              transition: "all 200ms",
            }}
          >
            {typeof data.title === "string" && data.title ? (
              <Typography noWrap variant="body1" color="inherit">
                <FormattedMessage id={data.title} />
              </Typography>
            ) : (
              data.title
            )}
          </Box>
        </ListItemStyle>
      </LinkPM>
    </>
  );
};

export default DefaultAsideItems;
