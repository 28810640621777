import { Delete } from "@mui/icons-material";
import {
  ButtonBase,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import LoadingIcon from "../../../common/components/LoadingIcon";
import useDebounce from "../../../common/hook/useDebounce";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import { NoRecordIcon, Search } from "../../../svg";

interface Props extends HookPaginationProps {}

const Filter = (props: Props) => {
  const { filter, setParams } = props;
  const { nreTypeId = "" } = useParams<{ nreTypeId: string }>();

  const [term, setTerm] = useState("");
  const { intl, API_PATHS, fetchThunk, dispatch } = useGeneralHook();
  const debouncedSearch = useDebounce(term, 1000);

  const { data, isValidating } = useSWR(
    API_PATHS.construction.index({
      page: 0,
      pageSize: 100,
      name: debouncedSearch,
      constructionTypeId: filter?.constructionTypeId,
      nreTypeId,
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json.data?.items?.map((v) => ({ id: v.id, name: v.name }));
    },
    { revalidateOnFocus: false }
  );

  return (
    <Paper style={{ width: 320, overflow: "hidden" }}>
      <Box padding={1} borderBottom={1} borderColor="divider" display="flex">
        <Typography variant="body1" style={{ flex: 1 }}>
          <FormattedMessage id="construction.list" /> &nbsp;
        </Typography>
        {filter.constructionIds?.length > 0 && (
          <Tooltip
            title={filter.constructionIds?.map((v) => (
              <Box>{v.name}</Box>
            ))}
          >
            <Chip
              label={
                <Typography variant="caption">
                  {`+${filter.constructionIds?.length}`}
                </Typography>
              }
              size="small"
            />
          </Tooltip>
        )}
      </Box>
      <Box padding={1} borderBottom={1} borderColor="divider">
        <TextField
          fullWidth
          value={term}
          onChange={(e) => {
            setTerm(e.target.value);
          }}
          placeholder={intl.formatMessage({
            id: "construction.enterName",
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className="svgStroke" />
              </InputAdornment>
            ),
            endAdornment: filter.constructionIds?.length > 0 && (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    setParams({ constructionIds: [] });
                    setTerm("");
                  }}
                >
                  <Delete />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box overflow={"auto"} height="calc(100vh - 250px)">
        {isValidating ? (
          <LoadingIcon />
        ) : data?.length > 0 ? (
          data?.map((item) => {
            const selected = (filter.constructionIds || [])?.includes(item.id);
            return (
              <ButtonBase
                key={item.id}
                sx={{
                  padding: 1,
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                  justifyContent: "flex-start",
                  minHeight: 40,
                }}
                onClick={() => {
                  setParams({
                    constructionIds: selected
                      ? filter.constructionIds?.filter((val) => val !== item.id)
                      : [...(filter.constructionIds || []), item.id],
                  });
                }}
              >
                <Checkbox
                  checked={selected}
                  style={{ padding: 0, marginRight: 8 }}
                />
                <Typography
                  variant="caption"
                  style={{ flex: 1, textAlign: "start" }}
                >
                  {item.name}
                </Typography>
              </ButtonBase>
            );
          })
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height={320}
          >
            <NoRecordIcon />
            <Typography variant="body2" style={{ marginTop: 16 }}>
              <FormattedMessage id="noData" />
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
export default Filter;
