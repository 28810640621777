// Material UI
import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
// API
import useSWR from "swr";
import TableCustom from "../../../common/components/TableCustom";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import FormDialog from "./FormDialog";

interface Props {}
const MetadataTab = (props: Props) => {
  const {
    dispatch,
    enqueueSnackbar,
    API_PATHS,
    fetchThunk,
    confirmDialog,
    intl,
  } = useGeneralHook();
  const { id = "" } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<some | undefined>();

  const { data, isValidating, revalidate } = useSWR(
    id ? API_PATHS.licenseMetaData.index(id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading?: (loading: boolean) => void) => {
      setLoading && setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.licenseMetaData.update(id, value.id),
              "put",
              value
            )
          )
        : await dispatch(
            fetchThunk(API_PATHS.licenseMetaData.index(id), "post", value)
          );
      setLoading && setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      API_PATHS.licenseMetaData,
      dispatch,
      enqueueSnackbar,
      fetchThunk,
      id,
      revalidate,
    ]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.licenseMetaData.delete(value.id), "delete")
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [
      confirmDialog,
      intl,
      dispatch,
      fetchThunk,
      API_PATHS.licenseMetaData,
      enqueueSnackbar,
      revalidate,
    ]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6" flex={1}>
          <FormattedMessage id="licenseMetadata.list" />
        </Typography>
        <Button
          startIcon={<Add />}
          color="primary"
          onClick={() => setFormData({})}
        >
          <FormattedMessage id="create" />
        </Button>
      </Box>
      <TableCustom
        dataSource={data}
        loading={isValidating}
        columns={[
          {
            title: "licenseMetadata.name",
            dataIndex: "name",
          },
          {
            title: "constructionKind",
            dataIndex: "constructionKind.name",
          },
          {
            title: "longitude",
            dataIndex: "longitude",
            align: "center",
          },
          {
            title: "latitude",
            dataIndex: "latitude",
            align: "center",
          },

          {
            title: "action",
            align: "center",
            fixed: "right",
            width: 100,
            render: (record: some, index: number) => (
              <>
                <IconButton
                  size="small"
                  onClick={() => setFormData(record)}
                  style={{ marginRight: 8 }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    onDelete(record);
                  }}
                  size="small"
                >
                  <Delete />
                </IconButton>
              </>
            ),
          },
        ]}
      />
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
    </>
  );
};

export default MetadataTab;
