// Material UI
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import LoadingIcon from "../../../common/components/LoadingIcon";
import { some } from "../../../common/constants";
import useFilterHook from "../../../common/hook/useFilterHook";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { NoData } from "../../../svg";
// Component
import Filter from "../component/Filter";
import MainContent from "../component/MainContent";

interface Props {}

const PeriodicMonitoringPage = (props: Props) => {
  const { nreTypeId } = useParams<{ nreTypeId: string }>();
  const { dispatch, API_PATHS, fetchThunk } = useGeneralHook();
  const hookFilter = useFilterHook();
  const { filter } = hookFilter;

  const { data, isValidating } = useSWR(
    API_PATHS.constructionType.index({
      nreTypeId,
      pageSize: 1000,
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data?.items;
    },
    { revalidateOnFocus: false }
  );

  return (
    <Box display="flex" flexDirection="column">
      <Filter {...hookFilter} />
      <Box display="flex" flexDirection={"column"} gap={2}>
        {isValidating && !data ? (
          <LoadingIcon />
        ) : data?.length > 0 ? (
          data
            .filter((v) =>
              filter.constructionTypeId
                ? filter.constructionTypeId?.id === v.id
                : true
            )
            .map((item: some) => (
              <MainContent
                key={item.id}
                constructionType={item}
                filterParams={filter}
              />
            ))
        ) : (
          <Box
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              padding: 64,
              flexDirection: "column",
            }}
          >
            <NoData />
            <Box marginTop={1}>
              <FormattedMessage id="noData" />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PeriodicMonitoringPage;
