import { green, grey, orange, purple, red } from "@mui/material/colors";
import { some } from "../../common/constants";
import {
  DisconnectCircle,
  ErrorCircle,
  ResetCircle,
  SuccessCircle,
  WarningCircle,
} from "../../svg";

export const LIST_STATUS_STATION = [
  {
    key: "exceed",
    icon: <ErrorCircle />,
    label: "status.exceed",
    color: red[600],
    value: [80],
    backgroundColor: "#FDE4E2",
  },
  {
    key: "good",
    icon: <SuccessCircle />,
    label: "status.success",
    color: green[500],
    value: [0],
    backgroundColor: "#C8FACD",
  },
  {
    key: "calibrating",
    icon: <ResetCircle />,
    label: "status.reset",
    color: purple[500],
    value: [1],
    backgroundColor: "#F3DCF7",
  },
  {
    key: "sensorError",
    icon: <WarningCircle />,
    label: "status.error",
    color: orange[600],
    value: [2, 3],
    backgroundColor: "#FEF5D4",
  },
  {
    key: "disconnected",
    icon: <DisconnectCircle />,
    label: "status.disconnect",
    color: grey[300],
    colorMap: "grey",
    value: [99, 98],
    backgroundColor: "#F4F6F8",
  },
];

function rad(x: number) {
  return (x * Math.PI) / 180;
}

export function getDistance(p1: some, p2: some) {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d / 1000; // returns the distance in meter
}
