import React from "react";
import { FormattedMessage } from "react-intl";
import useGeneralHook from "../hook/useGeneralHook";
import { Box, IconButton, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

interface Props {
  attachment?: string;
}

const AttachmentColumn = (props: Props) => {
  const { attachment } = props;
  const { setLoading, dispatch, fetchThunk, API_PATHS, enqueueSnackbar } =
    useGeneralHook();
  const getFile = React.useCallback(async () => {
    if (attachment) {
      setLoading(true);
      try {
        const json = await dispatch(
          fetchThunk(
            API_PATHS.upload.getFile(attachment),
            "get",
            undefined,
            "application/octet-stream"
          )
        );
        setLoading(false);
        if (json.status !== 200) {
          return;
        }
        const newBlob = new Blob([json.blob as Blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", `${attachment}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(json.blob as any);
      } catch (e: any) {
        enqueueSnackbar({
          message: <FormattedMessage id="downloadFail" />,
          variant: "success",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    API_PATHS.upload,
    attachment,
    dispatch,
    enqueueSnackbar,
    fetchThunk,
    setLoading,
  ]);

  if (!attachment) {
    return <></>;
  }

  return (
    <Box display={"flex"} flexDirection={"row"}>
      <Typography variant="body2">{attachment}</Typography>
      <IconButton
        component="label"
        style={{ padding: 4, marginRight: -8 }}
        onClick={getFile}
      >
        <DownloadIcon />
      </IconButton>
    </Box>
  );
};
export default AttachmentColumn;
