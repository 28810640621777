import Close from "@mui/icons-material/Close";
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputBaseProps,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import React, { memo } from "react";
import { useIntl } from "react-intl";
import { some } from "../../../constants";

interface IOption {
  id?: string | number | undefined;
  disabled?: boolean;
  name?: React.ReactNode | string | number;
}

export interface SelectCustomProps
  extends Omit<SelectProps, "defaultValue" | "value" | "onChange" | "ref"> {
  label?: React.ReactNode;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  required?: boolean;
  options?: (IOption | some)[];
  placeholder?: string;
  rawOptions?: boolean;
  disableClearBtn?: boolean;
  InputProps?: InputBaseProps;
  loadOptions?: () => Promise<some[]>;
  value: any;
  onChange?: (val: any) => void;
  formControlProps?: FormControlProps;
  hasAllOptions?: boolean;
  loadKey?: any;
}

const SelectCustom = React.forwardRef<HTMLDivElement | null, SelectCustomProps>(
  (props: SelectCustomProps, ref) => {
    const {
      label,
      required,
      options,
      placeholder,
      rawOptions,
      error,
      InputProps,
      loadOptions,
      value,
      onChange,
      disableClearBtn: disableCloseBtn,
      formControlProps,
      hasAllOptions,
      multiple,
      loadKey,
      ...rest
    } = props;
    const intl = useIntl();
    const [optionsTmp, setOption] = React.useState<typeof options>(options);

    const isAllSelected = hasAllOptions
      ? multiple && optionsTmp
        ? value?.length === optionsTmp?.length || value?.length === 0
        : value === ""
      : false;

    const handleChange = (valueSelect: any) => {
      if (!onChange) {
        return;
      }
      if (multiple) {
        const tmp =
          typeof valueSelect === "string"
            ? valueSelect.split(",")
            : valueSelect;
        if (tmp.length === 0) {
          onChange((optionsTmp || []).map((v) => v.id));
          return;
        }
        if (isAllSelected) {
          onChange(tmp.filter((v) => v !== "all"));
        } else if (hasAllOptions && (tmp || []).includes("all")) {
          onChange((optionsTmp || []).map((v) => v.id));
        } else {
          onChange(tmp);
        }
        return;
      } else {
        onChange(valueSelect);
      }
    };

    const onLoadOptions = React.useCallback(async () => {
      if (loadOptions) {
        const data = await loadOptions();
        if (data && data.length > 0) {
          setOption(data);
        }
      }
    }, [loadOptions]);

    React.useEffect(() => {
      if (!isEqual(options, optionsTmp)) {
        setOption(options);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    React.useEffect(() => {
      onLoadOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadKey]);

    return (
      <FormControl error={!!error} fullWidth ref={ref} {...formControlProps}>
        {label && (
          <InputLabel required={required} shrink>
            {label}
          </InputLabel>
        )}
        <Select
          value={isAllSelected ? ["all"] : value}
          multiple={multiple}
          onChange={(event) => handleChange(event.target.value)}
          fullWidth
          input={<OutlinedInput label="Name" />}
          endAdornment={
            ((multiple && value?.length > 0 && !isAllSelected) ||
              (!multiple && value !== " ")) &&
            !rest.readOnly &&
            !rest.disabled &&
            !disableCloseBtn ? (
              <InputAdornment
                position="end"
                sx={{ display: "none", position: "absolute", right: 16 }}
                className="clear-btn"
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    handleChange(
                      multiple ? (hasAllOptions ? ["all"] : []) : null
                    )
                  }
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : null
          }
          sx={{
            "&:hover": {
              "& .clear-btn": {
                display: "flex",
              },
            },
            ...rest.sx,
          }}
          {...rest}
          style={{ height: 32, ...rest.style }}
        >
          {placeholder && (
            <MenuItem value=" " disabled={true} style={{ opacity: ".5" }}>
              <Typography
                variant="inherit"
                style={{ opacity: ".5" }}
                component="span"
              >
                {placeholder}
              </Typography>
            </MenuItem>
          )}
          {hasAllOptions && (
            <MenuItem value={multiple ? "all" : " "}>
              {intl.formatMessage({ id: "all" })}
            </MenuItem>
          )}

          {Array.isArray(optionsTmp) &&
            optionsTmp?.map((option: IOption, index: number) => {
              const { name, disabled, id, ...rest } = option;
              return (
                <MenuItem key={index} value={id} disabled={disabled} {...rest}>
                  {typeof name === "string"
                    ? rawOptions || loadOptions
                      ? name
                      : name
                      ? intl.formatMessage({ id: name })
                      : ""
                    : name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }
);

export default memo(SelectCustom);
