import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import * as React from "react";
import useSWR from "swr";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  formData?: some;
}
const FTPTab: React.FunctionComponent<Props> = (props) => {
  const { formData } = props;

  const { dispatch, enqueueSnackbar, API_PATHS, fetchThunk } = useGeneralHook();

  const { data, isValidating, revalidate } = useSWR(
    formData?.id ? API_PATHS.ftp.index(formData?.id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  const onSubmit = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = await dispatch(
        fetchThunk(
          API_PATHS.ftp.index(formData?.id),
          value.id ? "put" : "post",
          value
        )
      );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      dispatch,
      fetchThunk,
      API_PATHS.ftp,
      formData?.id,
      enqueueSnackbar,
      revalidate,
    ]
  );

  return (
    <>
      <SchemaForm
        loading={isValidating}
        onSubmit={onSubmit}
        formData={data}
        onReset
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
        schema={{
          fields: ({
            formProps: {
              intl,
              dispatch,
              fetchThunk,
              API_PATHS,
              setState,
              state,
            },
            valuesField,
          }) => {
            return {
              ipLocal: {
                type: "text-field",
                label: intl.formatMessage({ id: "ftp.ip" }),
                placeholder: intl.formatMessage({ id: "ftp.ipEnter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },

              ipPublic: {
                type: "text-field",
                label: intl.formatMessage({ id: "ftp.ipPublic" }),
                placeholder: intl.formatMessage({ id: "ftp.ipPublicEnter" }),
                propsWrapper: { xs: 6 },
              },
              port: {
                type: "text-field",
                label: intl.formatMessage({ id: "ftp.port" }),
                placeholder: intl.formatMessage({ id: "ftp.portEnter" }),
                propsWrapper: { xs: 6 },
              },
              user: {
                type: "text-field",
                label: intl.formatMessage({ id: "ftp.userName" }),
                placeholder: intl.formatMessage({ id: "ftp.userNameEnter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              password: {
                type: "text-field",
                label: intl.formatMessage({ id: "ftp.password" }),
                placeholder: intl.formatMessage({ id: "ftp.passwordEnter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
                inputType: state.showPassword ? "text" : "password",
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setState({ showPassword: !state.showPassword })
                        }
                      >
                        {state.showPassword ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
              path: {
                type: "text-field",
                label: intl.formatMessage({ id: "ftp.path" }),
                placeholder: intl.formatMessage({ id: "ftp.pathEnter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              directoryStructure: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "ftp.directoryStructure" }),
                placeholder: intl.formatMessage({
                  id: "ftp.directoryStructureEnter",
                }),
                loadOptions: async () => {
                  const json = await dispatch(
                    fetchThunk(API_PATHS.ftp.directory)
                  );
                  return json.data;
                },
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },

              filename: {
                type: "text-field",
                label: intl.formatMessage({ id: "ftp.fileName" }),
                placeholder: intl.formatMessage({ id: "ftp.fileNameEnter" }),
                propsWrapper: { xs: 6 },
              },
              reconnectNumber: {
                type: "text-field",
                label: intl.formatMessage({ id: "ftp.reconnectNumber" }),
                placeholder: intl.formatMessage({
                  id: "ftp.reconnectNumberEnter",
                }),
                inputType: "number",
                register: {
                  min: {
                    value: 0,
                    message: intl.formatMessage({ id: "ftp.min" }),
                  },
                },
                required: false,
                propsWrapper: { xs: 6 },
              },
              startAt: {
                type: "dateTimePicker",
                label: intl.formatMessage({ id: "ftp.startAt" }),
                maxDate: valuesField.endAt,
                propsWrapper: { xs: 6 },
              },
              endAt: {
                type: "dateTimePicker",
                label: intl.formatMessage({ id: "ftp.endAt" }),
                minDate: valuesField.startAt,
                propsWrapper: { xs: 6 },
              },
            };
          },
        }}
      />
    </>
  );
};

export default FTPTab;
