import { some } from "../common/constants";

export interface IRouteObject {
  caseSensitive?: boolean;
  children?: IRouteObject[];
  element?: React.ReactNode;
  icon?: React.ReactNode;
  index?: boolean;
  hidden?: boolean;
  staticRoute?: boolean;
  fixedRoute?: boolean;
  path?: string;
  title?: string | React.ReactNode;
  listRole?: string[][];
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const mappedRoutesPermissionLayout = (
  list: IRouteObject[],
  menus: some[],
  isAdmin?: boolean
): IRouteObject[] => {
  return list
    .map((one) => {
      if (one.staticRoute || one.index || one.fixedRoute) {
        return one;
      }

      if (one.path) {
        const tmp = menus.find((v) => v.url === one.path);
        if (!tmp && !isAdmin) {
          return undefined;
        }
        const children =
          one.children && (isAdmin ? true : tmp?.children)
            ? mappedRoutesPermissionLayout(
                one.children,
                tmp?.children || [],
                isAdmin
              )
            : [];
        return {
          ...one,
          children: children,
        };
      }

      return undefined;
    })
    .filter(Boolean) as IRouteObject[];
};

export const mappedRoutesPermission = (
  list: IRouteObject[],
  menus: some[],
  isAdmin?: boolean
): IRouteObject[] => {
  return list
    .map((one) => {
      if (one.staticRoute) {
        return undefined;
      }

      if (one.path === "*" || one.index || one.fixedRoute) {
        return one;
      }

      if (one.path) {
        const tmp = menus.find((v) => v.url === one.path);
        if (!tmp && !isAdmin) {
          return undefined;
        }
        const children =
          one.children &&
          one.children?.filter((child) => !child.staticRoute)?.length > 0 &&
          (isAdmin ? true : tmp?.children)
            ? mappedRoutesPermission(
                one.children.filter((child) => !child.staticRoute),
                tmp?.children || [],
                isAdmin
              )
            : undefined;
        return {
          ...one,
          children: children,
        };
      }

      return undefined;
    })
    .filter(Boolean) as IRouteObject[];
};
