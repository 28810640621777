import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import { some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";

interface Props {
  data: some;
  hookPagination: HookPaginationProps;
  loading: boolean;
}
const Table = (props: Props) => {
  const { data, hookPagination, loading } = props;
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  const columns = [
    {
      title: "time",
      minWidth: 120,
      fixed: "left",
      variant: "caption",
      align: "center",
      dataIndex: "time",
    },
    {
      title: "totalTraffic",
      align: "center",
      dataIndex: "construction_tong_luuluong",
    },
  ] as Columns[];

  return (
    <>
      <TableCustom
        boxProps={{ style: { width: 440 } }}
        dataSource={data?.items}
        columns={columns}
        loading={loading}
        paginationProps={{
          count: data?.total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
    </>
  );
};
export default Table;
