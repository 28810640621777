import { Box } from "@mui/material";
import * as React from "react";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";
import { TIME_ZONE_OPTIONS } from "../constants";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <Box paddingY={2}>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
          }) => {
            return {
              jobClass: {
                type: "text-field",
                label: intl.formatMessage({ id: "job.jobClass" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              jobName: {
                type: "text-field",
                label: intl.formatMessage({ id: "job.jobName" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              cronExpression: {
                type: "text-field",
                label: intl.formatMessage({ id: "job.cronExpression" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },

              jobDataMapInputList: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "job.jobDataMapInputList" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                disableSearchByText: true,
                loadOptions: async () => {
                  const json = await dispatch(fetchThunk(API_PATHS.job.getAll));
                  return json.data?.items?.map((v) => ({
                    key: v.id,
                    value: v.jobName,
                  }));
                },
                getOptionLabel: (options) => options.value,
                isOptionEqualToValue: (option, value) => {
                  return option.key === value.key;
                },
                propsWrapper: { xs: 6 },
              },
              jobScheduleTime: {
                type: "dateTimePicker",
                label: intl.formatMessage({ id: "job.jobScheduleTime" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              repeatTime: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "job.repeatTime" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },

              jobDescription: {
                type: "text-field",
                label: intl.formatMessage({ id: "job.jobDescription" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                multiline: true,
                rows: 5,
                propsWrapper: { xs: 6 },
              },
              timeZone: {
                type: "select",
                options: TIME_ZONE_OPTIONS.map((one) => ({
                  ...one,
                  name: `${one.id}(${one.name})`,
                })),
                label: intl.formatMessage({ id: "job.timeZone" }),
                placeholder: intl.formatMessage({ id: "select" }),
                propsWrapper: { xs: 6 },
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
