import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { PAGE_SIZE, some } from "../constants";
import { stringifyUrl } from "../utils";

export interface HookPaginationProps
  extends ReturnType<typeof usePaginationHook> {}
interface Pagination {
  page: number;
  pageSize: number;
}
interface Props {
  defaultFilter?: some;
  defaultPagination?: some;
  disableLink?: boolean;
  disableSavePageSize?: boolean;
}
const usePaginationHook = (props?: Props) => {
  const {
    defaultFilter = {},
    defaultPagination,
    disableLink,
    disableSavePageSize,
  } = props || {};
  const DEFAULT_PAGINATION = useMemo(() => {
    return {
      page: 0,
      pageSize: Number(Cookies.get(PAGE_SIZE)) || 10,
    } as Pagination;
  }, []);

  const location = useLocation();
  const paramsUrl = useMemo(() => {
    let tmp: some = {};
    const search = new URLSearchParams(location.search);
    search.forEach(function (value, key) {
      if (value.trim() !== "") {
        try {
          tmp[key] = JSON.parse(value);
        } catch (e) {
          tmp[key] = value;
        }
      } else {
        tmp[key] = undefined;
      }
    });
    return tmp;
  }, [location.search]);
  const [pagination, setPagination] = useState<Pagination>({
    page: paramsUrl.page || defaultPagination?.page || DEFAULT_PAGINATION.page,
    pageSize:
      paramsUrl.pageSize ||
      defaultPagination?.pageSize ||
      DEFAULT_PAGINATION.pageSize,
  });
  const [filter, setFilter] = useState<some>({
    ...defaultFilter,
    ...paramsUrl,
  });

  const history = useNavigate();
  const clearParams = useCallback(
    (value?: some) => {
      if (disableLink) {
        setPagination((defaultPagination || DEFAULT_PAGINATION) as any);
        setFilter(value || defaultFilter);
      } else {
        history(
          {
            search: stringifyUrl({
              page: pagination.page,
              pageSize: pagination.pageSize,
              ...(filter?.tab ? { tab: filter.tab } : {}),
              ...(value || defaultFilter),
            }),
          },
          { replace: true, state: location.state }
        );
        setPagination((defaultPagination || DEFAULT_PAGINATION) as any);
        setFilter({
          ...(filter?.tab ? { tab: filter.tab } : {}),
          ...(value || defaultFilter),
        });
      }
    },
    [
      DEFAULT_PAGINATION,
      defaultFilter,
      defaultPagination,
      disableLink,
      filter.tab,
      history,
      location.state,
      pagination.page,
      pagination.pageSize,
    ]
  );

  const setParams = useCallback(
    (form: some) => {
      if (disableLink) {
        setPagination({
          page: form.page || pagination.page,
          pageSize: form.pageSize || pagination.pageSize,
        });
        setFilter({ ...filter, ...form });
      } else {
        history(
          {
            search: stringifyUrl({
              ...pagination,
              ...filter,
              page: 0,
              ...form,
            }),
          },
          { replace: true, state: location.state }
        );
      }
    },
    [disableLink, filter, history, location, pagination]
  );

  const onPageChange = useCallback(
    (event: unknown, newPage: number) => {
      if (disableLink) {
        setPagination((old) => ({ ...old, page: newPage }));
      } else {
        history(
          {
            search: stringifyUrl({ ...pagination, ...filter, page: newPage }),
          },
          { replace: true, state: location.state }
        );
      }
    },
    [disableLink, filter, history, location, pagination]
  );

  const onRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disableLink) {
        setPagination((old) => ({
          ...old,
          pageSize: parseInt(event.target.value, 10),
          page: 0,
        }));
      } else {
        history(
          {
            search: stringifyUrl({
              ...pagination,
              ...filter,
              pageSize: parseInt(event.target.value, 10),
              page: 0,
            }),
          },
          { replace: true, state: location.state }
        );
      }
    },
    [disableLink, filter, history, location, pagination]
  );

  useEffect(() => {
    !disableSavePageSize && Cookies.set(PAGE_SIZE, String(pagination.pageSize));
  }, [disableSavePageSize, pagination.pageSize]);

  useEffect(() => {
    if (!disableLink) {
      const { page, pageSize, ...rest } = paramsUrl;
      setPagination({
        page: page || 0,
        pageSize: pageSize || Number(Cookies.get(PAGE_SIZE)) || 10,
      });
      setFilter({ ...defaultFilter, ...rest });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableLink, paramsUrl]);

  const { tab, ...restFilter } = filter;

  return {
    pagination,
    filter,
    params: { ...pagination, ...restFilter } as some,
    tab,
    setParams,
    clearParams,
    onPageChange,
    onRowsPerPageChange,
  };
};

export default usePaginationHook;
