import { Add, Delete, Edit } from "@mui/icons-material";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { DATE_TIME_FORMAT, some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";

interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
  setChangePass: (value: some) => void;
  onCreateUpdateForm: (value: some) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const {
    hookPagination,
    data,
    loading,
    setChangePass,
    onCreateUpdateForm,
    onDelete,
  } = props;

  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns = [
    {
      title: "user.userId",
      dataIndex: "userId",
    },
    {
      title: "user.name",
      dataIndex: "name",
    },
    {
      title: "email",
      dataIndex: "email",
    },
    {
      title: "user.createdDate",
      render: (record) =>
        record.createdDate &&
        moment(record.createdDate).format(DATE_TIME_FORMAT),
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 280,
      // hidden: true,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <Box display={"flex"} gap={1} justifyContent="center">
          <LinkPM to={record.userId}>
            <Button color="info">
              <FormattedMessage id="userConstruction" />
            </Button>
          </LinkPM>
          <TagPM>
            <IconButton
              size="small"
              onClick={() => {
                setChangePass(record);
              }}
            >
              <LockResetIcon />
            </IconButton>
          </TagPM>
          <TagPM>
            <IconButton
              size="small"
              onClick={() => {
                onCreateUpdateForm(record);
              }}
            >
              <Edit />
            </IconButton>
          </TagPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </Box>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">
            <FormattedMessage id="user.list" />
          </Typography>
          <TagPM>
            <Button
              startIcon={<Add />}
              color="primary"
              onClick={() => onCreateUpdateForm({})}
            >
              <FormattedMessage id="create" />
            </Button>
          </TagPM>
        </Box>
        <TableCustom
          dataSource={data?.items}
          loading={loading}
          paginationProps={{
            count: data?.total,
            page: pagination.page,
            rowsPerPage: pagination.pageSize,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
          }}
          columns={columns}
        />
      </Paper>
    </>
  );
};
export default Table;
