import { Box } from "@mui/material";
import * as React from "react";
import DrawerCustom from "../../../../common/components/DrawerCustom";
import { some } from "../../../../common/constants";
import SchemaForm from "../../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
  nreTypeId?: string;
}
const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, nreTypeId } = props;
  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
          }) => {
            console.log("valuesField", valuesField);

            return {
              indicator: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "indicator" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.indicator.index({
                        name,
                        nreTypeId,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((item) => ({
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    unit: item.unit,
                  }));
                },
                getOptionLabel: (options) => `${options.name}(${options.unit})`,
                register: { required: true },
              },
              qcvn: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "qcvn" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.qcvn.index({
                        name,
                        nreTypeId,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((item) => ({
                    id: item.id,
                    name: item.name,
                  }));
                },
                register: { required: true },
              },
              thresholdInformation: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "qcvn.type" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadKey: valuesField?.qcvn?.id,
                disableSearchByText: true,
                loadOptions: async () => {
                  if (valuesField?.qcvn?.id) {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.threshold.index({
                          qcvnId: valuesField?.qcvn?.id,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }));
                  } else {
                    return [];
                  }
                },
                register: { required: true },
              },
              analyticalMethod: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "analytical" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.analytical.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((item) => ({
                    id: item.id,
                    name: item.name,
                  }));
                },
                register: { required: true },
              },
              value: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({
                  id: "waterQuality.indicatorsValue",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default FormDialog;
