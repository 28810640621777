import { some } from "./constants";

export const stringifyUrl = function (obj) {
  var str: string[] = [];
  for (const p in obj) {
    const value = obj[p];
    if (obj.hasOwnProperty(p) && value) {
      str.push(
        encodeURIComponent(p) +
          "=" +
          encodeURIComponent(
            typeof value === "object"
              ? JSON.stringify(value)
              : typeof value === "string" || typeof value === "number"
              ? value
              : ""
          )
      );
    }
  }
  return str.join("&");
};

export function capitalizeFirstLetter(string = "") {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function scrollTo(id: string, offsetTop: number) {
  const el = document.getElementById(id);
  if (el) {
    window.scrollTo({ top: el.offsetTop - offsetTop, behavior: "smooth" });
  }
}

export function mappedFilter(filter?: some) {
  if (!filter) {
    return {};
  }
  return Object.keys(filter).reduce((value, key) => {
    const tmp = filter[key];

    if (tmp?.id) {
      return {
        ...value,
        [key]: tmp.id,
      };
    }
    if (tmp !== null || tmp !== undefined) {
      return {
        ...value,
        [key]: tmp,
      };
    }
    return value;
  }, {});
}
