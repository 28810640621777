// Material UI
// Hooks + Common
import { Delete } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { TableCustomMain } from "../../../../common/components/TableCustom/TableCustomMain";
import { some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { ArrayRawComponentProps } from "../../../../common/SchemaForm/element/array-element/ArrayElement";
import FormControlAutoComplete from "../../../../common/SchemaForm/element/autocomplete/FormControlAutoComplete";
import { Search } from "../../../../svg";

interface Props extends ArrayRawComponentProps {}
const AddUserBox = (props: Props) => {
  const { methodsArray } = props;
  const { fields, append, remove } = methodsArray;
  const { dispatch, fetchThunk, API_PATHS, intl } = useGeneralHook();

  return (
    <>
      <FormControlAutoComplete
        value={null}
        style={{ width: 320, marginBottom: 16 }}
        filterOptionsRoot={(options = []) => {
          return options?.filter(
            (v) => fields?.findIndex((o: any) => o.userId === v.userId) === -1
          );
        }}
        startAdornment={
          <InputAdornment position="start">
            <Search className="svgStroke" />
          </InputAdornment>
        }
        label={intl.formatMessage({ id: "role.addUser" })}
        placeholder={intl.formatMessage({ id: "role.searchUser" })}
        loadOptions={async (searchString: string) => {
          const json = await dispatch(
            fetchThunk(
              API_PATHS.user.index({ page: 0, size: 1000, searchString })
            )
          );
          return json.data?.items;
        }}
        isOptionEqualToValue={(option: some, value: some) =>
          option.userId === value.userId
        }
        disableCloseOnSelect
        onChange={(e, val) => {
          append(val);
        }}
      />
      <TableCustomMain
        dataKey={"key"}
        containerProps={{ style: { maxHeight: 500 } }}
        dataSource={fields}
        columns={[
          {
            title: "role.userName",
            dataIndex: "name",
          },
          {
            title: "",
            width: 50,
            render: (_, index) => {
              return (
                <IconButton
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
      />
    </>
  );
};
export default AddUserBox;
