import * as React from "react";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}
export const schemaFormQCVN = {
  fields: ({ formProps: { intl, dispatch, fetchThunk, API_PATHS } }) => {
    return {
      code: {
        type: "text-field",
        label: intl.formatMessage({ id: "qcvn.code" }),
        placeholder: intl.formatMessage({ id: "enter" }),
        propsWrapper: { xs: 6 },
        register: {
          required: true,
        },
      },
      name: {
        type: "text-field",
        label: intl.formatMessage({ id: "qcvn.name" }),
        placeholder: intl.formatMessage({ id: "select" }),
        propsWrapper: { xs: 6 },
        register: {
          required: true,
        },
      },
      nreType: {
        type: "auto-complete",
        label: intl.formatMessage({ id: "nreType.name" }),
        placeholder: intl.formatMessage({
          id: "select",
        }),
        loadOptions: async (name: string) => {
          const json = await dispatch(
            fetchThunk(
              API_PATHS.nreType.index({
                name,
                pageSize: 100,
              })
            )
          );
          return json.data?.items?.map((v) => ({
            id: v.id,
            name: v.name,
          }));
        },
        register: {
          required: true,
        },
        propsWrapper: { xs: 6 },
      },
      attachment: {
        type: "uploadFile",
        label: intl.formatMessage({ id: "attachment" }),
        propsWrapper: { xs: 6 },
      },
      isFactor: {
        type: "checkbox",
        label: intl.formatMessage({ id: "qcvn.isFactor" }),
        propsWrapper: { xs: 6 },
        inline: true,
      },
      description: {
        type: "text-field",
        label: intl.formatMessage({ id: "description" }),
        placeholder: intl.formatMessage({ id: "enter" }),
        multiline: true,
        rows: 3,
      },
    };
  },
};

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <>
      <SchemaForm
        schema={schemaFormQCVN}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
