import { Box } from "@mui/material";
import * as React from "react";
import useSWR from "swr";
import DialogCustomFullScreen from "../../../../common/components/DialogCustomFullScreen";
import LoadingIcon from "../../../../common/components/LoadingIcon";
import { some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import SchemaForm from "../../../../common/SchemaForm";
import AddMenuBox from "./AddMenuBox";
import AddRightBox from "./AddRightBox";
import AddUserBox from "./AddUserBox";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open?: boolean;
  onClose?: (method: any) => void;
  formData?: some;
}
interface PropsContent {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  onClose?: (method: any) => void;
  formData?: some;
}

export const RoleSchemaFormContent: React.FunctionComponent<PropsContent> = (
  props
) => {
  const { onSubmit, onClose, formData } = props;

  const xs = formData?.roleId ? 12 : 6;
  return (
    <Box paddingBottom={5}>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
          }) => {
            return {
              roleName: {
                type: "text-field",
                label: intl.formatMessage({ id: "role.roleName" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 12 },
              },
              roleId: {
                type: "text-field",
                label: intl.formatMessage({ id: "role.roleId" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: xs },
              },
              parentId: {
                type: "select",
                label: intl.formatMessage({ id: "role.parentId" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async () => {
                  const json = await dispatch(
                    fetchThunk(API_PATHS.role.getAllRole())
                  );
                  return json.data?.map((v) => ({
                    id: v.roleId,
                    name: v.roleId,
                  }));
                },
                register: {
                  required: true,
                },
                propsWrapper: { xs: xs },
              },

              rightInRole: {
                type: "array",
                disableInitialData: true,
                keyName: "key",
                render: (params) => <AddRightBox {...params} />,
                propsWrapper: { xs: xs },
              },
              userInRole: {
                type: "array",
                disableInitialData: true,
                keyName: "key",
                render: (params) => <AddUserBox {...params} />,
                propsWrapper: { xs: xs },
              },
              menuInRole: {
                type: (params) => <AddMenuBox {...params} />,
                propsWrapper: { xs: 12 },
              },
            };
          },
          ui: ({ formProps: { intl } }) => {
            if (formData?.roleId) {
              return [
                {
                  id: "top",
                  propsWrapper: { xs: 4 },
                  fields: ["roleName", "roleId", "parentId"],
                },
                {
                  id: "top",
                  propsWrapper: { xs: 4 },
                  fields: ["rightInRole"],
                },

                {
                  id: "bottom",
                  propsWrapper: { xs: 4 },
                  fields: ["menuInRole"],
                },
              ];
            }
            return [
              {
                id: "top",
                propsWrapper: { xs: 8 },
                fields: [
                  "roleName",
                  "roleId",
                  "parentId",
                  "rightInRole",
                  "userInRole",
                ],
              },

              {
                id: "bottom",
                propsWrapper: { xs: 4 },
                fields: ["menuInRole"],
              },
            ];
          },
          changeDataBeforeSubmit(values) {
            return {
              ...values,
              rightInRole: values.rightInRole?.map((v) => v.rightId),
              userInRole: values.userInRole?.map((v) => v.userId),
              menuInRole: values.menuInRole?.map((v) => v.id),
            };
          },
        }}
        onSubmit={onSubmit}
        hiddenField={{ appId: process.env.REACT_APP_APP_ID }}
        onCancel={onClose}
        formData={formData}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormRoleDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, onSubmit, formData } = props;

  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data, isValidating } = useSWR(
    formData?.roleId
      ? API_PATHS.role.getDetail({ roleId: formData?.roleId })
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.roleId ? "update" : "create"}
      maxWidth="xl"
    >
      {isValidating ? (
        <LoadingIcon />
      ) : (
        <RoleSchemaFormContent
          formData={data && { ...data, ...data?.role, role: undefined }}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
    </DialogCustomFullScreen>
  );
};
export default FormRoleDialog;
