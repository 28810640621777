import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { DATE_FORMAT, some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";

interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { hookPagination, data, loading, onDelete } = props;

  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns = [
    {
      title: "license.code",
      dataIndex: "code",
    },
    {
      title: "construction",
      dataIndex: "construction.name",
    },
    {
      title: "licenseType",
      dataIndex: "licenseType.name",
    },
    {
      title: "license.effectiveStartDate",
      minWidth: 160,
      render: (record) => {
        return (
          record.effectiveStartDate &&
          moment(record.effectiveStartDate).format(DATE_FORMAT)
        );
      },
    },
    {
      title: "license.expirationDate",
      minWidth: 160,
      render: (record) => {
        return (
          record.expirationDate &&
          moment(record.expirationDate).format(DATE_FORMAT)
        );
      },
    },
    {
      title: "status",
      render: (record) => {
        const aboutToExpire = record.expirationDate
          ? moment(record.expirationDate).diff(moment(), "day")
          : 0;
        const expired = aboutToExpire <= 0;

        return (
          <Typography
            variant="inherit"
            color={
              expired
                ? "error"
                : aboutToExpire < 30
                ? "warning.main"
                : "success.main"
            }
          >
            <FormattedMessage
              id={
                expired
                  ? "expired"
                  : aboutToExpire < 30
                  ? "aboutToExpire"
                  : "unexpired"
              }
            />
          </Typography>
        );
      },
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <LinkPM to={record.id}>
            <IconButton style={{ marginRight: 8 }} size="small">
              <Edit />
            </IconButton>
          </LinkPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <TableCustom
        dataSource={data?.items}
        loading={loading}
        paginationProps={{
          count: data?.total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        columns={columns}
      />
    </>
  );
};
export default Table;
