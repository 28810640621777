import * as React from "react";
import useSWR from "swr";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  formData?: some;
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open?: boolean;
  onClose?: () => void;
  constructionData?: some;
}
interface PropsContent {
  formData?: some;
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  onClose?: () => void;
  constructionData?: some;
}

export const LicenseSchemaFormContent: React.FunctionComponent<PropsContent> = (
  props
) => {
  const { onSubmit, formData, onClose, constructionData } = props;
  const { dispatch, API_PATHS, fetchThunk } = useGeneralHook();

  const { data: licenseTypeOptions = [] } = useSWR(
    API_PATHS.licenseType.index({
      pageSize: 100000,
    }),
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data?.items;
    }
  );

  return (
    <>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
          }) => {
            return {
              code: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.code" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              construction: {
                type: "auto-complete",
                hidden: !!constructionData,
                label: intl.formatMessage({ id: "construction" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.construction.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              licenseType: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "licenseType" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                options: licenseTypeOptions,
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              decisionDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "license.decisionDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },

              effectiveStartDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "license.effectiveStartDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                minDate: valuesField.decisionDate,
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              timeRange: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "license.timeRange",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                inputType: "number",
                register: {
                  required: true,
                  min: {
                    value: 0,
                    message: intl.formatMessage({
                      id: "license.timeRangeValid",
                    }),
                  },
                },
                propsWrapper: { xs: 6 },
              },
              licensingType: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "licensingType" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                disableSearchByText: true,
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.licensingType.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data;
                },
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              licensingAuthority: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "licensingAuthority" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                disableSearchByText: true,
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.licensingAuthority.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              oldLicenseDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "license.oldLicenseDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                minDate: valuesField.decisionDate,
                propsWrapper: { xs: 6 },
              },
              oldLicense: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "license.oldLicense" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadKey: valuesField.construction,
                loadOptions: async (code: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.license.index({
                        code,
                        constructionId: valuesField.construction?.id,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    code: v.code,
                  }));
                },
                getOptionLabel: (options) => options.code,
                propsWrapper: { xs: 6 },
              },
              belongRiver: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.belongRiver" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              waterSource: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.waterSource" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              exploitMethod: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.exploitMethod" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              exploitPurpose: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.exploitPurpose" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              exploitRegimen: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.exploitRegimen" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              maxFlowExploit: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.maxFlowExploit" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              waterFlowExploit: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.waterFlowExploit" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              waterFloor: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.waterFloor" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              totalWell: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.totalWell" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              preGrantPermission: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.preGrantPermission" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              preGrantPermissionDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "license.preGrantPermissionDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              preGrantPermissionTotal: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "license.preGrantPermissionTotal",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              description: {
                type: "text-field",
                label: intl.formatMessage({ id: "description" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },

              attachment: {
                type: "uploadFile",
                label: intl.formatMessage({ id: "license.file" }),
                propsWrapper: { xs: 6 },
              },
              applicableStatus: {
                type: "checkbox",
                label: intl.formatMessage({ id: "license.applicableStatus" }),
                propsWrapper: { xs: 6 },
              },
            };
          },
          ui: ({ valuesField, formProps: { intl } }) => {
            return [
              {
                id: "common",
                title: intl.formatMessage({ id: "license.info" }),
                fields: [
                  "code",
                  "construction",
                  "licenseType",
                  "decisionDate",
                  "effectiveStartDate",
                  "timeRange",
                  "licensingType",
                  "licensingAuthority",
                  // "oldLicenseDate",
                  "oldLicense",
                  "attachment",
                ],
              },
              {
                id: "type",
                title: licenseTypeOptions.find(
                  (v) => v.id === valuesField.licenseType?.id
                )?.name,
                fields:
                  valuesField.licenseType?.code === "NM"
                    ? [
                        "belongRiver",
                        "waterSource",
                        "exploitRegimen",
                        "exploitMethod",
                        "preGrantPermission",
                        "preGrantPermissionDate",
                        "preGrantPermissionTotal",
                      ]
                    : valuesField.licenseType?.code === "NDD"
                    ? [
                        "wellList",
                        "totalWell",
                        "waterFloor",
                        "maxFlowExploit",
                        "preGrantPermission",
                        "preGrantPermissionDate",
                        "preGrantPermissionTotal",
                      ]
                    : valuesField.licenseType?.code === "NB"
                    ? [
                        "waterSource",
                        "waterFlowExploit",
                        "exploitPurpose",
                        "exploitRegimen",
                        "exploitMethod",
                      ]
                    : valuesField.licenseType?.code === "XT"
                    ? [
                        "belongRiver",
                        "wasteWaterList",
                        "waterSource",
                        "waterFlowExploit",
                        "exploitMethod",
                      ]
                    : [],
              },
            ];
          },
        }}
        hiddenField={constructionData}
        onSubmit={onSubmit}
        formData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <LicenseSchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
