export type some = { [key: string]: any };

export const TOKEN = "token-key";
export const MODE_THEME = "mode_theme";
export const PAGE_SIZE = "page_size";
export const SUCCESS_CODE = 0;
export const KEY_GOOGLE_MAP = "AIzaSyCK_8jKvtRQ4-j9RhlNhJb0NeUV4aiEmaY";
export const DATE_TIME_FORMAT = "HH:mm DD/MM/YYYY";
export const DATE_TIME_FULL_FORMAT = "HH:mm:ss DD/MM/YYYY";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm";
export const BE_DATE_FORMAT = "YYYY-MM-DD";
export const BE_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const GIENG_OPTIONS = [
  { id: "gieng01", name: "Giếng 01" },
  { id: "gieng02", name: "Giếng 02" },
  { id: "gieng03", name: "Giếng 03" },
  { id: "gieng04", name: "Giếng 04" },
  { id: "gieng05", name: "Giếng 05" },
  { id: "gieng06", name: "Giếng 06" },
  { id: "gieng07", name: "Giếng 07" },
  { id: "gieng08", name: "Giếng 08" },
];
