// Material UI
import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, Checkbox, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import TableCustom from "../../../common/components/TableCustom";
import TagPM from "../../../common/components/TagPM";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
// Redux
import { fetchThunk } from "../../../common/reducer/thunk";
import FormIndicatorDialog from "./FormIndicatorDialog";
interface Props {
  constructionTypeId?: string;
}
const IndicatorDetailBox = (props: Props) => {
  const { constructionTypeId } = props;
  const { dispatch, enqueueSnackbar, confirmDialog, intl } = useGeneralHook();
  const [formData, setFormData] = useState<some | undefined>();

  const { data, isValidating, revalidate } = useSWR(
    constructionTypeId
      ? API_PATHS.constructionTypeIndicator.index(constructionTypeId)
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      if (!constructionTypeId) {
        return;
      }
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(
            API_PATHS.constructionTypeIndicator.delete(value.id),
            "delete"
          )
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [
      confirmDialog,
      constructionTypeId,
      dispatch,
      intl,
      enqueueSnackbar,
      revalidate,
    ]
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading?: (loading: boolean) => void) => {
      if (!constructionTypeId) {
        return;
      }
      setLoading && setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.constructionTypeIndicator.update(
                constructionTypeId,
                value.id
              ),
              "put",
              value
            )
          )
        : await dispatch(
            fetchThunk(
              API_PATHS.constructionTypeIndicator.index(constructionTypeId),
              "post",
              value
            )
          );
      setLoading && setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [constructionTypeId, dispatch, enqueueSnackbar, revalidate]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">
          <FormattedMessage id="constructionIndicator.list" />
        </Typography>
        <TagPM>
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={() => setFormData({})}
          >
            <FormattedMessage id="create" />
          </Button>
        </TagPM>
      </Box>
      <TableCustom
        dataSource={data}
        loading={isValidating}
        columns={[
          {
            title: "constructionIndicator.name",
            dataIndex: "indicator.name",
            width: "20%",
          },
          {
            title: "constructionIndicator.code",
            dataIndex: "indicator.code",
            width: "20%",
          },
          {
            title: "constructionIndicator.unit",
            dataIndex: "indicator.unit",
          },
          {
            title: "constructionIndicator.isAuto",
            align: "center",
            render: (record) => {
              return (
                <Checkbox
                  color="secondary"
                  checked={record.isAuto}
                  onChange={(e) => {
                    onCreateUpdateForm({
                      ...record,
                      isAuto: e.target.checked,
                    });
                  }}
                />
              );
            },
          },
          {
            title: "constructionIndicator.isPeriodic",
            align: "center",
            render: (record) => {
              return (
                <Checkbox
                  checked={record.isPeriodic}
                  color="secondary"
                  onChange={(e) => {
                    onCreateUpdateForm({
                      ...record,
                      isPeriodic: e.target.checked,
                    });
                  }}
                />
              );
            },
          },
          {
            title: "action",
            align: "center",
            width: 120,
            render: (record, index) => {
              return (
                <>
                  <TagPM>
                    <IconButton
                      style={{ marginRight: 8 }}
                      size="small"
                      onClick={() => {
                        setFormData(record);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </TagPM>
                  <IconButton
                    onClick={() => {
                      onDelete(record);
                    }}
                    size="small"
                  >
                    <Delete />
                  </IconButton>
                </>
              );
            },
          },
        ]}
      />
      <FormIndicatorDialog
        open={!!formData}
        formData={formData}
        onClose={() => setFormData(undefined)}
        onSubmit={onCreateUpdateForm}
      />
    </>
  );
};

export default IndicatorDetailBox;
