import * as React from "react";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import SchemaForm from "../../../common/SchemaForm";
import { schemaFormQCVN } from "./FormDialog";

interface Props {
  detailData: some;
  revalidate: () => void;
}

const InfoTab: React.FunctionComponent<Props> = (props) => {
  const { detailData, revalidate } = props;
  const { dispatch, enqueueSnackbar, fetchThunk, API_PATHS } = useGeneralHook();

  const onSubmit = React.useCallback(
    async (value: some) => {
      const json = value.id
        ? await dispatch(
            fetchThunk(API_PATHS.qcvn.update(value.id), "put", value)
          )
        : await dispatch(fetchThunk(API_PATHS.qcvn.create, "post", value));
      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [API_PATHS.qcvn, dispatch, enqueueSnackbar, fetchThunk, revalidate]
  );

  return (
    <SchemaForm
      showConfirmBeforeLeave
      formData={detailData}
      schema={schemaFormQCVN}
      onSubmit={onSubmit}
      onCancel={(methods) => methods.reset()}
      formProps={{
        autoComplete: "nope",
        autoCorrect: "off",
      }}
    />
  );
};

export default InfoTab;
