import { Box, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import DrawerCustom from "../../../../common/components/DrawerCustom";
import { some } from "../../../../common/constants";
import SchemaForm from "../../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
          }) => {
            return {
              sensor: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "sensor" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.sensor.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items;
                },
                renderOption: (_, option) => (
                  <Tooltip
                    arrow
                    placement="left-start"
                    title={
                      <Box color="white">
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.name" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.name}
                          </Typography>
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.producingCountry" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.producingCountry}
                          </Typography>
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.manufacturer" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.manufacturer}
                          </Typography>
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.lowerThreshold" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.lowerThreshold}
                          </Typography>
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.upperThreshold" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.upperThreshold}
                          </Typography>
                        </Typography>
                      </Box>
                    }
                  >
                    <li {..._}>
                      <Typography variant="subtitle2">{option.name}</Typography>
                    </li>
                  </Tooltip>
                ),
                noHelperText: true,
              },
              accreditationStartingDay: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "stationSensor.accreditationStartDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                maxDate: valuesField.accreditationEndingDay,
              },
              accreditationEndingDay: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "stationSensor.accreditationEndDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                minDate: valuesField.accreditationStartingDay,
              },
              installationDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "stationSensor.installationDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                maxDate: valuesField.operationDate,
              },
              operationDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "stationSensor.operationDate",
                }),
                minDate: valuesField.installationDate,
                placeholder: intl.formatMessage({ id: "enter" }),
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default FormDialog;
