import * as React from "react";
// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
// Hooks + Common
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import SchemaForm from "../../../common/SchemaForm";

interface Props extends HookPaginationProps {}
const Filter = (props: Props) => {
  const { filter, setParams, clearParams } = props;

  return (
    <SchemaForm
      formProps={{ style: { marginBottom: 16 } }}
      formData={filter}
      onSubmit={setParams}
      hideSubmitButton
      schema={{
        fields: ({ formProps: { intl, dispatch, fetchThunk, API_PATHS } }) => ({
          name: {
            type: "text-field",
            label: intl.formatMessage({ id: "nreType" }),
            placeholder: intl.formatMessage({
              id: "enter",
            }),
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          nreField: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "nreField.name" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.nreField.index({
                    name,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
          },
          search: {
            type: "submitButton",
            fullWidth: true,
            label: intl.formatMessage({ id: "search" }),
            propsWrapper: { xs: "auto", marginTop: 2.5 },
          },

          reset: {
            type: "button",
            variant: "text",
            fullWidth: true,
            label: <RefreshIcon />,
            style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
            propsWrapper: { xs: "auto", marginTop: 3 },
            onClick: () => {
              clearParams();
            },
          },
        }),
      }}
    />
  );
};
export default Filter;
