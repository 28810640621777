import { Box } from "@mui/material";
import * as React from "react";
import DrawerCustom from "../../../common/components/DrawerCustom";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
          }) => {
            return {
              indicator: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "indicator" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.indicator.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items;
                },
                getOptionLabel: (options) => `${options.name}(${options.unit})`,
                register: { required: true },
              },
              lowerThreshold: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "threshold.lowerThreshold" }),
                placeholder: intl.formatMessage({
                  id: "enter",
                }),
                register: {
                  required: !!valuesField.lowerThresholdOperator,
                  max: {
                    value: valuesField.upperThreshold,
                    message: intl.formatMessage({ id: "threshold.max" }),
                  },
                },
              },

              lowerThresholdOperator: {
                type: "select",
                options: [
                  { id: ">", name: ">" },
                  { id: ">=", name: ">=" },
                ],
                label: intl.formatMessage({
                  id: "threshold.lowerThresholdOperator",
                }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                register: { required: !!valuesField.lowerThreshold },
              },
              upperThreshold: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "threshold.upperThreshold" }),
                placeholder: intl.formatMessage({
                  id: "enter",
                }),

                register: { required: !!valuesField.upperThresholdOperator },
              },
              upperThresholdOperator: {
                type: "select",
                options: [
                  { id: "<", name: "<" },
                  { id: "<=", name: "<=" },
                ],
                label: intl.formatMessage({
                  id: "threshold.upperThresholdOperator",
                }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                register: {
                  required: !!valuesField.upperThreshold,
                  min: {
                    value: valuesField.upperThreshold,
                    message: intl.formatMessage({ id: "threshold.min" }),
                  },
                },
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const ThresholdIndicatorFormDialog: React.FunctionComponent<Props> = (
  props
) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default ThresholdIndicatorFormDialog;
