import * as React from "react";
import DialogCustomFullScreen from "../../../../common/components/DialogCustomFullScreen";
import { some } from "../../../../common/constants";
import SchemaForm from "../../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
  message: string;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, message } = props;
  return (
    <>
      <SchemaForm
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "off",
          autoCorrect: "off",
        }}
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
          }) => {
            return {
              camera: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "camera" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.camera.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                    manufacturer: v.manufacturer,
                    model: v.model,
                    producingCountry: v.producingCountry,
                  }));
                },
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
                noHelperText: true,
              },
              accessAddress: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "cameraStation.accessAddress",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              linkRtsp: {
                type: "text-field",
                label: (
                  <div style={{ display: "flex" }}>
                    {intl.formatMessage({
                      id: "cameraStation.linkRtsp",
                    })}
                    &nbsp;
                    {message && (
                      <span style={{ color: "red" }}>({message})</span>
                    )}
                  </div>
                ),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              installationLocation: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "cameraStation.installationLocation",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              installationDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "cameraStation.installationDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              operationDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "cameraStation.operationDate",
                }),
                minDate: valuesField.installationDate,
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              recordingTime: {
                type: "dateTimePicker",
                label: intl.formatMessage({
                  id: "cameraStation.recordingTime",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              space: {
                unregister: true,
                propsWrapper: { xs: 6 },
              },
              recordWhenCrossingTheThreshold: {
                type: "switch",
                label: intl.formatMessage({
                  id: "cameraStation.recordWhenCrossingTheThreshold",
                }),
                defaultValue: true,
                propsWrapper: { xs: 6 },
              },
              activatingPause: {
                type: "switch",
                label: intl.formatMessage({
                  id: "cameraStation.activatingPause",
                }),
                defaultValue: true,
                propsWrapper: { xs: 6 },
              },
            };
          },
        }}
      />
    </>
  );
};

const CameraStationDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default CameraStationDialog;
