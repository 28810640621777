import { Container, Step, StepButton, Stepper } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import FormInfoBox from "./FormInfoBox";
import IndicatorCreateBox from "./IndicatorCreateBox";
import MetaDataBox from "./MetaDataBox";

const STEPS = [
  "constructionType.info",
  "constructionType.extendInfo",
  "constructionType.addIndicator",
];
interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const CreateContent: React.FunctionComponent<{
  onSubmitSuccess?: () => void;
  formData?: some;
  setFormData: (value: any) => void;
}> = (props) => {
  const { onSubmitSuccess, formData, setFormData } = props;
  const { dispatch, enqueueSnackbar, fetchThunk, API_PATHS } = useGeneralHook();

  const onSubmit = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = await dispatch(
        value.id
          ? fetchThunk(
              API_PATHS.constructionType.update(value.id),
              "put",
              value
            )
          : fetchThunk(API_PATHS.constructionType.create, "post", value)
      );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        setFormData(json.data);
        onSubmitSuccess && onSubmitSuccess();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      API_PATHS.constructionType,
      dispatch,
      fetchThunk,
      onSubmitSuccess,
      enqueueSnackbar,
      setFormData,
    ]
  );

  return (
    <Container maxWidth="sm" sx={{ marginTop: 2 }}>
      <FormInfoBox
        formData={formData}
        onSubmit={onSubmit}
        submitLabel={"continue"}
      />
    </Container>
  );
};

const ExtendInfoContent: React.FunctionComponent<{
  onSubmitSuccess: () => void;
  formData?: some;
}> = (props) => {
  const { onSubmitSuccess, formData } = props;

  return (
    <Container maxWidth="md" sx={{ marginTop: 2 }}>
      <MetaDataBox
        submitLabel="continue"
        onSubmitSuccess={onSubmitSuccess}
        constructionTypeId={formData?.id}
      />
    </Container>
  );
};
const IndicatorBoxContent: React.FunctionComponent<{
  onSubmitSuccess: () => void;
  formData?: some;
}> = (props) => {
  const { onSubmitSuccess, formData } = props;

  return (
    <Container maxWidth="md" sx={{ marginTop: 2 }}>
      <IndicatorCreateBox
        submitLabel="finish"
        onSubmitSuccess={onSubmitSuccess}
        constructionTypeId={formData?.id}
      />
    </Container>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose } = props;
  const [step, setStep] = React.useState(0);
  const [formData, setFormData] = React.useState<some | undefined>(undefined);

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      maxWidth="xl"
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
      TransitionProps={{
        onEnter: () => {
          setStep(0);
          setFormData(undefined);
        },
      }}
    >
      <Stepper activeStep={step} alternativeLabel>
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepButton
              color="inherit"
              disabled={formData ? false : [1, 2].includes(index)}
              onClick={() => {
                setStep(index);
              }}
            >
              <FormattedMessage id={label} />
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {step === 0 ? (
        <CreateContent
          onSubmitSuccess={() => setStep(1)}
          formData={formData}
          setFormData={setFormData}
        />
      ) : step === 1 ? (
        <ExtendInfoContent
          onSubmitSuccess={() => setStep(2)}
          formData={formData}
        />
      ) : (
        <IndicatorBoxContent
          onSubmitSuccess={() => onClose()}
          formData={formData}
        />
      )}
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
