// Material UI
import {
  Box,
  Button,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
interface Params {
  timeRange: number;
  timeRangeType: string;
}
const OptionsFilter = [
  { timeRange: 1, timeRangeType: "month" },
  { timeRange: 3, timeRangeType: "month" },
  { timeRange: 1, timeRangeType: "year" },
];

interface Props {}
const BarChartBox = (props: Props) => {
  const { dispatch, API_PATHS, appState, intl } = useGeneralHook();
  const [type, setType] = useState("dataReceive");
  const [filterParams, setFilterParams] = useState<Params>(OptionsFilter[0]);

  const { data = [] } = useSWR(
    type === "dataReceive"
      ? API_PATHS.dashboard.dataReceive(filterParams)
      : type === "dataExceed"
      ? API_PATHS.dashboard.dataExceed(filterParams)
      : type === "stationExceed"
      ? API_PATHS.dashboard.dataStationExceed(filterParams)
      : API_PATHS.dashboard.dataConstructionExceed(filterParams),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const series = useMemo(() => {
    return [
      {
        name: intl.formatMessage({ id: `dashboard.${type}` }),
        data: data?.map((item) => {
          return { y: item.ratio, x: item.times };
        }),
      },
    ];
  }, [data, intl, type]);

  return (
    <Paper
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box display="flex" padding={2}>
        <Box flex={1}>
          <Select
            id="demo-simple-select"
            value={type}
            label="Age"
            onChange={(e) => {
              setType(e.target.value);
            }}
            input={<InputBase />}
            autoWidth
            style={{ paddingRight: 32 }}
            renderValue={(value) => {
              return (
                <Typography variant="subtitle1">
                  <FormattedMessage id={`dashboard.${value}`} />
                </Typography>
              );
            }}
          >
            {[
              "dataReceive",
              // "dataExceed",
              // "stationExceed",
              "constructionExceed",
            ].map((value) => (
              <MenuItem key={value} value={value}>
                <Typography variant="body2">
                  <FormattedMessage id={`dashboard.${value}`} />
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" gap={1}>
          {OptionsFilter.map((value, index) => {
            return (
              <Button
                key={index}
                variant={
                  value.timeRange === filterParams.timeRange &&
                  value.timeRangeType === filterParams.timeRangeType
                    ? "contained"
                    : "outlined"
                }
                size="small"
                onClick={(e) => {
                  setFilterParams(value);
                }}
              >
                {value.timeRange}&nbsp;
                <FormattedMessage id={value.timeRangeType} />
              </Button>
            );
          })}
        </Box>
      </Box>
      <Box flex={1}>
        <Chart
          options={{
            theme: {
              mode: appState.common.mode === "light" ? "light" : "dark",
            },
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 4,
                columnWidth: "45%",
              },
            },
            tooltip: {
              x: { show: true, format: "MM yyyy", formatter: undefined },
            },
            chart: {
              type: "bar",
              events: {
                dataPointSelection: (event, chartContext, config) => {
                  console.log(
                    config.w.config.labels[config.dataPointIndex],
                    config
                  );
                },
              },
            },
            colors: ["#9C27B0"],
            xaxis: {
              categories: data?.map((v) => `${v.name}(${v.times})`),
            },
          }}
          series={series}
          type="bar"
          height={"95%"}
        />
      </Box>
    </Paper>
  );
};

export default BarChartBox;
