// Material UI
import Highcharts from "highcharts/highcharts";
import moment from "moment";
import { useMemo } from "react";
import useSWR from "swr";
import ChartJs from "../../../common/components/ChartJs";
import LoadingIcon from "../../../common/components/LoadingIcon";
import { some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

interface Props {
  filter: some;
  params: some;
}
const ChartBox = (props: Props) => {
  const { filter, params } = props;
  const { date, constructionId } = params;
  const { dispatch, fetchThunk, API_PATHS, theme } = useGeneralHook();
  const { data, isValidating } = useSWR(
    constructionId?.id && date
      ? API_PATHS.searchData.dayChart({
          ...date,
          constructionId: constructionId?.id,
        })
      : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const series = useMemo(() => {
    return filter?.columns?.reduce((value, column) => {
      if (column.indicators) {
        return [
          ...value,
          ...column.indicators.map((one) => {
            return {
              name: `${column.name}-${one.name}`,
              data: data
                ?.map((v) => [
                  moment(v.time).toDate().getTime(),
                  v[`${one.dataColumnCode}_avg`] || 0,
                ])
                .reverse(),
              lineWidth: 2,
            };
          }),
        ];
      }
      return [
        ...value,
        {
          name: column.name,
          data: data
            ?.map((v) => [
              moment(v.time).toDate().getTime(),
              v[`${column.dataColumnCode}_avg`] || 0,
            ])
            .reverse(),
          lineWidth: 2,
        },
      ];
    }, []);
  }, [data, filter.columns]);

  if (isValidating) {
    return <LoadingIcon />;
  }

  return (
    <ChartJs
      constructorType="chart"
      options={{
        title: {
          text: "",
        },
        yAxis: {
          title: { enabled: false },
        },
        xAxis: {
          type: "datetime",
        },
        series: series,
        chart: {
          zoomType: "x",
          height: 500,
          backgroundColor: theme.palette.background.paper,
        },

        credits: {
          enabled: false,
        },
        tooltip: {
          valueDecimals: 2,
        },
      }}
      highcharts={Highcharts}
    />
  );
};

export default ChartBox;
