import { Add } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TagPM from "../../../common/components/TagPM";
import { some } from "../../../common/constants";
import MenuTree from "./MenuTree";

interface Props {
  data?: some[];
  loading: boolean;
  onCreateUpdateForm: (value: some) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { data, onCreateUpdateForm, onDelete } = props;

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">
            <FormattedMessage id="menu.list" />
          </Typography>
          <TagPM>
            <Button
              startIcon={<Add />}
              color="primary"
              onClick={() => onCreateUpdateForm({})}
            >
              <FormattedMessage id="create" />
            </Button>
          </TagPM>
        </Box>
        <MenuTree
          data={data || []}
          onDelete={onDelete}
          onCreateUpdateForm={onCreateUpdateForm}
        />
      </Paper>
    </>
  );
};
export default Table;
