import { Box } from "@mui/material";
import * as React from "react";
import DrawerCustom from "../../../common/components/DrawerCustom";
import { some } from "../../../common/constants";
import FormInfoBox from "./FormInfoBox";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <Box padding={2}>
      <FormInfoBox formData={formData} onSubmit={onSubmit} onCancel={onClose} />
    </Box>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default FormDialog;
