// Material UI
// Hooks + Common
import { Delete } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useParams } from "react-router";
import useSWR from "swr";
import TableCustomMain from "../../../common/components/TableCustom/TableCustomMain";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import FormControlAutoComplete from "../../../common/SchemaForm/element/autocomplete/FormControlAutoComplete";
import { Search } from "../../../svg";
import usePaginationHook from "../../../common/hook/usePaginationHook";
import { useCallback } from "react";

interface Props {}
const UserListTab = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const { pagination, onPageChange, onRowsPerPageChange } = usePaginationHook({
    disableLink: true,
  });
  const { dispatch, fetchThunk, API_PATHS, intl, enqueueSnackbar } =
    useGeneralHook();

  const { data, revalidate } = useSWR(
    id
      ? API_PATHS.role.userInRole({
          role: id,
          page: pagination.page,
          size: pagination.pageSize,
        })
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );
  console.log("data", data);

  const onCreate = useCallback(
    async (value) => {
      const json = await dispatch(
        fetchThunk(API_PATHS.role.addUser, "post", {
          roleId: id,
          userInRole: [value.userId],
        })
      );
      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      API_PATHS.role.addUser,
      dispatch,
      enqueueSnackbar,
      fetchThunk,
      id,
      revalidate,
    ]
  );

  const onDelete = useCallback(
    async (value) => {
      const json = await dispatch(
        fetchThunk(API_PATHS.role.removeUser, "post", {
          roleId: id,
          userInRole: [value.userId],
        })
      );
      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      API_PATHS.role.removeUser,
      dispatch,
      enqueueSnackbar,
      fetchThunk,
      id,
      revalidate,
    ]
  );

  return (
    <>
      <FormControlAutoComplete
        value={null}
        style={{ width: 320, marginBottom: 16 }}
        filterOptionsRoot={(options = []) => {
          return options?.filter(
            (v) =>
              data?.items?.findIndex((o: any) => o.userId === v.userId) === -1
          );
        }}
        startAdornment={
          <InputAdornment position="start">
            <Search className="svgStroke" />
          </InputAdornment>
        }
        label={intl.formatMessage({ id: "role.addUser" })}
        placeholder={intl.formatMessage({ id: "role.searchUser" })}
        loadOptions={async (searchString: string) => {
          const json = await dispatch(
            fetchThunk(
              API_PATHS.user.index({ page: 0, size: 1000, searchString })
            )
          );
          return json.data?.items;
        }}
        isOptionEqualToValue={(option: some, value: some) =>
          option.userId === value.userId
        }
        disableCloseOnSelect
        onChange={(e, val) => {
          onCreate(val);
        }}
      />
      <TableCustomMain
        dataKey={"key"}
        containerProps={{ style: { maxHeight: 500 } }}
        dataSource={data?.items}
        columns={[
          {
            title: "role.userName",
            dataIndex: "name",
          },
          {
            title: "email",
            dataIndex: "email",
          },
          {
            title: "",
            width: 50,
            render: (record) => {
              return (
                <IconButton
                  onClick={() => {
                    onDelete(record);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        paginationProps={{
          count: data?.total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
    </>
  );
};
export default UserListTab;
