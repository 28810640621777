import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { AppState } from "../../redux/reducer";

function RedirectRoute({ children }: { children: JSX.Element }) {
  const { authentication } = useSelector((state: AppState) => state.auth);
  let location = useLocation();
  if (authentication) {
    let from = "/";
    if (location) {
      const params = new URLSearchParams(location.search);
      const fromParamValue = params.get("from");
      if (fromParamValue) {
        from = fromParamValue;
      }
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={from} state={{ from: location }} replace />;
  }

  return children;
}

export default RedirectRoute;
