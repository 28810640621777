import { Add, Delete, Edit, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { OpenState } from "../../../common/components/elements";
import { some } from "../../../common/constants";

interface Props {
  data: some[];
  onCreateUpdateForm: (value: some) => void;
  onDelete: (value: some) => void;
  index?: number;
}
const MenuTree = (props: Props) => {
  const { data, onCreateUpdateForm, onDelete, index = 0 } = props;

  return (
    <Box>
      {data.map((item) => {
        return (
          <OpenState defaultOpen={true} key={item.id}>
            {({ open, setOpen }) => {
              return (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: 34,
                      "&:hover > .control": {
                        display: "flex",
                      },
                    }}
                    style={{
                      marginLeft: index * 34,
                    }}
                  >
                    {item?.children.length > 0 ? (
                      <IconButton
                        style={{
                          transition: "all 0.3s",
                          transform: `rotate(${open ? 90 : 0}deg)`,
                        }}
                        size="small"
                        onClick={() => {
                          setOpen((old) => !old);
                        }}
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    ) : (
                      <Typography style={{ width: 32, textAlign: "center" }}>
                        •
                      </Typography>
                    )}
                    <Typography
                      variant={
                        item?.children.length > 0 ? "subtitle2" : "body2"
                      }
                    >
                      {item.name}&nbsp;({item.url})
                    </Typography>
                    <Box
                      className="control"
                      sx={{
                        display: "none",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          onDelete(item);
                        }}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          onCreateUpdateForm(item);
                        }}
                        color="primary"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          onCreateUpdateForm({ parentId: item.id });
                        }}
                        color="success"
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </Box>
                  {item?.children.length > 0 && (
                    <Collapse in={open}>
                      <MenuTree
                        data={item?.children}
                        onCreateUpdateForm={onCreateUpdateForm}
                        onDelete={onDelete}
                        index={index + 1}
                      />
                    </Collapse>
                  )}
                </Box>
              );
            }}
          </OpenState>
        );
      })}
    </Box>
  );
};
export default MenuTree;
