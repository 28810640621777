import { InputAdornment } from "@mui/material";
import * as React from "react";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { GIENG_OPTIONS, some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
  params: some;
  indicators: some[];
  constructionTypeId: string;
  nreTypeId: string;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    formData,
    params,
    onClose,
    indicators,
    constructionTypeId,
    nreTypeId,
  } = props;

  const isNDD = indicators.every((v) => !!v.indicators);

  if (isNDD) {
    return (
      <>
        <SchemaForm
          schema={{
            fields: ({
              formProps: { intl, dispatch, fetchThunk, API_PATHS },
              valuesField,
              methods: { setValue },
            }) => {
              return {
                construction: {
                  hidden: formData?.id,
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "construction.name" }),
                  placeholder: intl.formatMessage({
                    id: "search",
                  }),

                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.construction.index({
                          page: 0,
                          pageSize: 100,
                          name,
                          nreTypeId,
                          constructionTypeId: constructionTypeId,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  loadKey: [nreTypeId, constructionTypeId].join(","),
                  onChange: () => {
                    setValue("monitoringLocation", null);
                  },
                  propsWrapper: { xs: 6 },
                },
                time: {
                  type: "dateTimePicker",
                  label: intl.formatMessage({ id: "periodicMonitoring.time" }),
                  propsWrapper: { xs: 6 },
                  register: {
                    required: true,
                  },
                },
                observer: {
                  type: "text-field",
                  label: intl.formatMessage({
                    id: "periodicMonitoring.observer",
                  }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  propsWrapper: { xs: 6 },
                  register: {
                    required: true,
                  },
                },
                attachment: {
                  type: "uploadFile",
                  label: intl.formatMessage({ id: "attachment" }),
                  placeholder: intl.formatMessage({ id: "select" }),
                  propsWrapper: { xs: 12 },
                },
                gieng: {
                  type: "auto-complete",
                  options: GIENG_OPTIONS,
                  multiple: true,
                  limitTags: 3,
                  label: intl.formatMessage({ id: "periodicMonitoring.gieng" }),
                  placeholder: intl.formatMessage({ id: "select" }),
                  propsWrapper: { xs: 6 },
                },
                ...valuesField?.gieng?.reduce((value, current) => {
                  return {
                    ...value,
                    [`${current?.id}_luuluong_v`]: {
                      type: "text-field",
                      inputType: "number",
                      label: "Lưu lượng",
                      placeholder: intl.formatMessage({ id: "enter" }),
                      propsWrapper: { xs: 6 },
                      shouldUnregister: true,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {current.unit}
                          </InputAdornment>
                        ),
                      },
                    },
                    [`${current?.id}_mucnuoc_v`]: {
                      type: "text-field",
                      inputType: "number",
                      label: "Mực nước",
                      placeholder: intl.formatMessage({ id: "enter" }),
                      propsWrapper: { xs: 6 },
                      shouldUnregister: true,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {current.unit}
                          </InputAdornment>
                        ),
                      },
                    },
                  };
                }, {}),
              };
            },
            ui: ({ valuesField }) => [
              {
                id: "default",
                fields: ["monitoringLocation", "time", "gieng", "observer"],
              },
              ...(valuesField?.gieng || [])?.map((item) => {
                return {
                  id: item.id,
                  title: item.name,
                  fields: [`${item?.id}_luuluong_v`, `${item?.id}_mucnuoc_v`],
                };
              }),

              { id: "attachment", fields: ["attachment"] },
            ],
          }}
          onSubmit={onSubmit}
          initialData={formData}
          onCancel={onClose}
          formProps={{
            autoComplete: "nope",
            autoCorrect: "off",
          }}
        />
      </>
    );
  }
  return (
    <>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
            methods: { setValue },
          }) => {
            return {
              construction: {
                hidden: formData?.id,
                type: "auto-complete",
                label: intl.formatMessage({ id: "construction.name" }),
                placeholder: intl.formatMessage({
                  id: "search",
                }),

                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.construction.index({
                        page: 0,
                        pageSize: 100,
                        name,
                        nreTypeId,
                        constructionTypeId: constructionTypeId,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                loadKey: [nreTypeId, constructionTypeId].join(","),
                onChange: () => {
                  setValue("monitoringLocation", null);
                },
                propsWrapper: { xs: 6 },
              },
              time: {
                type: "dateTimePicker",
                label: intl.formatMessage({ id: "periodicMonitoring.time" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              longitude: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "longitude" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              latitude: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "latitude" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              observer: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "periodicMonitoring.observer",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              attachment: {
                type: "uploadFile",
                label: intl.formatMessage({ id: "attachment" }),
                placeholder: intl.formatMessage({ id: "select" }),
                propsWrapper: { xs: 6 },
              },
              ...indicators?.reduce((value, current) => {
                return {
                  ...value,
                  [`${current.code}_v`]: {
                    type: "text-field",
                    inputType: "number",
                    label: current.name,
                    placeholder: intl.formatMessage({ id: "enter" }),
                    propsWrapper: { xs: 6 },
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {current.unit}
                        </InputAdornment>
                      ),
                    },
                  },
                };
              }, {}),
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={
        formData?.id ? "periodicMonitoring.update" : "periodicMonitoring.create"
      }
    >
      <SchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
