import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { some } from "../../../common/constants";

interface Props {
  data?: some[];
  loading: boolean;
  onCreateUpdateForm: (value: some) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { data, loading, onCreateUpdateForm, onDelete } = props;

  const columns = [
    {
      title: "licenseTypeMetadata.name",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "licenseTypeMetadata.datatype",
      dataIndex: "datatype",
    },
    {
      title: "description",
      dataIndex: "description",
    },

    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <IconButton
            style={{ marginRight: 8 }}
            size="small"
            onClick={() => {
              onCreateUpdateForm(record);
            }}
          >
            <Edit />
          </IconButton>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">
          <FormattedMessage id="licenseMetadata.list" />
        </Typography>
        <TagPM>
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={() => onCreateUpdateForm({})}
          >
            <FormattedMessage id="create" />
          </Button>
        </TagPM>
      </Box>
      <TableCustom dataSource={data} loading={loading} columns={columns} />
    </>
  );
};
export default Table;
