import { CloseRounded } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/lab";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Slide,
  ThemeProvider,
} from "@mui/material";
import enLocale from "date-fns/locale/en-US";
import viLocale from "date-fns/locale/vi";
import "moment/locale/vi";
import { SnackbarProvider } from "notistack";
import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useRoutes } from "react-router";
import "./App.css";
import ConfirmDialogProvider from "./common/components/ConfirmDialogProvider";
import CustomAdapter from "./common/components/CustomDateAdapter";
import FetchErrorDialog from "./common/components/FetchErrorDialog";
import LoadingIcon from "./common/components/LoadingIcon";
import ProtectedRoute from "./common/components/ProtectedRoute";
import RedirectRoute from "./common/components/RedirectRoute";
import { MUITheme } from "./config/setupTheme";
import { ROUTES } from "./layout/constants";
import DefaultLayout from "./layout/DefaultLayout";
import { ADMIN_ROUTES } from "./layout/router";
import {
  mappedRoutesPermission,
  mappedRoutesPermissionLayout,
} from "./layout/utils";
import LoginPage from "./modules/auth/page/LoginPage";
import { AppState } from "./redux/reducer";
import "./scss/svg.scss";

export const localeMap = {
  vi: viLocale,
  en: enLocale,
};

function App() {
  const notistackRef = React.useRef<any>(null);
  const appState = useSelector((state: AppState) => state, shallowEqual);
  const location = useLocation();
  const mode = useSelector(
    (state: AppState) => state.common.mode,
    shallowEqual
  );

  const { loading } = useSelector((state: AppState) => state.common);
  const locale = useSelector(
    (state: AppState) => state.intl.locale,
    shallowEqual
  );

  const onClickDismiss = (key) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  const mappedMenus = useMemo(() => {
    const getChild = (parentId) => {
      return (
        appState.auth.tokenInfo?.menus
          ?.filter((v) => v.parentId === parentId)
          .map((v) => {
            return { ...v, children: getChild(v.id) };
          }) || []
      )?.sort((a, b) =>
        a.sortOrder ? (a.sortOrder || 0) - (b.sortOrder || 0) : 1
      );
    };
    return getChild(null);
  }, [appState.auth.tokenInfo]);

  const routesPermission = useMemo(
    () => mappedRoutesPermission(ADMIN_ROUTES(appState, location), mappedMenus),
    [appState, location, mappedMenus]
  );

  const routesPermissionLayout = useMemo(
    () =>
      mappedRoutesPermissionLayout(
        ADMIN_ROUTES(appState, location),
        mappedMenus
      ),
    [appState, location, mappedMenus]
  );

  const element = useRoutes([
    {
      path: ROUTES.login,
      element: (
        <RedirectRoute>
          <LoginPage />
        </RedirectRoute>
      ),
    },
    {
      path: "*",
      element: (
        <ProtectedRoute>
          <DefaultLayout listRoutes={routesPermissionLayout} />
        </ProtectedRoute>
      ),
      children: routesPermission,
    },
  ]);

  return (
    <ThemeProvider theme={MUITheme(mode)}>
      <LocalizationProvider
        dateAdapter={CustomAdapter as any}
        locale={localeMap[locale]}
      >
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          preventDuplicate
          TransitionComponent={Slide}
          autoHideDuration={3000}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          )}
        >
          <ConfirmDialogProvider>
            <React.Suspense fallback={<LoadingIcon />}>
              {element}
            </React.Suspense>
            <FetchErrorDialog />
            <Backdrop unmountOnExit style={{ zIndex: 2000 }} open={loading}>
              <div style={{ color: "white" }}>
                <CircularProgress variant="indeterminate" color="inherit" />
              </div>
            </Backdrop>
          </ConfirmDialogProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
