// Material UI
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Paper, Tab } from "@mui/material";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate, useParams } from "react-router";
// API
import useSWR from "swr";
import LoadingIcon from "../../../common/components/LoadingIcon";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useFilterHook from "../../../common/hook/useFilterHook";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { RoleSchemaFormContent } from "../component/FormRoleDialog";
import UserListTab from "../component/UserListTab";

// Redux

const RoleDetailPage = (props: any) => {
  const { dispatch, enqueueSnackbar, API_PATHS, fetchThunk } = useGeneralHook();
  const { id } = useParams<{ id: string }>();
  const hookPagination = useFilterHook();
  const { tab, setParams } = hookPagination;

  const { data, isValidating, revalidate } = useSWR(
    id ? API_PATHS.role.getDetail({ roleId: id }) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  const onUpdateForm = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = await dispatch(
        fetchThunk(API_PATHS.role.update, "post", value)
      );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [API_PATHS.role.update, dispatch, enqueueSnackbar, fetchThunk, revalidate]
  );

  const TABS = useMemo(
    () => [
      {
        id: "info",
        label: "role.info",
        component: data ? (
          <RoleSchemaFormContent
            formData={{ ...data, ...data.role, role: undefined }}
            onSubmit={onUpdateForm}
            onClose={(methods) => {
              methods.reset();
            }}
          />
        ) : (
          <LoadingIcon />
        ),
      },
      {
        id: "users",
        label: "role.users",
        component: <UserListTab />,
      },
    ],
    [data, onUpdateForm]
  );

  if (!data && !isValidating) {
    return <Navigate to="/" />;
  }

  return (
    <Box display="flex" flexDirection="column">
      <TabContext value={tab || "info"}>
        <Paper variant="outlined">
          {!data && isValidating ? (
            <LoadingIcon />
          ) : (
            <>
              <TabList
                onChange={(e, newValue) => {
                  setParams({ tab: newValue });
                }}
                variant="scrollable"
                indicatorColor="primary"
              >
                {TABS?.map((tab: some, index: number) => {
                  return (
                    <Tab
                      key={index}
                      value={tab.id}
                      style={{ minWidth: 100 }}
                      disableTouchRipple
                      label={<FormattedMessage id={tab.label} />}
                    />
                  );
                })}
              </TabList>
              <Divider />
              <Box padding={2}>
                {TABS.map((tab) => {
                  return (
                    <TabPanel
                      key={tab.id}
                      value={tab.id}
                      style={{ padding: 0 }}
                    >
                      {tab.component}
                    </TabPanel>
                  );
                })}
              </Box>
            </>
          )}
        </Paper>
      </TabContext>
    </Box>
  );
};

export default RoleDetailPage;
