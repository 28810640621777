import { IconButton, Typography, useTheme } from "@mui/material";
import { OptionsObject, useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { API_PATHS } from "../../api/API_App";
import { AppState } from "../../redux/reducer";
import { useConfirmDialog } from "../components/ConfirmDialogProvider";
import { some, SUCCESS_CODE } from "../constants";
import { setLoading } from "../reducer/commonReducer";
import { fetchThunk } from "../reducer/thunk";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface OptionsSnackbarObject extends Omit<OptionsObject, "variant"> {
  message?: React.ReactNode;
  variant?: number | OptionsObject["variant"];
  requestId?: number;
}

const useGeneralHook = () => {
  const dispatch: ThunkDispatch<AppState, null, AnyAction> = useDispatch();
  const appState = useSelector((state: AppState) => state);
  const theme = useTheme();
  const confirmDialog = useConfirmDialog();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const intl = useIntl();
  const [state, setState] = useState<some>({});

  const setLoadingFnc = useCallback(
    (open: boolean) => {
      dispatch(setLoading(open));
    },
    [dispatch]
  );

  return {
    intl,
    dispatch,
    appState,
    enqueueSnackbar: (params: OptionsSnackbarObject) => {
      const { requestId, message, variant, ...rest } = params;
      enqueueSnackbar(
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Typography variant="body2" color="inherit">
              {message}
            </Typography>
            {requestId && (
              <Typography variant="body2" color="inherit">
                {intl.formatMessage({ id: "requestId" })}:&nbsp;
                {requestId}
              </Typography>
            )}
          </div>{" "}
          {requestId && (
            <CopyToClipboard text={requestId}>
              <IconButton aria-label="copy" color="inherit" size="small">
                <ContentCopyIcon />
              </IconButton>
            </CopyToClipboard>
          )}
        </div>,
        {
          ...rest,
          variant:
            typeof variant === "number"
              ? variant === SUCCESS_CODE
                ? "success"
                : "error"
              : variant,
        }
      );
    },
    closeSnackbar,
    theme,
    confirmDialog,
    location,
    navigate,
    fetchThunk: fetchThunk,
    API_PATHS: API_PATHS,
    setLoading: setLoadingFnc,
    state,
    setState: (value) => setState((old) => (value ? { ...old, ...value } : {})),
    isDarkTheme: theme.palette.mode === "dark",
  };
};

export default useGeneralHook;
