import Cookies from "js-cookie";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../../redux/reducer";
import { logoutThunk } from "../../modules/auth/reducer/authenticationReducer";
import { some, TOKEN } from "../constants";
import { setLoading, setNetworkError } from "./commonReducer";
import { parseJwt } from "../../layout/utils";
import moment from "moment";
import { API_PATHS } from "../../api/API_App";

export function fetchThunk(
  url: string,
  method: "delete" | "put" | "get" | "post" | "PATCH" = "get",
  body?: some | FormData | string | some[],
  contentType?: string,
  fallback = { cancelled: false, data: {} }
): ThunkAction<Promise<some | any>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    while (true) {
      let res: Response | null = null;
      let token = Cookies.get(TOKEN) || "";
      const exp = parseJwt(token)?.exp;
      if (moment(exp * 1000).isBefore(moment())) {
        Cookies.remove(TOKEN);
        try {
          res = await fetch(API_PATHS.auth.resetToken, {
            method: "get",
            body: "",
            headers: {
              "Cache-Control": "no-cache",
              Authorization: `Bearer ${token}`,
              "Content-Type": contentType || "application/json",
            },
          });
        } catch (_) {}
        if (res) {
          if (res.status === 402 || res.status === 500) {
            throw new Error(await res.text());
          }
          if (res.status === 501) {
            throw new Error(res.statusText);
          }
          if (res.status === 403) {
            dispatch(logoutThunk());
            // throw new Error("Invalid token");
            return null;
          }
          if (res.ok) {
            const json = await res.json();
            Cookies.set(TOKEN, json.data);
            token = json.data;
          }
        }
      }

      try {
        res = await fetch(url, {
          method,
          body:
            body instanceof FormData
              ? body
              : typeof body === "object"
              ? JSON.stringify(body)
              : body,
          headers:
            contentType !== "multipart/form-data"
              ? {
                  "Cache-Control": "no-cache",
                  Authorization: `Bearer ${token}`,
                  "Content-Type": contentType || "application/json",
                }
              : {
                  Authorization: `Bearer ${token}`,
                },
        });
      } catch (_) {}
      dispatch(setLoading(false));
      if (res) {
        // if (res.status === 402 || res.status === 500) {
        //   throw new Error(await res.text());
        // }
        // if (res.status === 501) {
        //   throw new Error(res.statusText);
        // }
        if (res.status === 401) {
          dispatch(logoutThunk());
          // throw new Error("Invalid token");
        }
        // if (res.ok) {
        if (fallback.cancelled) {
          return fallback.data;
        }
        if (
          contentType === "image/jpeg" ||
          contentType === "file" ||
          contentType === "application/octet-stream"
        ) {
          const blob = await res.blob();
          return { status: res.status, blob: blob, headers: res.type };
        }
        const json = await res.json();
        return json;
        // }
      }

      let hasInternet = true;
      try {
        await fetch("https://www.google.com", { mode: "no-cors" });
      } catch (_) {
        hasInternet = false;
      }

      dispatch(
        setNetworkError(hasInternet ? "serverProblem" : "unstableNetwork", true)
      );

      do {
        await new Promise((resolve) => setTimeout(resolve, 250));
        if (!getState().common.openErrorDialog) {
          break;
        }
      } while (getState().common.networkErrorMsg);
      if (!getState().common.openErrorDialog) {
        break;
      }
      continue;
    }
    return {};
  };
}
