import { Edit } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { PopoverState } from "../../../common/components/elements";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import {
  DATE_TIME_FULL_FORMAT,
  GIENG_OPTIONS,
  some,
} from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import { capitalizeFirstLetter } from "../../../common/utils";
import { Search } from "../../../svg";
import { LIST_STATUS_STATION } from "../../dashboard/constants";
import AttachmentColumn from "../../../common/components/AttachmentColumn";

interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
  onCreateUpdateForm: (value: some) => void;
  columnsFilter: some[];
  indicators: some[];
  constructionTypeId: string;
}
const Table = (props: Props) => {
  const {
    indicators,
    columnsFilter,
    hookPagination,
    data,
    loading,
    onCreateUpdateForm,
    constructionTypeId,
  } = props;

  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns = [
    {
      title: "construction",
      dataIndex: "construction.name",
      width: 200,
    },
    {
      title: "time",
      render: (record: some) => {
        return record.time && moment(record.time).format(DATE_TIME_FULL_FORMAT);
      },
      width: 200,
    },
    {
      title: "periodicMonitoring.observer",
      dataIndex: "observer",
      width: 130,
    },
    ...(columnsFilter || [])?.map((column) => {
      if (column.indicators) {
        return {
          title: (
            <Box>
              <Typography
                variant="caption"
                fontWeight="bold"
                style={{ wordBreak: "break-word" }}
                component="div"
              >
                {capitalizeFirstLetter(column.name)}
              </Typography>
            </Box>
          ),
          headerProps: { style: { paddingRight: 0, paddingLeft: 0 } },
          align: "center",

          children: column.indicators?.map((indicator) => {
            return {
              title: (
                <Box>
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    component="span"
                  >
                    {capitalizeFirstLetter(indicator.name)}
                  </Typography>
                  {indicator.unit && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                    >
                      &nbsp;({indicator.unit})
                    </Typography>
                  )}
                </Box>
              ),
              minWidth: 70,
              align: "center",
              render: (record: some) => {
                const value = record?.[`${column.code}_${indicator.code}_v`];
                const status = record?.[`${column.code}_${indicator.code}_s`];
                const label = LIST_STATUS_STATION.find((v) =>
                  v.value.includes(status)
                )?.label;
                const color = LIST_STATUS_STATION.find((v) =>
                  v.value.includes(status)
                )?.color;
                return (
                  <Box>
                    {typeof value === "number" ? (
                      <Typography
                        variant="caption"
                        color={
                          record?.status === 99
                            ? "textSecondary"
                            : "success.main"
                        }
                        style={{
                          wordBreak: "break-word",
                        }}
                      >
                        {typeof value === "number" && (
                          <FormattedNumber
                            value={value}
                            maximumFractionDigits={2}
                          />
                        )}
                      </Typography>
                    ) : (
                      "-"
                    )}
                    {color && (
                      <Tooltip
                        title={label ? <FormattedMessage id={label} /> : ""}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 4,
                            left: "calc(50% - 4px)",
                            width: 8,
                            height: 8,
                            borderRadius: "50%",
                            bgcolor: color,
                            flexShrink: 0,
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                );
              },
            };
          }),
        };
      }
      return {
        title: (
          <Box>
            <Typography variant="caption" fontWeight="bold" component="span">
              {capitalizeFirstLetter(column.name)}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              component="span"
            >
              &nbsp;({column.unit})
            </Typography>
          </Box>
        ),
        minWidth: 70,
        align: "center",
        variant: "caption",
        render: (record) => {
          const value = record?.[`${column.code}_v`];
          const status = record?.[`${column.code}_s`];
          const label = LIST_STATUS_STATION.find((v) =>
            v.value.includes(status)
          )?.label;
          const color = LIST_STATUS_STATION.find((v) =>
            v.value.includes(status)
          )?.color;
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Tooltip title={label ? <FormattedMessage id={label} /> : ""}>
                <Typography variant="caption" color={color}>
                  {typeof value === "number" && (
                    <FormattedNumber value={value} maximumFractionDigits={2} />
                  )}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      };
    }),
    {
      title: "attachment",
      width: 100,
      render: (record: some) => {
        return <AttachmentColumn attachment={record?.attachment} />;
      },
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => {
        return (
          <>
            <PopoverState>
              {({ anchorEl, handleClick, handleClose, open }) => {
                return (
                  <>
                    <IconButton onClick={handleClick} style={{ padding: 4 }}>
                      <MoreHorizIcon color="inherit" />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          onCreateUpdateForm({
                            ...record,
                            gieng: GIENG_OPTIONS.filter(
                              (v) =>
                                !!indicators[0]?.indicators?.find(
                                  (indicator) =>
                                    record[`${v.id}_${indicator.code}_v`]
                                )
                            ),
                          });
                        }}
                      >
                        <ListItemIcon>
                          <Edit />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body2">
                            <FormattedMessage id="periodicMonitoring.edit" />
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                      <LinkPM
                        to={{
                          pathname: `${constructionTypeId}/${record?.construction?.id}`,
                        }}
                      >
                        <MenuItem>
                          <ListItemIcon>
                            <Search />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography variant="body2">
                              <FormattedMessage id="periodicMonitoring.search" />
                            </Typography>
                          </ListItemText>
                        </MenuItem>
                      </LinkPM>
                    </Menu>
                  </>
                );
              }}
            </PopoverState>
          </>
        );
      },
    },
  ] as Columns[];

  return (
    <>
      <TableCustom
        dataSource={data?.items}
        loading={loading}
        paginationProps={{
          count: data?.total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        columns={columns}
      />
    </>
  );
};
export default Table;
