import { OpenInNewRounded } from "@mui/icons-material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Paper, Tab, Typography } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import { BE_DATE_FORMAT, DATE_FORMAT, some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
import { Report, Search } from "../../../svg";
import ChartBox from "../component/ChartBox";
import Filter from "../component/Filter";
import IndicatorBox, { FilterIndicatorBox } from "../component/IndicatorBox";
import Table from "../component/Table";

const TabOptions = [
  { id: "list", icon: <Search className="svgStroke" /> },
  // { id: "minMax", icon: <ArrowTwoWay className="svgStroke" /> },
  { id: "chart", icon: <Report className="svgStroke" /> },
];

interface Props {}
const SearchDataPageDay = (props: Props) => {
  const { dispatch, fetchThunk, enqueueSnackbar, API_PATHS } = useGeneralHook();
  const [loading, setLoading] = React.useState(false);

  const hookPagination = usePaginationHook({
    defaultFilter: {
      date: {
        startDate: moment().startOf("months").format(BE_DATE_FORMAT),
        endDate: moment().format(BE_DATE_FORMAT),
      },
    },
  });
  const { params, setParams, pagination, tab } = hookPagination;
  const [filter, setFilter] = React.useState<FilterIndicatorBox>({
    columns: [],
  });

  const { constructionId, date } = params;

  const { data, isValidating } = useSWR(
    constructionId && date
      ? API_PATHS.searchData.day({
          ...pagination,
          ...date,
          constructionId: constructionId?.id,
        })
      : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );

  const getLink = React.useCallback(async () => {
    if (constructionId && date) {
      setLoading(true);
      try {
        const json = await dispatch(
          fetchThunk(
            API_PATHS.searchData.dayExcel({
              ...date,
              constructionId: constructionId?.id,
            }),
            "get",
            undefined,
            "file"
          )
        );
        setLoading(false);
        if (json.status !== 200) {
          return;
        }
        const newBlob = new Blob([json.blob as Blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute(
          "download",
          `${constructionId?.name}_day_${moment(date?.startDate).format(
            DATE_FORMAT
          )}_${moment(date?.endDate).format(DATE_FORMAT)}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(json.blob as any);
      } catch (e: any) {
        enqueueSnackbar({
          message: <FormattedMessage id="downloadFail" />,
          variant: "success",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    API_PATHS.searchData,
    constructionId,
    date,
    dispatch,
    enqueueSnackbar,
    fetchThunk,
  ]);

  return (
    <Box display="flex" flexDirection="column">
      <Filter {...hookPagination} />
      <TabContext value={tab || "list"}>
        <Paper variant="outlined">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={1}
          >
            <TabList
              onChange={(e, newValue) => {
                setParams({ tab: newValue });
              }}
              variant="scrollable"
              indicatorColor="primary"
              style={{ flex: 1 }}
            >
              {TabOptions?.map((item: some, index: number) => {
                return (
                  <Tab
                    key={index}
                    value={item.id}
                    style={{ minWidth: 100 }}
                    disableTouchRipple
                    label={
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {item.icon}
                        <Typography
                          variant="subtitle1"
                          style={{ marginLeft: 8 }}
                          color="inherit"
                        >
                          <FormattedMessage id={`miningData.${item.id}`} />
                        </Typography>
                      </Box>
                    }
                  />
                );
              })}
            </TabList>
            <IndicatorBox
              constructionId={constructionId?.id}
              filter={filter}
              setFilter={setFilter}
            />
            <LoadingButton
              style={{ marginLeft: 8 }}
              loading={loading}
              loadingPosition="start"
              startIcon={<OpenInNewRounded />}
              onClick={() => {
                getLink();
              }}
              disabled={!(constructionId && date)}
            >
              <FormattedMessage id="exportData" />
            </LoadingButton>
          </Box>
          <Divider />
          <Box padding={1}>
            <TabPanel value={"list"} style={{ padding: 0 }}>
              <Table
                loading={isValidating}
                data={data}
                columnsFilter={filter.columns}
                hookPagination={hookPagination}
              />
            </TabPanel>
            {/* <TabPanel
              value={"minMax"}
              style={{ padding: 0, paddingBottom: 56 }}
            >
              <TableMinMax filter={filter} params={params} />
            </TabPanel>{" "} */}
            <TabPanel value={"chart"} style={{ padding: 0 }}>
              <ChartBox filter={filter} params={params} />
            </TabPanel>
          </Box>
        </Paper>
      </TabContext>
    </Box>
  );
};
export default SearchDataPageDay;
