// Material UI
import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
import SelectCustom from "../../../common/SchemaForm/element/select/SelectCustom";
// Component
import Filter from "../component/Filter";
import Table from "../component/Table";

const CameraOnlinePage = (props: any) => {
  const { intl, dispatch, API_PATHS, fetchThunk } = useGeneralHook();
  const [formData, setFormData] = useState<some | undefined>(undefined);
  const [data, setData] = useState<some | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const hookPagination = usePaginationHook({
    disableLink: true,
    disableSavePageSize: true,
    defaultPagination: { page: 0, pageSize: 9 },
    defaultFilter: {
      constructionIds: [],
    },
  });

  const { pagination, filter, setParams } = hookPagination;

  const getData = useCallback(async () => {
    setLoading(true);
    const json = await dispatch(
      fetchThunk(API_PATHS.cameraOnline.index, "post", {
        constructionIds: filter?.constructionIds || [],
        ...pagination,
      })
    );
    setLoading(false);
    if (json.code === SUCCESS_CODE) {
      setData(json.data);
    }
  }, [
    API_PATHS.cameraOnline.index,
    dispatch,
    fetchThunk,
    filter?.constructionIds,
    pagination,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Box display="flex" height="100%" gap={2}>
      <Filter {...hookPagination} />
      <Box flex={1}>
        <Box display="flex" width="100%" alignItems="center" marginBottom={2}>
          <Typography variant="subtitle1" style={{ flex: 1 }}>
            <FormattedMessage id="construction.list" />
          </Typography>
          <Typography variant="body2" style={{ marginRight: 8 }}>
            <FormattedMessage id="constructionType" />
          </Typography>
          <Box>
            <SelectCustom
              hasAllOptions
              value={filter.constructionTypeId || " "}
              placeholder={intl.formatMessage({
                id: "select",
              })}
              style={{ width: 200 }}
              loadOptions={async () => {
                const json = await dispatch(
                  fetchThunk(
                    API_PATHS.constructionType.index({
                      pageSize: 100,
                    })
                  )
                );
                return json.data?.items?.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              }}
              onChange={(value) => setParams({ constructionTypeId: value })}
            />
          </Box>
        </Box>
        <Table
          data={data}
          loading={loading}
          hookPagination={hookPagination}
          setFormData={setFormData}
        />
        {/* <DetailDialog
          open={!!formData}
          onClose={() => {
            setFormData(undefined);
          }}
          formData={formData}
        /> */}
      </Box>
    </Box>
  );
};

export default CameraOnlinePage;
