import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Paper, Tab } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import LoadingIcon from "../../../common/components/LoadingIcon";
import { some } from "../../../common/constants";
import useFilterHook from "../../../common/hook/useFilterHook";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import InfoTab from "../component/InfoTab";
import ThresholdTab from "../component/ThresholdTab";

const QCVNDetailPage = () => {
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();
  const { tab = "info", setParams } = useFilterHook();

  const { id } = useParams<{ id: string }>();

  const { data, revalidate, isValidating } = useSWR(
    id ? API_PATHS.qcvn.update(id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  const TabOptions = [
    {
      id: "info",
      component: <InfoTab detailData={data} revalidate={revalidate} />,
    },
    { id: "type", component: <ThresholdTab /> },
  ];

  return (
    <Box display="flex" flexDirection="column">
      <TabContext value={tab}>
        <Paper variant="outlined">
          {!data && isValidating ? (
            <LoadingIcon />
          ) : (
            <>
              <TabList
                onChange={(e, newValue) => {
                  setParams({ tab: newValue });
                }}
                variant="scrollable"
                indicatorColor="primary"
              >
                {TabOptions?.map((tab: some, index: number) => {
                  return (
                    <Tab
                      key={index}
                      value={tab.id}
                      style={{ minWidth: 100 }}
                      disableTouchRipple
                      label={<FormattedMessage id={`qcvn.${tab.id}`} />}
                    />
                  );
                })}
              </TabList>
              <Divider />
              <Box padding={2}>
                {TabOptions.map((item, index) => {
                  return (
                    <TabPanel
                      key={item.id}
                      value={item.id}
                      style={{ padding: 0 }}
                    >
                      {item.component}
                    </TabPanel>
                  );
                })}
              </Box>
            </>
          )}
        </Paper>
      </TabContext>
    </Box>
  );
};
export default QCVNDetailPage;
