import * as React from "react";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose?: () => void;
}

interface PropsContent {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  onClose?: () => void;
  formData?: some;
}

export const ConstructionSchemaFormContent: React.FunctionComponent<PropsContent> =
  (props) => {
    const { onSubmit, formData, onClose } = props;
    return (
      <>
        <SchemaForm
          schema={{
            fields: ({
              formProps: { intl, dispatch, fetchThunk, API_PATHS },
              valuesField,
              methods: { setValue },
            }) => {
              return {
                name: {
                  type: "text-field",
                  label: intl.formatMessage({ id: "construction.name" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                },
                code: {
                  type: "text-field",
                  label: intl.formatMessage({
                    id: "construction.code",
                  }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                },

                nreType: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "nreType" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.nreType.index({
                          name,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                      code: v.code,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                  noHelperText: true,
                },
                constructionType: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "constructionType" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  readOnly: !valuesField.nreType?.id,
                  loadKey: valuesField.nreType?.id,
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.constructionType.index({
                          nreTypeId: valuesField.nreType?.id,
                          name,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                },
                constructionKind: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "constructionKind" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  disableSearchByText: true,
                  loadOptions: async () => {
                    const json = await dispatch(
                      fetchThunk(API_PATHS.constructionKind.index({}))
                    );
                    return json.data?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                },
                agency: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "agency" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.agency.index({
                          name,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                },
                enterprise: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "enterprise" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.enterprise.index({
                          name,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                },
                area: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "area" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.area.index({
                          name,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                },
                riverBasin: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "riverBasin" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.riverBasin.index({
                          name,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  register: {
                    required: true,
                  },
                },
                province: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "province" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.province.index({
                          name,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  noHelperText: true,
                  onChange: () => {
                    setValue("district", null);
                    setValue("commune", null);
                  },
                },
                district: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "district" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadKey: valuesField.province?.id,
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.district.index({
                          name,
                          provinceId: valuesField.province?.id,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  onChange: () => {
                    setValue("commune", null);
                  },
                  propsWrapper: { xs: 4 },
                  noHelperText: true,
                },
                commune: {
                  type: "auto-complete",
                  label: intl.formatMessage({ id: "commune" }),
                  placeholder: intl.formatMessage({
                    id: "select",
                  }),
                  loadKey: valuesField.district?.id,
                  loadOptions: async (name: string) => {
                    const json = await dispatch(
                      fetchThunk(
                        API_PATHS.commune.index({
                          name,
                          districtId: valuesField.district?.id,
                          pageSize: 100,
                        })
                      )
                    );
                    return json.data?.items?.map((v) => ({
                      id: v.id,
                      name: v.name,
                    }));
                  },
                  propsWrapper: { xs: 4 },
                  noHelperText: true,
                },
                address: {
                  type: "text-field",
                  label: intl.formatMessage({ id: "address" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  propsWrapper: { xs: 4 },
                },

                longitude: {
                  type: "text-field",
                  inputType: "number",
                  label: intl.formatMessage({ id: "longitude" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  propsWrapper: { xs: 4 },
                },
                latitude: {
                  type: "text-field",
                  inputType: "number",
                  label: intl.formatMessage({ id: "latitude" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  propsWrapper: { xs: 4 },
                },
                operationDate: {
                  type: "datePicker",
                  label: intl.formatMessage({
                    id: "construction.operationDate",
                  }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  propsWrapper: { xs: 4 },
                },
                sortOrder: {
                  type: "text-field",
                  inputType: "number",
                  label: intl.formatMessage({ id: "sortOrder" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  propsWrapper: { xs: 4 },
                },
                attachment: {
                  type: "uploadFile",
                  label: intl.formatMessage({ id: "attachment" }),
                  placeholder: intl.formatMessage({ id: "select" }),
                  propsWrapper: { xs: 4 },
                },
                description: {
                  type: "text-field",
                  label: intl.formatMessage({ id: "description" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  multiline: true,
                  rows: 4,
                  propsWrapper: { xs: 8 },
                },
              };
            },
          }}
          onSubmit={onSubmit}
          formData={formData}
          onCancel={onClose ? onClose : (methods) => methods.reset()}
          formProps={{
            autoComplete: "nope",
            autoCorrect: "off",
          }}
        />
      </>
    );
  };

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={"create"}
    >
      <ConstructionSchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
