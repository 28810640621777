import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import * as React from "react";
import DrawerCustom from "../../../common/components/DrawerCustom";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";
import { EMAIL_REGEX } from "../../../config/regex";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({ formProps: { intl, state, setState } }) => {
            return {
              userId: {
                type: "text-field",
                label: intl.formatMessage({ id: "user.userId" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
              },
              password: {
                type: "text-field",
                hidden: !!formData?.createdDate,
                inputType: state?.showPassword ? "text" : "password",
                label: intl.formatMessage({ id: "ftp.password" }),
                placeholder: intl.formatMessage({ id: "ftp.passwordEnter" }),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setState({ showPassword: !state.showPassword })
                        }
                      >
                        {!state.showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: 4, overflow: "hidden" },
                },
                register: {
                  required: true,
                },
              },
              name: {
                type: "text-field",
                label: intl.formatMessage({ id: "user.name" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
              },
              email: {
                type: "text-field",
                label: intl.formatMessage({ id: "email" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: intl.formatMessage({ id: "emailValid" }),
                  },
                },
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default FormDialog;
