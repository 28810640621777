// Material UI
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Divider, Paper, Tab } from "@mui/material";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate, useParams } from "react-router";
// API
import useSWR from "swr";
import LoadingIcon from "../../../common/components/LoadingIcon";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useFilterHook from "../../../common/hook/useFilterHook";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { StationSchemaFormContent } from "../component/StationFormDialog";
import AutoCheckTab from "../station/AutoCheckTab";
import CameraTab from "../station/CameraTab";
import DataLoggerStationTab from "../station/DataLoggerStationTab";
import FTPTab from "../station/FTPTab";
import IndicatorTab from "../station/IndicatorTab";
import MQTTTab from "../station/MQTTTab";
import SensorTab from "../station/SensorTab";
// Redux

const StationDetailPage = (props: any) => {
  const { dispatch, enqueueSnackbar, API_PATHS, fetchThunk } = useGeneralHook();
  const { id, stationId } = useParams<{ id: string; stationId: string }>();
  const hookPagination = useFilterHook();
  const { tab, setParams } = hookPagination;

  const { data, isValidating, revalidate } = useSWR(
    id && stationId ? API_PATHS.station.detail(stationId) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const { data: constructionData } = useSWR(
    id ? API_PATHS.construction.update(id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  const onSubmit = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(API_PATHS.station.update(value.id), "put", {
              ...value,
              constructionId: id,
            })
          )
        : await dispatch(
            fetchThunk(API_PATHS.station.create, "post", {
              ...value,
              constructionId: id,
            })
          );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [API_PATHS.station, dispatch, fetchThunk, id, enqueueSnackbar, revalidate]
  );

  const TABS = useMemo(
    () => [
      {
        id: "info",
        label: "station.detailInfo",
        component: (
          <StationSchemaFormContent formData={data} onSubmit={onSubmit} />
        ),
      },
      data?.method === "MQTT"
        ? {
            id: "mqtt",
            label: "mqtt",
            component: <MQTTTab formData={data} />,
          }
        : {
            id: "ftp",
            label: "ftp",
            component: <FTPTab formData={data} />,
          },
      {
        id: "indicator",
        label: "station.indicator",
        component: (
          <IndicatorTab nreTypeCode={constructionData?.nreType?.code} />
        ),
      },
      {
        id: "autoCheck",
        label: "autoCheck",
        component: <AutoCheckTab />,
      },
      {
        id: "datalogger",
        label: "dataLogger",
        component: <DataLoggerStationTab formData={data} />,
      },
      {
        id: "sensor",
        label: "sensor",
        component: <SensorTab />,
      },
      {
        id: "camera",
        label: "camera",
        component: <CameraTab formData={data} />,
      },
    ],
    [constructionData?.nreType?.code, data, onSubmit]
  );

  if (!data && !isValidating) {
    return <Navigate to="/" />;
  }

  return (
    <Box display="flex" flexDirection="column">
      <TabContext value={tab || "info"}>
        <Paper variant="outlined">
          {!data && isValidating ? (
            <LoadingIcon />
          ) : (
            <>
              <TabList
                onChange={(e, newValue) => {
                  setParams({ tab: newValue });
                }}
                variant="scrollable"
                indicatorColor="primary"
              >
                {TABS?.map((tab: some, index: number) => {
                  return (
                    <Tab
                      key={index}
                      value={tab.id}
                      style={{ minWidth: 100 }}
                      disableTouchRipple
                      label={<FormattedMessage id={tab.label} />}
                    />
                  );
                })}
              </TabList>
              <Divider />
              <Box padding={2}>
                {TABS.map((tab) => {
                  return (
                    <TabPanel
                      key={tab.id}
                      value={tab.id}
                      style={{ padding: 0 }}
                    >
                      {tab.component}
                    </TabPanel>
                  );
                })}
              </Box>
            </>
          )}
        </Paper>
      </TabContext>
    </Box>
  );
};

export default StationDetailPage;
