/* eslint-disable react-hooks/exhaustive-deps */
// Material UI
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import {
  alpha,
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import LinkPM from "../../../../common/components/LinkPM";
import { some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
import { ROUTES } from "../../../../layout/constants";
interface Props {}
const ConstructionBox = (props: Props) => {
  const { dispatch, API_PATHS } = useGeneralHook();
  const { data } = useSWR(
    API_PATHS.dashboard.construction,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  return (
    <Box height="100%" display={"flex"} flexDirection="column">
      <Typography variant="h6" marginBottom={2}>
        <FormattedMessage id="dashboard.summaryConStruction" />{" "}
        <Typography variant="body2" component="span">
          <FormattedMessage
            id="dashboard.summary"
            values={{
              num: data?.reduce((v, c) => v + c?.total, 0),
            }}
          />
        </Typography>
      </Typography>
      <Grid container spacing={2} height={"100%"}>
        {data?.map((item: some, index: number) => {
          return (
            <Grid item key={index} style={{ overflow: "hidden" }} xs={3}>
              <Paper
                variant="outlined"
                sx={{
                  padding: 1.5,
                  bgcolor: alpha(item.color, 0.4),
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box display="flex" gap={1}>
                  <Avatar src={item.icon} style={{ background: item.color }}>
                    &nbsp;
                  </Avatar>
                  <Box flex={1}>
                    <Typography variant="subtitle2">
                      {item.name}&nbsp;({item.code})
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      <FormattedMessage
                        id="dashboard.constructionAmount"
                        values={{
                          num: item.total,
                        }}
                      />
                    </Typography>
                  </Box>
                  <Box>
                    <LinkPM
                      to={{
                        pathname: ROUTES.monitorOnline.gen(item.id),
                      }}
                    >
                      <IconButton>
                        <ArrowForwardRoundedIcon color="primary" />
                      </IconButton>
                    </LinkPM>
                  </Box>
                </Box>
                <Divider sx={{ marginY: 1 }} />
                <Box sx={{ overflow: "auto", flex: 1 }} gap={2}>
                  {item.constructionTypes.map((val) => {
                    return (
                      <Typography key={val.id} variant="body2">
                        {val.name}&nbsp;({val.total})
                      </Typography>
                    );
                  })}
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ConstructionBox;
