/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  CircularProgress,
  FilterOptionsState,
  OutlinedInputProps,
} from "@mui/material";
import { isEqual } from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDebounce } from "usehooks-ts";
import { some } from "../../../constants";
import TextFieldElement from "../text-field/TextFieldElement";
import { ListboxComponent } from "./ListboxComponent";

export interface FormControlAutoCompletePropsBase {
  label?: React.ReactNode;
  formControlStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  error?: boolean;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  required?: boolean;
  loadOptions?: (input: string) => Promise<some[]>;
  loadKey?: any;
  disableSearchByText?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  freeSolo?: boolean;
  multiple?: boolean;
  disableClearable?: boolean;
  onChangeInput?: OutlinedInputProps["onChange"];
  options?: some[];
  windowScroll?: boolean;
  getOptionLabel?: (option: some) => string;
  isOptionEqualToValue?: (option: some, value: some) => boolean;
  getOptionDisabled?: (option: some) => boolean;
  groupBy?: (option: some) => string;
  initialSearch?: string;
  disableReSearchOnBlur?: boolean;
  limitTags?: number;
  filterOptionsRoot?: (options: some[]) => some[];
  filterOptions?: (options: some[], state: FilterOptionsState<some>) => some[];
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: some,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
}

export interface FormControlAutoCompleteProps
  extends FormControlAutoCompletePropsBase {
  innerRef?: React.Ref<any>;
}

export const FormControlAutoComplete = (
  props: FormControlAutoCompleteProps | some
) => {
  const {
    label,
    placeholder,
    error,
    formControlStyle,
    required,
    renderInput,
    options,
    loadOptions,
    startAdornment,
    endAdornment,
    inputStyle,
    labelStyle,
    innerRef,
    readOnly,
    onChangeInput,
    autoFocus,
    windowScroll,
    initialSearch,
    disableReSearchOnBlur,
    loadKey,
    disableSearchByText,
    filterOptionsRoot: filterOptions,
    ...rest
  } = props;

  const [optionsTmp, setOption] = useState<typeof options>(options || []);
  const [loading, setLoading] = useState<boolean>(false);
  const [term, setTerm] = useState(initialSearch);
  const debouncedTerm = useDebounce<string>(term, 300);

  const loadOptionsFnc = async (input: string) => {
    if (loadOptions) {
      try {
        setLoading(true);
        const data = await loadOptions(input);
        if (data && data.length > 0) {
          setOption(data || []);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!isEqual(options, optionsTmp)) {
      setOption(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    loadOptionsFnc(debouncedTerm);
  }, [debouncedTerm, loadKey]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      loading={loading}
      options={
        filterOptions ? filterOptions(optionsTmp || []) : optionsTmp || []
      }
      onInputChange={(event: object, value: string, reason: string) => {
        reason === "input" && !disableSearchByText && setTerm(value);
      }}
      noOptionsText={<FormattedMessage id="noOption" />}
      disabled={readOnly}
      renderInput={
        renderInput ||
        (({ InputProps, ...params }) => (
          <TextFieldElement
            {...params}
            disabled={rest.disabled || false}
            fullWidth
            error={error}
            label={label}
            inputRef={innerRef}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
            required={required}
            InputProps={{
              ...InputProps,
              readOnly,
              autoFocus: autoFocus,
              style: {
                padding: 0,
                paddingRight: 24,
                ...inputStyle,
              },
              startAdornment: (
                <>
                  {startAdornment}
                  {InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {InputProps.endAdornment}
                  {endAdornment}
                </>
              ),
              error: error,
            }}
            onChange={onChangeInput}
            variant="outlined"
            size="small"
          />
        ))
      }
      getOptionLabel={(option: any) => option.name || " "}
      isOptionEqualToValue={(option: some, value: some) =>
        option.id === value.id
      }
      autoComplete
      ListboxComponent={
        windowScroll
          ? (ListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >)
          : undefined
      }
      onMouseDownCapture={(e) => !optionsTmp?.length && e.stopPropagation()}
      disableCloseOnSelect={!!rest.multiple}
      // PopperComponent={(val) => <Popper {...val} disablePortal />} dùng khi để fullscreen
      {...(loadOptions && !disableSearchByText
        ? { filterOptions: (option) => option }
        : {})}
      {...rest}
    />
  );
};

export default FormControlAutoComplete;
