import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { DATE_TIME_FORMAT, some } from "../../../common/constants";
interface Props {
  data?: some[];
  loading: boolean;
  onDelete: (value: some) => void;
  setFormDataRole: (value: some) => void;
}
const Table = (props: Props) => {
  const { data, loading, onDelete, setFormDataRole } = props;

  const columns = [
    {
      title: "role.appId",
      dataIndex: "appId",
    },
    {
      title: "role.roleId",
      dataIndex: "roleId",
    },
    {
      title: "role.roleName",
      dataIndex: "roleName",
    },
    {
      title: "email",
      dataIndex: "email",
    },
    {
      title: "role.createdDate",
      render: (record) =>
        record.createdDate &&
        moment(record.createdDate).format(DATE_TIME_FORMAT),
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 120,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <Box display="flex" gap={1} justifyContent="center">
          <LinkPM to={record.roleId}>
            <IconButton style={{ marginRight: 8 }} size="small">
              <Edit />
            </IconButton>
          </LinkPM>
          <IconButton
            onClick={() => {
              onDelete(record);
            }}
            size="small"
          >
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">
            <FormattedMessage id="role.list" />
          </Typography>
          <TagPM>
            <Button
              startIcon={<Add />}
              color="primary"
              onClick={() => setFormDataRole({})}
            >
              <FormattedMessage id="create" />
            </Button>
          </TagPM>
        </Box>
        <TableCustom dataSource={data} loading={loading} columns={columns} />
      </Paper>
    </>
  );
};
export default Table;
