// Material UI
import { Add, OpenInNewRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
// Redux
import { fetchThunk } from "../../../common/reducer/thunk";
// Component
import Filter from "../component/Filter";
import FormDialog from "../component/FormDialog";
import Table from "../component/Table";

const LicensePage = (props: any) => {
  const { dispatch, enqueueSnackbar, confirmDialog, intl } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;
  const [formData, setFormData] = useState<some | undefined>();
  const [loading, setLoading] = useState(false);

  const { data, isValidating, revalidate } = useSWR(
    API_PATHS.license.index(params),
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(API_PATHS.license.update(value.id), "put", value)
          )
        : await dispatch(fetchThunk(API_PATHS.license.create, "post", value));
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, revalidate]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.code }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.license.update(value.id), "delete")
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, enqueueSnackbar, revalidate]
  );

  const getLink = React.useCallback(async () => {
    setLoading(true);
    try {
      const json = await dispatch(
        fetchThunk(
          API_PATHS.license.exportExcel({
            licenseTypeId: params.licenseTypeId?.id,
          }),
          "get",
          undefined,
          "file"
        )
      );
      setLoading(false);
      if (json.status !== 200) {
        return;
      }
      const newBlob = new Blob([json.blob as Blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        `Báo-cáo-thống-kê-giấy-phép-theo-loại-hình-cấp-phép.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(json.blob as any);
    } catch (e: any) {
      enqueueSnackbar({
        message: <FormattedMessage id="downloadFail" />,
        variant: "success",
      });
    } finally {
      setLoading(false);
    }
  }, [dispatch, enqueueSnackbar]);

  return (
    <Box display="flex" flexDirection="column">
      <Filter {...hookPagination} />{" "}
      <Paper sx={{ p: 1 }}>
        <Box display="flex" alignItems="center" marginBottom={2} gap={1}>
          <Typography variant="h6" flex={1}>
            <FormattedMessage id="license.list" />
          </Typography>
          <LoadingButton
            style={{ marginLeft: 8 }}
            loading={loading}
            loadingPosition="start"
            startIcon={<OpenInNewRounded />}
            onClick={() => {
              getLink();
            }}
          >
            <FormattedMessage id="exportData" />
          </LoadingButton>
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={() => setFormData({})}
          >
            <FormattedMessage id="create" />
          </Button>
        </Box>
        <Table
          data={data}
          loading={isValidating}
          hookPagination={hookPagination}
          onDelete={onDelete}
        />
      </Paper>
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
    </Box>
  );
};

export default LicensePage;
