import { Visibility } from "@mui/icons-material";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";
import { LIST_REPORT_TABLE } from "../constants";

interface Props {}
const Table = (props: Props) => {
  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">
            <FormattedMessage id="report.list" />
          </Typography>
        </Box>
        <TableCustom
          dataSource={Object.values(LIST_REPORT_TABLE).filter((v) => !v.hidden)}
          columns={[
            {
              title: "report.number",
              render: (record, index) => {
                return (
                  <FormattedMessage
                    id="report.number2"
                    values={{ num: record.value }}
                  />
                );
              },
            },
            { title: "report.name", dataIndex: "name" },
            {
              title: "action",
              align: "center",
              width: 70,
              render: (record) => {
                return (
                  <LinkPM to={`${record.value}`}>
                    <IconButton>
                      <Visibility />
                    </IconButton>
                  </LinkPM>
                );
              },
            },
          ]}
        />
      </Paper>
    </>
  );
};
export default Table;
