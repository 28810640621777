// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
import { HookFilterProps } from "../../../common/hook/useFilterHook";
// Hooks + Common
import SchemaForm from "../../../common/SchemaForm";

interface Props extends HookFilterProps {}
const FilterConstruction = (props: Props) => {
  const { filter, setParams, clearParams } = props;
  return (
    <SchemaForm
      formProps={{ style: { marginBottom: 16 } }}
      formData={filter}
      onSubmit={setParams}
      hideSubmitButton
      schema={{
        fields: ({ formProps: { intl } }) => ({
          date: {
            type: "dateRange",
            propsWrapper: { xs: 4 },
            noHelperText: true,
            disableFuture: true,
          },
          search: {
            type: "submitButton",
            fullWidth: true,
            label: intl.formatMessage({ id: "search" }),
            propsWrapper: { xs: "auto", marginTop: 2.5 },
          },
          reset: {
            type: "button",
            variant: "text",
            fullWidth: true,
            label: <RefreshIcon />,
            style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
            propsWrapper: { xs: "auto", marginTop: 3 },
            onClick: () => {
              clearParams();
            },
          },
        }),
      }}
    />
  );
};
export default FilterConstruction;
