/* eslint-disable react-hooks/exhaustive-deps */
// Material UI
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { TabContext, TabList } from "@mui/lab";
import { Box, ButtonBase, Grid, Paper, Tab, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import { get } from "lodash";
import React, { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import useSWR from "swr";
import LinkPM from "../../../../common/components/LinkPM";
import { some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
import { stringifyUrl } from "../../../../common/utils";
import { ROUTES } from "../../../../layout/constants";
import { LIST_STATUS_STATION } from "../../constants";

interface Props {}
const ConstructionStatusBox = (props: Props) => {
  const { dispatch, API_PATHS, appState } = useGeneralHook();
  const [tab, setTab] = useState(
    appState?.nre?.data?.filter((v) => v.isAutomatic)?.[0]?.id
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const { data } = useSWR(
    tab ? API_PATHS.dashboard.constructionStatistic(tab) : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  return (
    <Paper>
      <TabContext value={`${tab}`}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {appState.nre.data
              ?.filter((v) => v.isAutomatic)
              .map((item) => {
                return (
                  <Tab
                    key={item.id}
                    label={item.name}
                    value={item.id}
                    title={item.name}
                  />
                );
              })}
          </TabList>
        </Box>
      </TabContext>
      <Box padding={2}>
        <Typography variant="body2" marginBottom={1}>
          <FormattedMessage id="dashboard.constructionTotal" />
          &nbsp;
          {data?.total !== undefined && (
            <FormattedNumber value={data?.total} maximumFractionDigits={2} />
          )}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {LIST_STATUS_STATION.map((item: some, index: number) => {
            return (
              <Grid key={index} item style={{ flexBasis: "20%" }}>
                <Paper
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingY: 3,
                    position: "relative",
                    backgroundColor: item.backgroundColor,
                    color: "common.black",
                    minWidth: 120,
                  }}
                >
                  {item.icon}
                  <Typography variant="body2" sx={{ pt: 1 }} color="inherit">
                    <FormattedMessage id={item.label} />
                  </Typography>
                  <Typography variant="h6" color="inherit">
                    <FormattedNumber value={get(data, item.key, 0)} />
                  </Typography>
                  <LinkPM
                    to={{
                      pathname: `/${ROUTES.monitorOnline.index}/${tab}`,
                      search: stringifyUrl({
                        status: item.value.join(","),
                      }),
                    }}
                  >
                    <ButtonBase
                      sx={{
                        p: 1,
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        borderRadius: "50px 0px 0px 0px",
                        padding: 1,
                        bgcolor: "white",
                      }}
                    >
                      <ArrowRightAltIcon sx={{ marginTop: 1, marginLeft: 1 }} />
                    </ButtonBase>
                  </LinkPM>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Paper>
  );
};

export default ConstructionStatusBox;
