import HighchartsReact from "highcharts-react-official";

const ChartJs = ({ options, highcharts, constructorType }) => (
  <HighchartsReact
    highcharts={highcharts}
    constructorType={constructorType}
    options={options}
  />
);
export default ChartJs;
