import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { API_PATHS } from "../../../api/API_App";
import { some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../common/reducer/thunk";
import SchemaForm from "../../../common/SchemaForm";

interface Props {}
const ChangePasswordPage = (props: Props) => {
  const { dispatch, enqueueSnackbar } = useGeneralHook();

  const onSubmit = React.useCallback(
    async (values: some, setLoading, method) => {
      setLoading(true);
      try {
        const json = await dispatch(
          fetchThunk(API_PATHS.auth.changePassword, "post", {
            newPassword: values.newPassword,
            oldPassword: values.oldPassword,
          })
        );
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      } finally {
        setLoading(false);
        method.reset();
      }
    },
    [dispatch, enqueueSnackbar]
  );

  return (
    <Box display={"flex"} justifyContent="center">
      <Paper variant="outlined" sx={{ padding: 2, width: 420 }}>
        <Typography variant="h6" marginBottom={2}>
          <FormattedMessage id="changePassword" />
        </Typography>
        <SchemaForm
          onSubmit={onSubmit}
          schema={{
            fields: ({
              valuesField,
              methods: { setValue },
              formProps: { intl },
            }) => {
              return {
                oldPassword: {
                  type: "text-field",
                  inputType: valuesField?.showOldPassword ? "text" : "password",
                  label: intl.formatMessage({ id: "user.oldPassword" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  register: {
                    validate: {
                      required: (value) =>
                        !value ? intl.formatMessage({ id: "required" }) : true,
                      lessThan: (v) =>
                        v.length < 8 || v.length > 24
                          ? intl.formatMessage({ id: "user.minLength" })
                          : true,
                    },
                  },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setValue(
                              "showOldPassword",
                              !valuesField?.showOldPassword
                            )
                          }
                        >
                          {valuesField?.showOldPassword ? (
                            <Visibility fontSize="small" />
                          ) : (
                            <VisibilityOff fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { borderRadius: 4, overflow: "hidden" },
                    disbleUnderline: true,
                  },
                },

                newPassword: {
                  type: "text-field",
                  inputType: valuesField?.showNewPassword ? "text" : "password",
                  label: intl.formatMessage({ id: "user.newPassword" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  register: {
                    validate: {
                      required: (value) =>
                        !value ? intl.formatMessage({ id: "required" }) : true,
                      lessThan: (v) =>
                        v.length < 8 || v.length > 24
                          ? intl.formatMessage({ id: "user.minLength" })
                          : true,
                      difference: (value) =>
                        valuesField?.oldPassword === value &&
                        valuesField?.newPassword
                          ? intl.formatMessage({
                              id: "user.matchingOldPassword",
                            })
                          : true,
                    },
                  },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setValue(
                              "showNewPassword",
                              !valuesField?.showNewPassword
                            )
                          }
                        >
                          {valuesField?.showNewPassword ? (
                            <Visibility fontSize="small" />
                          ) : (
                            <VisibilityOff fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { borderRadius: 4, overflow: "hidden" },
                    disbleUnderline: true,
                  },
                },
                rePassword: {
                  type: "text-field",
                  inputType: valuesField?.showRePassword ? "text" : "password",
                  label: intl.formatMessage({ id: "user.rePassword" }),
                  placeholder: intl.formatMessage({ id: "enter" }),
                  register: {
                    validate: {
                      required: (value) =>
                        !value ? intl.formatMessage({ id: "required" }) : true,
                      lessThan: (v) =>
                        v.length < 8 || v.length > 24
                          ? intl.formatMessage({ id: "user.minLength" })
                          : true,
                      positive: (value) =>
                        valuesField?.newPassword !== value &&
                        valuesField?.newPassword
                          ? intl.formatMessage({
                              id: "user.notMatchingPassword",
                            })
                          : true,
                    },
                  },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setValue(
                              "showRePassword",
                              !valuesField?.showRePassword
                            )
                          }
                        >
                          {valuesField?.showRePassword ? (
                            <Visibility fontSize="small" />
                          ) : (
                            <VisibilityOff fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { borderRadius: 4, overflow: "hidden" },
                    disbleUnderline: true,
                  },
                },
              };
            },
          }}
        />
      </Paper>
    </Box>
  );
};
export default ChangePasswordPage;
