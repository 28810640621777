import { Box, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import DrawerCustom from "../../../../common/components/DrawerCustom";
import { GIENG_OPTIONS, some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import SchemaForm from "../../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
  nreTypeCode: string;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, nreTypeCode } = props;
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();
  const { id, stationId } = useParams<{ id: string; stationId: string }>();
  console.log("id", id);

  const { data } = useSWR(
    id ? API_PATHS.constructionIndicator.index(id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            methods: { setValue },
          }) => {
            return {
              constructionIndicator: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "indicator" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                options: data,
                getOptionLabel: (options) =>
                  `${options.indicator?.name}(${options?.indicator?.unit})`,
                register: { required: true },
                onChange: (value) => {
                  setValue("indicatorSource", value.code);
                },
              },
              stationSensor: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "sensor" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  if (!stationId) {
                    return [];
                  }
                  const json = await dispatch(
                    fetchThunk(API_PATHS.stationSensor.index(stationId))
                  );
                  return json.data;
                },
                getOptionLabel: (options) => options.sensor?.name,
                renderOption: (_, option) => (
                  <Tooltip
                    arrow
                    placement="left-start"
                    title={
                      <Box color="white">
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.name" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.sensor?.name}
                          </Typography>
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.producingCountry" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.sensor?.producingCountry}
                          </Typography>
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.manufacturer" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.sensor?.manufacturer}
                          </Typography>
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.lowerThreshold" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.sensor?.lowerThreshold}
                          </Typography>
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          <FormattedMessage id="sensor.upperThreshold" />
                          :&nbsp;
                          <Typography
                            variant="subtitle1"
                            component="span"
                            color="inherit"
                          >
                            {option.sensor?.upperThreshold}
                          </Typography>
                        </Typography>
                      </Box>
                    }
                  >
                    <li {..._}>
                      <Typography variant="subtitle2">
                        {option.sensor?.name}
                      </Typography>
                    </li>
                  </Tooltip>
                ),
              },
              factor: {
                type: "text-field",
                fromControlProps: { style: { margin: 0 } },
                label: intl.formatMessage({
                  id: "indicator.factor",
                }),
                placeholder: intl.formatMessage({
                  id: "enter",
                }),
                inputType: "number",
                defaultValue: "1",
                register: {
                  required: true,
                  validate: (value) => (value > 0 ? true : "> 0"),
                },
              },
              dataColumn: {
                type: "select",
                hidden: nreTypeCode !== "NDD",
                label: intl.formatMessage({ id: "station.dataColumn" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                options: GIENG_OPTIONS,
                rawOptions: true,
              },
            };
          },
          changeDataBeforeSubmit: (value: some) => {
            return {
              ...value,
              stationId: stationId,
              dataColumn: value.dataColumn || null,
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default FormDialog;
