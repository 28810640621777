import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import TableCustom from "../../../../common/components/TableCustom";
import { Columns } from "../../../../common/components/TableCustom/utils";
import TagPM from "../../../../common/components/TagPM";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  some,
} from "../../../../common/constants";

interface Props {
  data?: some[];
  loading: boolean;
  onCreateUpdateForm: (value: some) => void;
  onUpdate: (value: some) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { data, loading, onCreateUpdateForm, onUpdate, onDelete } = props;

  const columns = [
    {
      title: "dataLogger",
      dataIndex: "datalogger.name",
    },
    {
      title: "dataLoggerStation.ip",
      dataIndex: "ip",
    },
    {
      title: "dataLoggerStation.account",
      dataIndex: "account",
    },
    {
      title: "dataLoggerStation.dataTransmissionStartTime",
      render: (record: some) => {
        return (
          record.dataTransmissionStartTime &&
          moment(record.dataTransmissionStartTime).format(DATE_TIME_FORMAT)
        );
      },
    },
    {
      title: "dataLoggerStation.installationDate",
      render: (record: some) => {
        return (
          record.installationDate &&
          moment(record.installationDate).format(DATE_FORMAT)
        );
      },
    },
    {
      title: "dataLoggerStation.operationDate",
      render: (record: some) => {
        return (
          record.operationDate &&
          moment(record.operationDate).format(DATE_FORMAT)
        );
      },
    },
    {
      title: "status",
      align: "center",
      render: (record: some) => {
        return (
          <Switch
            checked={record.active}
            onChange={(e) => {
              onUpdate({ ...record, active: e.target.checked });
            }}
          />
        );
      },
    },

    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <TagPM>
            <IconButton
              style={{ marginRight: 8 }}
              size="small"
              onClick={() => {
                onCreateUpdateForm(record);
              }}
            >
              <Edit />
            </IconButton>
          </TagPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">
          <FormattedMessage id="dataLoggerStation.list" />
        </Typography>
        <TagPM>
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={() =>
              onCreateUpdateForm({ encryptionSupport: true, active: true })
            }
          >
            <FormattedMessage id="create" />
          </Button>
        </TagPM>
      </Box>
      <TableCustom dataSource={data} loading={loading} columns={columns} />
    </>
  );
};
export default Table;
