import { PaletteMode } from "@mui/material";
import { blueGrey, green, grey, red, yellow } from "@mui/material/colors";
import {
  alpha,
  darken,
  PaletteOptions,
  unstable_createMuiStrictModeTheme as createTheme,
} from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";
import { PRIMARY, SECONDARY } from "./colors";

const BaseTheme = (palette: PaletteOptions, isDarkTheme?: boolean) => {
  return createTheme({
    components: {
      MuiDrawer: {
        defaultProps: {
          BackdropProps: {
            style: { backgroundColor: "unset" },
            unmountOnExit: true,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionStart: {
            marginLeft: 8,
            marginRight: 0,
          },
          positionEnd: {
            marginLeft: 0,
            marginRight: 12,
          },
        },
      },
      MuiTooltip: {
        defaultProps: { arrow: true },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorSecondary: {
            "&.Mui-checked": {
              color: yellow[700],
            },
          },
        },
      },
      MuiRadio: {
        defaultProps: { color: "secondary" },
        styleOverrides: {
          colorSecondary: {
            "&.Mui-checked": {
              color: yellow[700],
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: { justifyContent: "flex-end" },
          selectRoot: {
            margin: "0 16px 0 8px",
            minWidth: "64px",
          },
          selectIcon: {
            top: "calc(50% - 14px)",
          },
          menuItem: { fontSize: 12 },
          input: {
            "& .MuiTablePagination-select": {
              textAlign: "left",
              textAlignLast: "left",
              background: "inherit",
              border: `0.5px solid ${grey[400]}`,
              borderRadius: "2px",
              fontSize: 14,
              padding: "3px 12px",
            },
          },
          actions: {
            marginLeft: "10px",
            "& .MuiIconButton-root": {
              padding: "6px",
            },
          },
          spacer: {
            flex: "unset",
          },
          // even: {
          //   background: "white",
          // },
          // odd: {
          //   background: BLUE_200,
          // },
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: "small",
        },
      },
      MuiTypography: {
        defaultProps: {
          color: "textPrimary",
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: { height: 1 },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            zIndex: 0,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          color: "primary",
        },
        styleOverrides: {
          root: {
            fontSize: 14,
            padding: "4px 16px",
          },
          text: {
            padding: "4px 16px",
          },
          outlined: {
            padding: "3px 16px",
          },
          sizeSmall: {
            padding: "0px 8px",
          },
          outlinedPrimary: {
            color: isDarkTheme ? "white" : undefined,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            right: 8,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          outlined: {
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
          },
        },
      },
      MuiFormLabel: {
        defaultProps: { sx: { color: "text.primary" } },
        styleOverrides: {
          root: {
            fontSize: 14,
            marginBottom: 8,
            lineHeight: 1,
            fontWeight: 400,
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          root: {
            "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
              transform: "unset",
              position: "relative",
            },
            "&.MuiInputLabel-shrink": {
              transform: "unset",
              position: "relative",
            },
            "&::-webkit-scrollbar-thumb": {
              display: "none !important",
            },
            height: 15,
          },
          asterisk: {
            color: red[600],
            "&::before": {
              color: blueGrey[400],
              content: '" ("',
            },
            "&::after": {
              color: blueGrey[400],
              content: '" )"',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: { height: 8 },
          contained: { marginLeft: 0 },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { padding: 0 },
          adornedEnd: { padding: 0 },
          adornedStart: { padding: 0 },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            "& .MuiAutocomplete-endAdornment": {
              position: "relative",
              right: "0px !important",
            },
            paddingRight: "8px !important",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          multiline: {
            padding: "0px !important",
          },
          input: {
            padding: "4px 8px !important",
            minHeight: 24,
          },
          inputMultiline: {
            height: "auto !important",
            padding: "8px 8px !important",
          },
          root: {
            borderRadius: 4,
            fontSize: 14,
            backgroundColor: palette.background?.paper,
            // "&.MuiOutlinedInput-root.Mui-focused fieldset": {
            //   boxShadow: `${alpha(PRIMARY, 0.25)} 0 0 0 0.2rem`,
            //   // borderColor: PRIMARY,
            //   borderWidth: 1,
            // },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              // borderColor: blueGrey[400],
            },
            "& fieldset": {
              top: 0,
              "& legend": {
                display: "none",
              },
            },
            "& .MuiAutocomplete-popper.MuiAutocomplete-paper": {
              padding: 0,
            },
          },
        },
      },
    },
    typography: {
      htmlFontSize: 14,
      fontSize: 14,
      button: { textTransform: "none" },
      fontFamily: "Nunito sans",
      h1: { fontSize: 96, lineHeight: "112px", fontWeight: 600 },
      h2: { fontSize: 60, lineHeight: "72px", fontWeight: 600 },
      h3: { fontSize: 48, lineHeight: "56px", fontWeight: 600 },
      h4: { fontSize: 32, lineHeight: "44px", fontWeight: "normal" },
      h5: { fontSize: 24, lineHeight: "34px", fontWeight: "normal" },
      h6: { fontSize: 20, lineHeight: "30px", fontWeight: 600 },
      subtitle1: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 600,
      },
      body1: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      body2: {
        fontSize: "14px",
        lineHeight: "22px",
      },
      caption: {
        fontSize: "12px",
        lineHeight: "18px",
      },
      fontWeightBold: 600,
    },
    shadows: [
      "none",
      "0px 4px 10px rgba(0, 0, 0, 0.1)",
      "0px 6px 12px rgba(0, 0, 0, 0.1)",
      "0px 10px 16px rgba(0, 0, 0, 0.1)",
      "0px 16px 22px rgba(0, 0, 0, 0.08)",
      "0px 28px 32px rgba(0, 0, 0, 0.08)",
      "0px 32px 64px rgba(0, 0, 0, 0.08)",
      ...shadows.slice(7),
    ] as any,
  });
};

const LightTheme = () => {
  const palette = {
    primary: {
      light: alpha(PRIMARY, 0.9),
      main: PRIMARY,
      dark: darken(PRIMARY, 0.1),
      contrastText: "#ffffff",
    },
    secondary: {
      light: alpha(SECONDARY, 0.9),
      main: SECONDARY,
      dark: darken(SECONDARY, 0.1),
      contrastText: "#ffffff",
    },

    text: {
      primary: grey[900],
      secondary: blueGrey[400],
    },
    background: {
      paper: "#fff",
      default: "#FCFCFC",
    },
    error: {
      main: red[600],
      dark: red[700],
    },
    success: { main: green[600], dark: green[700] },
    warning: { main: yellow[600], dark: yellow[700] },
  } as PaletteOptions;
  return createTheme({
    ...BaseTheme(palette),
    palette: palette,
  });
};

const DarkTheme = () => {
  const palette = {
    primary: {
      light: alpha(PRIMARY, 0.9),
      main: PRIMARY,
      dark: darken(PRIMARY, 0.1),
      contrastText: "#ffffff",
    },
    secondary: {
      light: alpha(SECONDARY, 0.9),
      main: SECONDARY,
      dark: darken(SECONDARY, 0.1),
      contrastText: "#ffffff",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    background: {
      paper: "#14142B",
      default: "#4E4B66",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    error: {
      main: red[600],
      dark: red[700],
    },
    success: { main: green[600], dark: green[700] },
    warning: { main: yellow[600], dark: yellow[700] },
  } as PaletteOptions;
  return createTheme({
    ...BaseTheme(palette, true),
    palette: { mode: "dark", ...palette },
  });
};

export const MUITheme = (mode?: PaletteMode) => {
  if (mode === "dark") {
    return DarkTheme();
  } else {
    return LightTheme();
  }
};
