import * as React from "react";
// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
// Hooks + Common
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import SchemaForm from "../../../common/SchemaForm";
import { ExpandLess } from "@mui/icons-material";
import { Box, Collapse } from "@mui/material";

interface Props extends HookPaginationProps {}
const Filter = (props: Props) => {
  const { filter, setParams, clearParams } = props;

  return (
    <SchemaForm
      formProps={{ style: { marginBottom: 16 } }}
      formData={filter}
      onSubmit={setParams}
      hideSubmitButton
      schema={{
        fields: ({
          formProps: { intl, dispatch, fetchThunk, API_PATHS, state, setState },
          valuesField,
        }) => ({
          constructionId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "construction.name" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.construction.index({
                    name,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 3 },
          },
          nreTypeId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "nreType" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.nreType.index({
                    name,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          constructionTypeId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "constructionType" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadKey: valuesField?.nreTypeId?.id,
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.constructionType.index({
                    name,
                    nreTypeId: valuesField?.nreTypeId?.id,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          areaId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "area" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.area.index({
                    name,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          riverBasinId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "riverBasin" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.riverBasin.index({
                    name,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          provinceId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "province" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.province.index({
                    name,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          districtId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "district" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadKey: valuesField.provinceId?.id,
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.district.index({
                    name,
                    provinceId: valuesField.provinceId?.id,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          communeId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "commune" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadKey: valuesField.districtId?.id,
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.commune.index({
                    name,
                    districtId: valuesField.districtId?.id,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          search: {
            type: "submitButton",
            fullWidth: true,
            label: intl.formatMessage({ id: "search" }),
            propsWrapper: { xs: "auto", marginTop: 2.5 },
          },
          expand: {
            type: "button",
            endIcon: (
              <ExpandLess
                style={{
                  transition: "0.2s all",
                  transform: state?.expand ? "rotate(0deg)" : "rotate(180deg)",
                }}
              />
            ),
            color: "primary",
            variant: "text",
            onClick: () => setState({ expand: !state.expand }),
            label: intl.formatMessage({ id: "advance" }),
            propsWrapper: { xs: "auto", marginTop: 2.5 },
          },
          reset: {
            type: "button",
            variant: "text",
            fullWidth: true,
            label: <RefreshIcon />,
            style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
            propsWrapper: { xs: "auto", marginTop: 3 },
            onClick: () => {
              clearParams();
            },
          },
        }),
        ui: [
          {
            id: "layout",
            fields: [
              "constructionId",
              "nreTypeId",
              "name",
              "search",
              "reset",
              "expand",
            ],
          },
          {
            id: "expand",
            fields: [
              "constructionTypeId",
              "areaId",
              "riverBasinId",
              "provinceId",
              "districtId",
              "communeId",
            ],
          },
          { id: "submit", fields: ["save"] },
        ],
        layout: ({ listElement, formProps: { state } }) => {
          return (
            <>
              <Box display="flex">{listElement[0]}</Box>
              <Collapse in={state?.expand}>
                <Box paddingTop={2}>{listElement[1]}</Box>
              </Collapse>
            </>
          );
        },
      }}
    />
  );
};
export default Filter;
