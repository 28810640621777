import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { PopoverState } from "../../../common/components/elements";
import LoadingIcon from "../../../common/components/LoadingIcon";
import {
  Row,
  useStickyCountPagin,
} from "../../../common/components/TableCustom/TableCustomMain";
import TablePaginationActionsCustom from "../../../common/components/TableCustom/TablePaginationActionsCustom";
import { some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import { NoData } from "../../../svg";
interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
  setFormData: (value: some) => void;
}
const Table = (props: Props) => {
  const { hookPagination, data, loading, setFormData } = props;
  const { intl } = useGeneralHook();
  const countPagination = useStickyCountPagin(data?.total);
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  return (
    <>
      {loading ? (
        <LoadingIcon />
      ) : data?.items?.length > 0 ? (
        <Grid container columnSpacing={4} rowSpacing={3}>
          {data?.items?.map((item) => {
            return (
              <Grid item xs={12} md={6} xl={4} key={item.id}>
                <Paper variant="outlined" style={{ padding: 8 }}>
                  <iframe
                    src={item.linkIframe}
                    allowFullScreen
                    width="100%"
                    height="220px"
                    onClick={() => {
                      setFormData(item);
                    }}
                    title={item.id}
                  />
                  <Box display="flex">
                    <Typography variant="body2" style={{ flex: 1 }}>
                      {item.construction?.name}
                    </Typography>

                    <PopoverState>
                      {({ anchorEl, handleClick, handleClose, open }) => {
                        return (
                          <>
                            <IconButton
                              onClick={handleClick}
                              style={{ padding: 4 }}
                            >
                              <MoreHorizIcon color="inherit" />
                            </IconButton>

                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem onClick={handleClose}>
                                <Typography variant="body2">
                                  <FormattedMessage id="cameraOnline.gs" />
                                </Typography>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                <Typography variant="body2">
                                  <FormattedMessage id="cameraOnline.info" />
                                </Typography>
                              </MenuItem>
                            </Menu>
                          </>
                        );
                      }}
                    </PopoverState>
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height={320}
        >
          <NoData />
          <Typography variant="body2" style={{ marginTop: 16 }}>
            <FormattedMessage id="noData" />
          </Typography>
        </Box>
      )}
      <TablePagination
        data-tour="step-5"
        component={Row}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        count={countPagination}
        labelRowsPerPage={intl.formatMessage({ id: "labelRowPerPage" })}
        ActionsComponent={TablePaginationActionsCustom}
        rowsPerPageOptions={[9, 18, 36, 72]}
      />
    </>
  );
};
export default Table;
