// Material UI
import { Box } from "@mui/material";
// API
// Redux
// Component
import Table from "../component/Table";

const ReportPage = (props: any) => {
  return (
    <Box display="flex" flexDirection="column">
      <Table />
    </Box>
  );
};

export default ReportPage;
