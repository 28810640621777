import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
// Material UI
import { Box, IconButton, Paper, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";

import TagPM from "../../../common/components/TagPM";
// Constant
import { BE_DATE_FORMAT, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
import Filter from "../component/Filter";
import IndicatorBox, { FilterIndicatorBox } from "../component/IndicatorBox";
import Table from "../component/Table";
// Component

const QAQCPage = () => {
  const {
    dispatch,
    enqueueSnackbar,
    intl,
    API_PATHS,
    confirmDialog,
    fetchThunk,
  } = useGeneralHook();
  const [isBoss, setBoss] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const methods = useForm({
    reValidateMode: "onChange",
    mode: "onSubmit",
    // defaultValues: {
    //   list_data: [
    //     {
    //       id: 321321,
    //       name: "Hà Nội: 556 Nguyễn Văn Cừ (KK)",
    //       time: "20:55, 10/09/2021",
    //       status: 1,
    //       data: {
    //         nhamay_luuluong: 1,
    //         dctt_luuluong: 2,
    //         quatran_luuluong: 3,
    //         haluu_mucnuoc: 4,
    //       },
    //     },
    //   ],
    // },
  });

  const { reset, getValues, trigger, formState, watch } = methods;
  const hookPagination = usePaginationHook({
    defaultFilter: {
      date: {
        startDate: moment().startOf("months").format(BE_DATE_FORMAT),
        endDate: moment().format(BE_DATE_FORMAT),
      },
      dataType: "kdtd",
    },
  });
  const valuesForm = watch();
  const isActiveSubmit = valuesForm.items?.findIndex((v) => v.isChecked) !== -1;
  const { params, pagination } = hookPagination;
  const { constructionId, status, dataType, date } = params;

  const [filter, setFilter] = React.useState<FilterIndicatorBox>({
    columns: [],
  });

  const { data, isValidating, mutate } = useSWR(
    params?.constructionId
      ? API_PATHS.qa_qc.get({
          ...pagination,
          ...date,
          constructionId: constructionId?.id,
          status,
          dataType,
        })
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const onCensor = React.useCallback(async () => {
    const isOk = await trigger();

    if (!isOk || formState.dirtyFields?.items?.length === 0) {
      return;
    }
    const values = getValues();
    setLoading(true);
    try {
      const json = await dispatch(
        fetchThunk(
          API_PATHS.qa_qc.censor(constructionId?.id),
          "post",
          formState.dirtyFields?.items
            .map((item, index) => {
              if (item) {
                return values.items[index];
              }
              return undefined;
            })
            .filter(Boolean)
        )
      );
      if (json.code === SUCCESS_CODE) {
        mutate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    } finally {
      setLoading(false);
    }
  }, [
    API_PATHS.qa_qc,
    constructionId?.id,
    dispatch,
    enqueueSnackbar,
    fetchThunk,
    formState.dirtyFields?.items,
    getValues,
    mutate,
    trigger,
  ]);
  const onApproved = React.useCallback(async () => {
    const isOk = await trigger();
    if (!isOk || !isActiveSubmit) {
      return;
    }
    const confirm = await confirmDialog.promptConfirmation({
      title: intl.formatMessage({ id: "qa_qc.title" }),
      content: intl.formatMessage({ id: "qa_qc.approvedConfirm" }),
    });
    if (confirm) {
      setLoading2(true);
      try {
        const json = await dispatch(
          fetchThunk(
            API_PATHS.qa_qc.approve(constructionId?.id),
            "post",
            valuesForm.items?.filter((v) => v.isChecked)
          )
        );
        if (json.code === SUCCESS_CODE) {
          mutate();
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId[0],
          variant: json.code,
        });
      } finally {
        setLoading2(false);
      }
    }
    confirmDialog.close();
  }, [
    API_PATHS.qa_qc,
    confirmDialog,
    constructionId?.id,
    dispatch,
    enqueueSnackbar,
    fetchThunk,
    intl,
    isActiveSubmit,
    mutate,
    trigger,
    valuesForm.items,
  ]);

  const onReject = React.useCallback(async () => {
    const isOk = await trigger();

    if (!isOk || !isActiveSubmit) {
      return;
    }
    const confirm = await confirmDialog.promptConfirmation({
      warning: true,
      title: intl.formatMessage({ id: "qa_qc.title" }),
      content: intl.formatMessage({ id: "qa_qc.rejectConfirm" }),
    });
    if (confirm) {
      setLoading3(true);
      try {
        const json = await dispatch(
          fetchThunk(
            API_PATHS.qa_qc.reject(constructionId?.id),
            "post",
            valuesForm.items?.filter((v) => v.isChecked)
          )
        );
        if (json.code === SUCCESS_CODE) {
          mutate();
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      } finally {
        setLoading3(false);
      }
    }
    confirmDialog.close();
  }, [
    API_PATHS.qa_qc,
    confirmDialog,
    constructionId?.id,
    dispatch,
    enqueueSnackbar,
    fetchThunk,
    intl,
    isActiveSubmit,
    mutate,
    trigger,
    valuesForm.items,
  ]);

  React.useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  return (
    <Box display="flex" flexDirection="column">
      <Filter {...hookPagination} />
      <FormProvider {...methods}>
        <form>
          <Paper sx={{ padding: 2 }}>
            <Box display="flex" alignItems="center" gap={1} paddingBottom={2}>
              <Typography variant="subtitle1" flex={1}>
                <FormattedMessage id="qa_qc.list" />
              </Typography>
              <IndicatorBox
                constructionId={constructionId?.id}
                filter={filter}
                setFilter={setFilter}
              />
              <IconButton onClick={() => reset()} disabled={!constructionId}>
                <RefreshIcon color="primary" />
              </IconButton>
              <LoadingButton onClick={() => setBoss(!isBoss)}>
                <FormattedMessage id={isBoss ? "boss" : "staff"} />
              </LoadingButton>
              {!isBoss ? (
                <LoadingButton
                  loading={loading}
                  disabled={
                    !constructionId || !formState.dirtyFields?.items?.length
                  }
                  onClick={() => onCensor()}
                >
                  <FormattedMessage id="qa_qc.remove" />
                </LoadingButton>
              ) : (
                <>
                  <TagPM>
                    <LoadingButton
                      loading={loading3}
                      onClick={() => onReject()}
                      variant="outlined"
                      disabled={!constructionId || !isActiveSubmit}
                    >
                      <FormattedMessage id="qa_qc.reject" />
                    </LoadingButton>
                    <LoadingButton
                      loading={loading2}
                      onClick={() => onApproved()}
                      disabled={!constructionId || !isActiveSubmit}
                    >
                      <FormattedMessage id="qa_qc.approved" />
                    </LoadingButton>
                  </TagPM>
                </>
              )}
            </Box>
            <Table
              loading={isValidating}
              hookPagination={hookPagination}
              indicators={filter}
              filter={params}
              rawData={data}
              isBoss={isBoss}
            />
          </Paper>
        </form>
      </FormProvider>
    </Box>
  );
};

export default QAQCPage;
