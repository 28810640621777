// Material UI
import { Add } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
// Redux
import { fetchThunk } from "../../../common/reducer/thunk";
// Component
import Filter from "../component/Filter";
import FilterConstruction from "../component/FilterConstruction";
import FormDialog from "../component/FormDialog";
import Table from "../component/Table";

const WaterQualityPage = (props: any) => {
  const { dispatch, enqueueSnackbar, confirmDialog, intl } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;
  const [formData, setFormData] = useState<some | undefined>();

  const { data, revalidate, isValidating } = useSWR(
    API_PATHS.waterQuality.index(params),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading) => {
      setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(API_PATHS.waterQuality.update(value.id), "put", value)
          )
        : await dispatch(
            fetchThunk(API_PATHS.waterQuality.create, "post", value)
          );
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
      setLoading(false);
    },
    [dispatch, enqueueSnackbar, revalidate]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.waterQuality.update(value.id), "delete")
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, enqueueSnackbar, revalidate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Filter {...hookPagination} />
      <Paper sx={{ display: "flex" }}>
        <FilterConstruction {...hookPagination} />
        <Box p={2} flex={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
          >
            <Typography variant="h6">
              <FormattedMessage id="waterQuality.list" />{" "}
              {params?.constructionId ? params?.constructionId?.name : ""}
            </Typography>
            <Button
              sx={{ flexShrink: 0 }}
              startIcon={<Add />}
              color="primary"
              onClick={() => setFormData({})}
            >
              <FormattedMessage id="waterQuality.create" />
            </Button>
          </Box>
          <Table
            data={data}
            loading={isValidating}
            hookPagination={hookPagination}
            onCreateUpdateForm={setFormData}
            onDelete={onDelete}
          />
        </Box>
      </Paper>
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
        constructionId={params?.constructionId?.id}
      />
    </Box>
  );
};

export default WaterQualityPage;
