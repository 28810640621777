import RefreshIcon from "@mui/icons-material/Refresh";
import { HookFilterProps } from "../../../common/hook/useFilterHook";
import SchemaForm from "../../../common/SchemaForm";
import { LIST_STATUS_STATION } from "../../dashboard/constants";

interface Props extends HookFilterProps {
  nreTypeId?: string;
}

const Filter = (props: Props) => {
  const { filter, nreTypeId, setParams, clearParams } = props;

  return (
    <SchemaForm
      formProps={{ style: { marginBottom: 16 } }}
      formData={filter}
      onSubmit={setParams}
      hideSubmitButton
      schema={{
        fields: ({ formProps: { intl, dispatch, fetchThunk, API_PATHS } }) => ({
          constructionId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "construction.name" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.construction.index({
                    page: 0,
                    pageSize: 100,
                    name,
                    nreTypeId,
                  })
                )
              );
              return json.data?.items?.map((v) => ({ id: v.id, name: v.name }));
            },
            loadKey: nreTypeId,
            propsWrapper: { xs: 3 },
            noHelperText: true,
          },
          status: {
            type: "select",
            label: intl.formatMessage({ id: "monitor.warningLevel" }),
            placeholder: intl.formatMessage({
              id: "monitor.warningLevelEnter",
            }),
            options: LIST_STATUS_STATION.map((v) => ({
              id: v.value.join(","),
              name: v.label,
            })),
            hasAllOptions: true,
            propsWrapper: { xs: 2 },
            noHelperText: true,
          },
          search: {
            type: "submitButton",
            fullWidth: true,
            label: intl.formatMessage({
              id: "search",
            }),
            propsWrapper: { xs: "auto", marginTop: 2.5 },
          },
          reset: {
            type: "button",
            variant: "text",
            fullWidth: true,
            label: <RefreshIcon />,
            style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
            propsWrapper: { xs: "auto", marginTop: 3 },
            onClick: () => {
              clearParams();
            },
          },
        }),
      }}
    />
  );
};
export default Filter;
