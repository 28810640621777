import * as React from "react";
import { API_PATHS } from "../../../api/API_App";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import { fetchThunk } from "../../../common/reducer/thunk";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <SchemaForm
      schema={{
        fields: ({ formProps: { intl, dispatch } }) => {
          return {
            name: {
              type: "text-field",
              label: intl.formatMessage({ id: "licenseMetadata.name" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              register: {
                required: true,
              },
              propsWrapper: { xs: 6 },
            },
            constructionKind: {
              type: "auto-complete",
              label: intl.formatMessage({ id: "constructionKind" }),
              placeholder: intl.formatMessage({
                id: "select",
              }),
              disableSearchByText: true,
              loadOptions: async () => {
                const json = await dispatch(
                  fetchThunk(API_PATHS.constructionKind.index({}))
                );
                return json.data?.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              },
              propsWrapper: { xs: 6 },
              register: {
                required: true,
              },
            },
            longitude: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({ id: "longitude" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              register: {
                required: true,
              },
              propsWrapper: { xs: 6 },
            },
            latitude: {
              type: "text-field",
              label: intl.formatMessage({ id: "latitude" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              register: {
                required: true,
              },
              propsWrapper: { xs: 6 },
            },
            usefulVolume: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({
                id: "licenseMetadata.usefulVolume",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              propsWrapper: { xs: 6 },
            },
            preventionVolume: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({
                id: "licenseMetadata.preventionVolume",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              propsWrapper: { xs: 6 },
            },
            dryVolume: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({
                id: "licenseMetadata.dryVolume",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              propsWrapper: { xs: 6 },
            },
            totalVolume: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({
                id: "licenseMetadata.totalVolume",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              propsWrapper: { xs: 6 },
            },
            watt: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({
                id: "licenseMetadata.watt",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              propsWrapper: { xs: 6 },
            },
            designFlow: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({
                id: "licenseMetadata.designFlow",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              propsWrapper: { xs: 6 },
            },
            realityFlow: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({
                id: "licenseMetadata.realityFlow",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              propsWrapper: { xs: 6 },
            },
            exceptedExploitFlow: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({
                id: "licenseMetadata.exceptedExploitFlow",
              }),
              placeholder: intl.formatMessage({ id: "enter" }),
              propsWrapper: { xs: 6 },
            },
          };
        },
      }}
      onSubmit={onSubmit}
      initialData={formData}
      onCancel={onClose}
      formProps={{
        autoComplete: "nope",
        autoCorrect: "off",
      }}
    />
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
