import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import LoadingIcon from "../../../common/components/LoadingIcon";
import {
  TableCustomContainer,
  TableHeaderCell,
} from "../../../common/components/TableCustom/element";
import TagPM from "../../../common/components/TagPM";
import { some } from "../../../common/constants";
import ThresholdIndicationRow from "./ThresholdIndicationRow";

interface Props {
  data?: some;
  loading: boolean;
  onCreateUpdateForm: (value: some) => void;
  onDelete: (value: some) => void;
}
const ThresholdTable = (props: Props) => {
  const { data, loading, onCreateUpdateForm, onDelete } = props;
  if (loading && !data) {
    return <LoadingIcon />;
  }
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">
          <FormattedMessage id="threshold.list" />
        </Typography>
        <TagPM>
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={() => onCreateUpdateForm({})}
          >
            <FormattedMessage id="create" />
          </Button>
        </TagPM>
      </Box>
      <TableCustomContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableHeaderCell width={50}></TableHeaderCell>
              <TableHeaderCell width={50} align="center">
                <FormattedMessage id="stt" />
              </TableHeaderCell>
              <TableHeaderCell align="left">
                <FormattedMessage id="threshold.name" />
              </TableHeaderCell>
              <TableHeaderCell align="center" width={80}>
                <FormattedMessage id="threshold.indicatorCount" />
              </TableHeaderCell>
              <TableHeaderCell align="center" width={140}>
                <FormattedMessage id="action" />
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <ThresholdIndicationRow
                key={index}
                row={row}
                index={index}
                onDelete={onDelete}
                setFormData={onCreateUpdateForm}
              />
            ))}
          </TableBody>
        </Table>
      </TableCustomContainer>
    </>
  );
};
export default ThresholdTable;
