// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
import * as React from "react";
// Hooks + Common
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import SchemaForm from "../../../common/SchemaForm";

interface Props extends HookPaginationProps {}
const Filter = (props: Props) => {
  const { filter, setParams, clearParams } = props;

  return (
    <SchemaForm
      formProps={{ style: { marginBottom: 16 } }}
      formData={filter}
      onSubmit={setParams}
      hideSubmitButton
      schema={{
        fields: ({
          formProps: { intl, dispatch, API_PATHS, fetchThunk },
          valuesField,
          methods: { setValue },
        }) => ({
          nreTypeId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "nreType" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.nreType.index({
                    name,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            onChange: () => {
              setValue("constructionTypeId", null);
            },
            propsWrapper: { xs: 2 },
          },
          constructionTypeId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "constructionType" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadKey: valuesField?.nreTypeId?.id,
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.constructionType.index({
                    name,
                    nreTypeId: valuesField?.nreTypeId?.id,
                    pageSize: 100,
                  })
                )
              );
              return json.data?.items?.map((v) => ({
                id: v.id,
                name: v.name,
              }));
            },
            propsWrapper: { xs: 2 },
          },
          constructionId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "construction.name" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),
            loadKey: [
              valuesField?.nreTypeId?.id,
              valuesField?.constructionTypeId?.id,
            ].join(","),
            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.construction.index({
                    page: 0,
                    pageSize: 100,
                    name,
                    nreTypeId: valuesField?.nreTypeId?.id,
                    constructionTypeId: valuesField?.constructionTypeId?.id,
                  })
                )
              );
              return json.data?.items?.map((v) => ({ id: v.id, name: v.name }));
            },
            register: {
              required: true,
            },
            propsWrapper: { xs: 2 },
          },
          date: {
            type: "dateRange",
            propsWrapper: { xs: 4 },
            register: {
              required: true,
            },
          },
          search: {
            type: "submitButton",
            fullWidth: true,
            label: intl.formatMessage({ id: "search" }),
            propsWrapper: { xs: "auto", marginTop: 2.5 },
          },
          reset: {
            type: "button",
            variant: "text",
            fullWidth: true,
            label: <RefreshIcon />,
            style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
            propsWrapper: { xs: "auto", marginTop: 3 },
            onClick: () => {
              clearParams();
            },
          },
        }),
      }}
    />
  );
};
export default Filter;
