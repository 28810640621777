import Cookies from "js-cookie";
import { batch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { ActionType, createCustomAction, getType } from "typesafe-actions";
import { API_PATHS } from "../../../api/API_App";
import { AppState, clearStoreAfterLogout } from "../../../redux/reducer";
import { TOKEN, some, SUCCESS_CODE } from "../../../common/constants";
import { fetchThunk } from "../../../common/reducer/thunk";

export interface AuthenticationState {
  authentication: boolean;
  userInfo?: some;
  tokenInfo?: some;
}

export const authenticationIn = createCustomAction("authentication/in");
export const authenticationOut = createCustomAction("authentication/out");

export const saveUserInfo = createCustomAction(
  "authentication/saveUserInfo",
  (userInfo: some) => ({ userInfo: userInfo })
);

export const saveTokenInfo = createCustomAction(
  "authentication/saveTokenInfo",
  (tokenInfo: some) => ({ tokenInfo: tokenInfo })
);

export function validateToken(): ThunkAction<some, AppState, null, AnyAction> {
  return async (dispatch) => {
    if (!Cookies.get(TOKEN)) {
      dispatch(authenticationOut());
    } else {
      const json2 = await dispatch(fetchThunk(API_PATHS.auth.validateToken));
      if (json2.code === SUCCESS_CODE) {
        dispatch(saveUserInfo(json2.data));
        dispatch(authenticationIn());
      } else {
        Cookies.remove(TOKEN);
        dispatch(authenticationOut());
      }
      return json2;
    }
  };
}

export function logoutThunk(): ThunkAction<void, AppState, null, AnyAction> {
  return (dispatch) => {
    Cookies.remove(TOKEN);
    batch(() => {
      dispatch(authenticationOut());
      dispatch(clearStoreAfterLogout());
    });
  };
}

const actions = {
  authenticationIn,
  authenticationOut,
  saveUserInfo,
  saveTokenInfo,
};

type ActionT = ActionType<typeof actions>;

export default function authenticationReducer(
  state = { authentication: false },
  action: ActionT
): AuthenticationState {
  switch (action.type) {
    case getType(authenticationIn):
      return { ...state, authentication: true };
    case getType(authenticationOut):
      return { ...state, authentication: false };
    case getType(saveUserInfo):
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case getType(saveTokenInfo):
      return {
        ...state,
        tokenInfo: action.tokenInfo,
      };
    default:
      return state;
  }
}
