// Material UI
import { Box } from "@mui/material";
import React, { useCallback } from "react";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
// Constant
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
// Redux
import { fetchThunk } from "../../../common/reducer/thunk";
// Component
import Filter from "../component/Filter";
import Table from "../component/Table";

const PublicConfigPage = () => {
  const { dispatch, enqueueSnackbar } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;

  const { data, isValidating, revalidate } = useSWR(
    API_PATHS.configPublic.index(params),
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  const onUpdateForm = useCallback(
    async (formData: some) => {
      const json = await dispatch(
        fetchThunk(API_PATHS.configPublic.update, "post", formData.items)
      );
      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, revalidate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Filter {...hookPagination} />
      <Table
        data={data}
        loading={isValidating}
        hookPagination={hookPagination}
        onSubmit={onUpdateForm}
      />
    </Box>
  );
};

export default PublicConfigPage;
