import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, Checkbox, Collapse, IconButton, Typography } from "@mui/material";
import { OpenState } from "../../../../common/components/elements";
import { some } from "../../../../common/constants";

interface Props {
  data: some[];
  selected: any[];
  index?: number;
  onSelect: (value: some, checked) => void;
}
const MenuTreeSelect = (props: Props) => {
  const { data, index = 0, onSelect, selected } = props;

  return (
    <Box overflow={"auto"}>
      {data.map((item) => {
        return (
          <OpenState key={item.id}>
            {({ open, setOpen }) => {
              return (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    style={{
                      marginLeft: index * 38,
                    }}
                  >
                    {item?.children.length > 0 ? (
                      <IconButton
                        style={{
                          transition: "all 0.3s",
                          transform: `rotate(${open ? 90 : 0}deg)`,
                        }}
                        size="small"
                        onClick={() => {
                          setOpen((old) => !old);
                        }}
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    ) : (
                      <Typography style={{ width: 32.5, textAlign: "center" }}>
                        •
                      </Typography>
                    )}
                    <Checkbox
                      checked={
                        selected.length > 0
                          ? selected.findIndex((v) => v.id === item.id) !== -1
                          : false
                      }
                      onChange={(e) => {
                        onSelect(item, e.target.checked);
                      }}
                    />
                    <Typography
                      variant={
                        item?.children.length > 0 ? "subtitle2" : "body2"
                      }
                    >
                      {item.name}&nbsp;({item.url})
                    </Typography>
                  </Box>
                  {item?.children.length > 0 && (
                    <Collapse in={open}>
                      <MenuTreeSelect
                        data={item?.children}
                        index={index + 1}
                        onSelect={onSelect}
                        selected={selected}
                      />
                    </Collapse>
                  )}
                </Box>
              );
            }}
          </OpenState>
        );
      })}
    </Box>
  );
};
export default MenuTreeSelect;
