import { Close } from "@mui/icons-material";
import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { FormattedMessage, FormattedNumber } from "react-intl";
import useSWR from "swr";
import TableCustomMain from "../../../common/components/TableCustom/TableCustomMain";
import { DATE_TIME_FORMAT, some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { capitalizeFirstLetter } from "../../../common/utils";
import { LIST_STATUS_STATION } from "../../dashboard/constants";
interface Props {
  data: some;
  onClose: () => void;
  isFullScreen: boolean;
}

const LocationMarker = (props: Props) => {
  const { isFullScreen, data, onClose } = props;
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data: stationData, isValidating } = useSWR(
    API_PATHS.searchData.minute({
      constructionId: data.id,
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );

  const { data: dataIndicator = [] } = useSWR(
    API_PATHS.searchData.indicator({
      constructionId: data.id,
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );
  const status = LIST_STATUS_STATION.find((v) => v.value.includes(data.status));

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 8,
        right: 16,
        top: isFullScreen ? undefined : 120,
        width: 360,
        overflow: "hidden",
        display: "flex",
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: 4, right: 4, zIndex: 10 }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <Paper
        sx={{
          width: "100%",
          overflow: "auto",
          p: 2,
        }}
      >
        <Box minWidth="100%">
          <Typography variant="h6" color="inherit" style={{ marginRight: 24 }}>
            {data.name}
          </Typography>
          <Box display="flex">
            <Typography
              variant="body2"
              color="inherit"
              style={{ minWidth: 120 }}
            >
              <FormattedMessage id="construction.code" />
            </Typography>
            <Typography variant="body2" color="inherit">
              {data.code}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="body2"
              color="inherit"
              style={{ minWidth: 120 }}
            >
              <FormattedMessage id="province" />
            </Typography>
            <Typography variant="body2" color="inherit">
              {data.province?.name}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="body2"
              color="inherit"
              style={{ minWidth: 120 }}
            >
              <FormattedMessage id="address" />
            </Typography>
            <Typography variant="body2" color="inherit">
              {data.address}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="body2"
              color="inherit"
              style={{ minWidth: 120 }}
            >
              <FormattedMessage id="latitude" />
            </Typography>
            <Typography variant="body2" color="inherit">
              {data.latitude}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="body2"
              color="inherit"
              style={{ minWidth: 120 }}
            >
              <FormattedMessage id="longitude" />
            </Typography>
            <Typography variant="body2" color="inherit">
              {data.longitude}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="body2"
              color="inherit"
              style={{ minWidth: 120 }}
            >
              <FormattedMessage id="status" />
            </Typography>
            {status && (
              <Box display="inline-flex" alignItems="center">
                <Box
                  sx={{
                    height: 8,
                    minWidth: 8,
                    borderRadius: 4,
                    bgcolor: status.color,
                    marginRight: 1,
                  }}
                />
                <FormattedMessage id={status.label} />
              </Box>
            )}
          </Box>
          <TableCustomMain
            dataSource={stationData?.items || []}
            containerProps={{
              style: { maxHeight: 330, marginTop: 8 },
              sx: {
                "& td": {
                  position: "relative",
                },
              },
            }}
            tableProps={{
              style: { height: "100%" },
            }}
            loading={isValidating}
            hideColumnIndex
            columns={
              [
                {
                  title: "time",
                  minWidth: 120,
                  fixed: "left",
                  variant: "caption",
                  align: "center",
                  render: (record: some) => {
                    return (
                      <Typography variant="caption">
                        {record.time &&
                          moment(record.time).format(DATE_TIME_FORMAT)}
                      </Typography>
                    );
                  },
                },
                ...(dataIndicator || []).map((column) => {
                  if (column.indicators) {
                    return {
                      title: (
                        <Box>
                          <Typography
                            variant="caption"
                            fontWeight="bold"
                            style={{ wordBreak: "break-word" }}
                            component="div"
                          >
                            {capitalizeFirstLetter(column.name)}
                          </Typography>
                        </Box>
                      ),
                      headerProps: {
                        style: { paddingRight: 0, paddingLeft: 0 },
                      },
                      align: "center",

                      children: column.indicators?.map((indicator) => {
                        return {
                          title: (
                            <Box>
                              <Typography
                                variant="caption"
                                fontWeight="bold"
                                style={{ wordBreak: "break-word" }}
                                component="div"
                              >
                                {capitalizeFirstLetter(indicator.name)}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ wordBreak: "break-word" }}
                              >
                                {indicator.unit}
                              </Typography>
                            </Box>
                          ),
                          minWidth: 70,
                          align: "center",
                          render: (record: some) => {
                            const value =
                              record?.[`${indicator.dataColumnCode}_v`];
                            const status =
                              record?.[`${indicator.dataColumnCode}_s`];
                            const label = LIST_STATUS_STATION.find((v) =>
                              v.value.includes(status)
                            )?.label;
                            const color = LIST_STATUS_STATION.find((v) =>
                              v.value.includes(status)
                            )?.color;
                            return (
                              <Box>
                                {typeof value === "number" ? (
                                  <Typography
                                    variant="caption"
                                    color={
                                      record?.status === 99
                                        ? "textSecondary"
                                        : "success.main"
                                    }
                                    style={{
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {typeof value === "number" && (
                                      <FormattedNumber
                                        value={value}
                                        maximumFractionDigits={2}
                                      />
                                    )}
                                  </Typography>
                                ) : (
                                  "-"
                                )}
                                {color && (
                                  <Tooltip
                                    title={
                                      label ? (
                                        <FormattedMessage id={label} />
                                      ) : (
                                        ""
                                      )
                                    }
                                  >
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        bottom: 4,
                                        left: "calc(50% - 4px)",
                                        width: 8,
                                        height: 8,
                                        borderRadius: "50%",
                                        bgcolor: color,
                                        flexShrink: 0,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                            );
                          },
                        };
                      }),
                    };
                  }
                  return {
                    title: (
                      <Box>
                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          component="span"
                        >
                          {capitalizeFirstLetter(column.name)}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="span"
                        >
                          &nbsp;({column.unit})
                        </Typography>
                      </Box>
                    ),
                    minWidth: 70,
                    align: "center",
                    variant: "caption",
                    render: (record) => {
                      const value = record?.[`${column.dataColumnCode}_v`];
                      const status = record?.[`${column.dataColumnCode}_s`];
                      const label = LIST_STATUS_STATION.find((v) =>
                        v.value.includes(status)
                      )?.label;
                      const color = LIST_STATUS_STATION.find((v) =>
                        v.value.includes(status)
                      )?.color;
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Tooltip
                            title={label ? <FormattedMessage id={label} /> : ""}
                          >
                            <Typography variant="caption" color={color}>
                              {typeof value === "number" && (
                                <FormattedNumber
                                  value={value}
                                  maximumFractionDigits={2}
                                />
                              )}
                            </Typography>
                          </Tooltip>
                        </Box>
                      );
                    },
                  };
                }),
              ] as any
            }
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default LocationMarker;
