import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { FormattedMessage, FormattedNumber } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import { DATE_FORMAT, some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import { capitalizeFirstLetter } from "../../../common/utils";

interface Props {
  data: some;
  columnsFilter: some[];
  hookPagination: HookPaginationProps;
  loading: boolean;
}
const Table = (props: Props) => {
  const { data, columnsFilter = [], hookPagination, loading } = props;
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  const columns = [
    {
      title: "time",
      minWidth: 120,
      fixed: "left",
      variant: "caption",
      align: "center",
      render: (record: some) => {
        return (
          <Typography variant="caption">
            {record.time && moment(record.time).format(DATE_FORMAT)}
          </Typography>
        );
      },
    },
    ...(columnsFilter || []).map((column) => {
      if (column.indicators) {
        return {
          title: (
            <Typography
              variant="caption"
              fontWeight="bold"
              style={{ wordBreak: "break-word" }}
              component="div"
            >
              {capitalizeFirstLetter(column.name)}
            </Typography>
          ),
          headerProps: { style: { paddingRight: 0, paddingLeft: 0 } },
          align: "center",
          children: column.indicators?.map((indicator) => {
            return {
              title: (
                <Box>
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    component="span"
                  >
                    {capitalizeFirstLetter(indicator.name)}
                  </Typography>
                  {indicator.unit && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                    >
                      &nbsp;({indicator.unit})
                    </Typography>
                  )}
                </Box>
              ),
              align: "center",
              children: ["max", "min", "avg"].map((v) => {
                return {
                  title: (
                    <Box>
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        component="span"
                      >
                        <FormattedMessage id={v} />
                      </Typography>
                    </Box>
                  ),
                  align: "center",
                  render: (record) => {
                    const value = record?.[`${indicator.dataColumnCode}_${v}`];

                    return (
                      <Typography variant="caption">
                        {typeof value === "number" && (
                          <FormattedNumber
                            value={value}
                            maximumFractionDigits={2}
                          />
                        )}
                      </Typography>
                    );
                  },
                };
              }),
            };
          }),
        };
      }
      return {
        title: (
          <Box>
            <Typography variant="caption" fontWeight="bold" component="span">
              {capitalizeFirstLetter(column.name)}
            </Typography>
            {column.unit && (
              <Typography
                variant="caption"
                color="textSecondary"
                component="span"
              >
                &nbsp;({column.unit})
              </Typography>
            )}
          </Box>
        ),
        align: "center",
        variant: "caption",
        children: ["max", "min", "avg"].map((v) => {
          return {
            title: (
              <Box>
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  component="span"
                >
                  <FormattedMessage id={v} />
                </Typography>
              </Box>
            ),
            align: "center",
            render: (record) => {
              const value = record?.[`${column.dataColumnCode}_${v}`];
              console.log("value", typeof value);

              return (
                <Typography variant="caption">
                  {typeof value === "number" && (
                    <FormattedNumber value={value} maximumFractionDigits={2} />
                  )}
                </Typography>
              );
            },
          };
        }),
      };
    }),
  ] as Columns[];

  return (
    <>
      <TableCustom
        dataSource={data?.items}
        columns={columns}
        loading={loading}
        paginationProps={{
          count: data?.total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
    </>
  );
};
export default Table;
