import RefreshIcon from "@mui/icons-material/Refresh";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import {
  Badge,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Collapse,
  InputAdornment,
  Paper,
} from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import { OpenState } from "../../../common/components/elements";
import { some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import SchemaForm from "../../../common/SchemaForm";
import { Search } from "../../../svg";

export interface FilterIndicatorBox {
  columns: some[];
}

interface Props {
  constructionId?: string;
  filter: FilterIndicatorBox;
  setFilter: (params: FilterIndicatorBox) => void;
}
const IndicatorBox = (props: Props) => {
  const { constructionId, setFilter, filter } = props;
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data } = useSWR(
    constructionId
      ? API_PATHS.qa_qc.indicators({
          constructionId: constructionId,
        })
      : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  React.useEffect(() => {
    if (data) {
      setFilter({ columns: data || [] });
    }
  }, [data, setFilter]);

  return (
    <OpenState>
      {({ open, setOpen }) => {
        return (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Box display="inline-flex" position="relative">
              <Button
                startIcon={
                  <Badge badgeContent={filter.columns?.length} color="error">
                    <TuneRoundedIcon />
                  </Badge>
                }
                variant="text"
                onClick={() => {
                  setOpen((old) => !old);
                }}
              >
                <FormattedMessage id="selectIndicator" />
              </Button>
              <Collapse in={open}>
                <Paper
                  variant="outlined"
                  style={{
                    padding: 16,
                    width: 546,
                    zIndex: 100,
                    position: "absolute",
                    top: 42,
                    right: 0,
                  }}
                >
                  <SchemaForm
                    hideSubmitButton
                    formData={filter}
                    onSubmit={(value: FilterIndicatorBox) => {
                      setOpen(false);
                      setFilter(value);
                    }}
                    schema={{
                      fields: ({
                        formProps: { intl },
                        methods: { reset, setValue },
                        valuesField,
                      }) => {
                        return {
                          columns: {
                            type: "auto-complete",
                            propsWrapper: { xs: 7 },
                            noHelperText: true,
                            placeholder: intl.formatMessage({
                              id: "indicator.name",
                            }),
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search className="svgStroke" />
                              </InputAdornment>
                            ),
                            getOptionLabel: (options) =>
                              `${options.name}(${options.unit})`,
                            multiple: true,
                            renderTags: () => null,
                            options: data,
                            disableClearable: true,
                          },
                          checkAll: {
                            type: "checkbox",
                            unregister: true,
                            checked:
                              valuesField.columns?.length === data?.length,
                            label: intl.formatMessage({
                              id: "selectAllIndicator",
                            }),
                            fromControlProps: {
                              style: { marginTop: 0 },
                            },
                            propsWrapper: { xs: 5 },
                            noHelperText: true,
                            onChange: (e) => {
                              setValue("columns", e.target.checked ? data : []);
                            },
                          },
                          board: {
                            type: () => {
                              return (
                                <Paper
                                  variant="outlined"
                                  style={{
                                    padding: 8,
                                    minHeight: 80,
                                    maxHeight: 260,
                                    overflow: "auto",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {valuesField?.columns?.map((val, index) => {
                                    return (
                                      <Chip
                                        key={index}
                                        size="small"
                                        variant="outlined"
                                        label={val.name}
                                        style={{
                                          margin: 4,
                                        }}
                                        onDelete={() => {
                                          setValue(
                                            "columns",
                                            valuesField?.columns?.filter(
                                              (v) => v.id !== val.id
                                            )
                                          );
                                        }}
                                      />
                                    );
                                  })}
                                </Paper>
                              );
                            },
                            unregister: true,
                          },
                          reset: {
                            type: "button",
                            variant: "text",
                            fullWidth: true,
                            label: <RefreshIcon />,
                            style: {
                              padding: 4,
                              minWidth: "unset",
                              borderRadius: "50%",
                              width: "unset",
                            },
                            propsWrapper: {
                              xs: "auto",
                              marginTop: 0.25,
                              style: {
                                flex: 1,
                                display: "flex",
                                justifyContent: "flex-end",
                              },
                            },
                            onClick: () => {
                              reset();
                            },
                          },
                          apply: {
                            type: "submitButton",
                            fullWidth: true,
                            label: intl.formatMessage({
                              id: "apply",
                            }),
                            propsWrapper: { xs: "auto" },
                          },
                        };
                      },
                    }}
                  />
                </Paper>
              </Collapse>
            </Box>
          </ClickAwayListener>
        );
      }}
    </OpenState>
  );
};
export default IndicatorBox;
